import { useContext, useState } from "react";
import { useSWRConfig } from "swr";
import { useDispatch, useSelector } from "react-redux";

import { UPDATE_ORDER, SELECT_ORDER, SET_ORDERS } from "../stores/reducers/OrderReducers";
import { OrderContext } from "../context/OrderContext";
import { api } from "../api/api";
import { Toast } from "../utils/Toast";
import { GlobalContext } from "../context/GlobalContext";

export const OrderProvider = ({ children }) => {
  const { isAccess } = useContext(GlobalContext)
  const deliveryDistricts = useSelector((state) => state.district.data)
  const orderSelected = useSelector(state => state.order.orderSelect)
  const orders = useSelector(state => state.order.orders)
  const dispatch = useDispatch()


  const { mutate } = useSWRConfig()

  const [timer, setTimer] = useState(null)

  async function loadOrders() {
    try {
      const response = await api.get('/orders')
      const { body } = response.data

      dispatch(SET_ORDERS(body))
      return body
    } catch (error) { }
  }

  function calculatePricingTotal(order) {
    const _pricingTotalProducts = calculateTotal(order)

    let _discount = Number(order?.discount) ?? 0;
    let _extra = Number(order?.extra) ?? 0;

    const _taxDelivery = order?.deliveryDistrict?.district ? parseFloat(order?.deliveryDistrict?.pricing ?? 0) : 0
    const freeDelivery = order?.deliveryDistrict?.free ? true : false

    let _totalWithDiscount = _pricingTotalProducts - _discount + _extra
    let _total = (_pricingTotalProducts - _discount) + _extra
    let pricingDiscountCupom = 0

    if (order.cupomType === 'porcentagem') {
      pricingDiscountCupom += _totalWithDiscount * (order?.cupomDiscount / 100)
      _totalWithDiscount = _totalWithDiscount - (_totalWithDiscount * (order?.cupomDiscount / 100))
    }

    if (order.cupomType === 'fixo') {
      pricingDiscountCupom += order?.cupomDiscount ?? 0
      _totalWithDiscount = _totalWithDiscount - order?.cupomDiscount ?? 0
    }

    if (!freeDelivery) {
      _totalWithDiscount += _taxDelivery
      _total += _taxDelivery
    }

    return {
      total: _total,
      totalWithDiscount: _totalWithDiscount,
      totalProducts: _pricingTotalProducts,
      discount: pricingDiscountCupom
    }
  }

  function calculateTotal(order) {
    const data = order?.productsOrders?.map((item, index) => {
      const keys = Object.keys(item?.additinal).map((e) => e.split('_').join(' '))
      const sumAdditioanl = keys.reduce((prev, current) => {
        return prev + parseFloat(item.additinal[current.replace(/ /ig, '_')]?.map(v => ((v?.pricing ?? 0) * (v?.quantity ?? 0))).reduce((p, c) => p + parseFloat(c), 0)) ?? 0
      }, 0)
      const preco = (parseFloat(item.pricing) + sumAdditioanl) * item.quantity
      return preco
    })

    const _pricingTotalProducts = data?.reduce((prev, current) => prev + current, 0) ?? 0
    let _total = _pricingTotalProducts

    return _total
  }

  async function selectOrder(id) {
    const response = await api.get('/orders/' + id)
    dispatch(SELECT_ORDER(response.data))
    return response.data
  }

  async function updated(id, key, valueInput = '', time = true, message = true) {
    if (!isAccess('pedidos', 'update')) {
      return Toast.warning('Você não tem permissão para atualizar pedido')
    }

    if (key === 'status' && valueInput === 'finalizado') {
      await mutate('/safe/load/data')
    }

    const oldState = { ...orderSelected }

    const body = {
      id,
      [key]: valueInput === "-1" ? null : valueInput
    }

    dispatch(UPDATE_ORDER(body))

    const atualizar = async () => {
      try {
        await api.put('/orders/' + id, body)

        if (message) {
          Toast.success("Informação atualizada.")
        }

        await selectOrder(id)
      } catch (error) {
        dispatch(UPDATE_ORDER(oldState))
        Toast.warning("Erro ao atualizar, status do pedido.\nEntre em contato com o suporte.")
      }
    }

    if (!time) {
      return await atualizar()
    }

    clearTimeout(timer)

    const newTimer = setTimeout(async () => {
      if (!id || !key) {
        Toast.success()
      }

      await atualizar()

    }, 800)

    setTimer(newTimer)
  }

  async function updateOrder(id, key, valueInput = '') {
    const body = {
      id,
      [key]: valueInput === "-1" ? null : valueInput
    }

    try {
      await api.put('/orders/' + id, body)
      Toast.success("Informação atualizada.")
      selectOrder(id)
      dispatch(UPDATE_ORDER(body))
    } catch (error) { }
  }

  // useEffect(() => {
  //   if (configurations?.idStore && isAccess())
  //     window.iFoodWidget.init({
  //       widgetId: 'dddb6875-1458-4db2-b0c6-783b3a129ab7',
  //       merchantIds: [
  //         configurations.idStore
  //       ],
  //     });
  // }, [configurations.idStore, isAccess])

  async function refusedOrder(id, status, messageRefused) {
    try {
      const body = {
        id: id,
        status,
        messageRefused
      }

      await api.post('/orders/refused', {
        message: messageRefused,
        idOrder: id
      })
      Toast.success("Pedido recusado com sucesso.")
      dispatch(UPDATE_ORDER(body))
    } catch (error) {
    }
  }

  async function cancelOrder(id) {
    const confirm = await Toast.confirm("Deseja cancelar este pedido?", 'Não', 'Sim')
    if (confirm) {
      try {
        const body = {
          id: id,
          status: 'cancelado',
          messageRefused: "Pedido cancelado"
        }
        await api.put('/orders/' + id, body)
        Toast.success("Pedido cancelado com sucesso.")
        dispatch(UPDATE_ORDER(body))
      } catch (error) {
        Toast.warning("Erro ao cancelar o pedido.\nEntre em contato com o suporte.")
      }
    }
  }


  return (
    <OrderContext.Provider value={{
      updated, calculatePricingTotal, orders,
      orderSelected, selectOrder, deliveryDistricts,
      refusedOrder, cancelOrder, calculateTotal, loadOrders, updateOrder
    }}>
      {children}
    </OrderContext.Provider>
  )
}