import { useEffect, useState } from "react"
import { api } from "../../../../api/api"
import { Toast } from "../../../../utils/Toast"
import { SearchCep } from "../../../../services/cep"
import { useDispatch, useSelector } from "react-redux"
import { SET_COMPANY, UPDATE_COMPANY } from "../../../../stores/reducers/CompanyReducers"
import { FaHourglassStart, /**FaCopy**/ } from "react-icons/fa"
import { removeCaracteresEspeciais } from "../../../../utils/utils"
import ReactInputMask from "react-input-mask"
import { LIST_TYPE_COMPANY } from "../../../../utils/constantes"

export function Information() {
  const dispatch = useDispatch()
  const company = useSelector(state => state.company?.data)
  const [isAvailability, setIsAvailability] = useState(null)
  const [cpfOrCnpj, setCpfOrCnpj] = useState('cnpj')


  useEffect(() => {
    setCpfOrCnpj(company?.cpfCnpj?.length <= 13 ? 'cpf' : 'cnpj')
  }, [company?.cpfCnpj])

  async function create() {
    await api.post('/companies', {
      ...company,
      timeDelivery: company.timeDelivery,
      timeRetirada: company.timeRetirada,
    })
    Toast.success()
  }

  function onChange(key, valueInput) {
    dispatch(SET_COMPANY({
      ...company,
      [key]: valueInput
    }))
  }

  function changePhonesRemoveCaracter(value = '') {
    return value
      .replace(/\(/ig, '')
      .replace(/\)/ig, '')
      .replace(/_/ig, '')
      .replace(/-/ig, '')
      .replace(/ /ig, '')
  }

  async function searchCep(valueCep) {
    try {
      const data = await SearchCep(valueCep)
      dispatch(UPDATE_COMPANY({
        ...company,
        ...data
      }))
    } catch (error) {
      Toast.warning("CEP inválido ou não encontrado.")
    }
  }

  async function checkLink() {
    const response = await api.get('/companies/availability/' + company?.link)
    setIsAvailability(response.data)
  }

  // function copy(url) {
  //   navigator.clipboard.writeText(url);
  //   Toast.success("Link copiado")
  // }

  return (
    <div className="mt-4 mb-4">
      <h4 className="text-gray card-title" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '1.2rem' }}>
        Informações do Estabelecimento
      </h4>

      <div className="row">
        <div className="col-sm-7 mt-2">
          <label htmlFor="">Nome</label>
          <input className="form-control form-control-sm" type="text" value={company?.name ?? ''} onChange={(e) => onChange('name', e.target.value)} />
        </div>
        <div className="col-sm-2 mt-2">
          <label htmlFor="">Tipo de documento</label>
          <select className="form-select form-select-sm" value={cpfOrCnpj} onChange={(e) => {
            if (e.target.value === null || e.target.value === '---- Selecione ----')
              setCpfOrCnpj("cnpj")
            else
              setCpfOrCnpj(e.target.value)
          }}>
            <option value={null}>---- Selecione ----</option>
            <option value="cnpj">CNPJ</option>
            <option value="cpf">CPF</option>
          </select>
        </div>

        {cpfOrCnpj === 'cpf' &&
          <div className="col-sm-3 mt-2">
            <label htmlFor="">CPF</label>
            <ReactInputMask
              mask="999.999.999-99"
              className="form-control form-control-sm"
              value={company?.cpfCnpj ?? ''}
              onChange={(e) => onChange('cpfCnpj', e.target.value)}
            />
          </div>

        }
        {cpfOrCnpj === 'cnpj' &&
          <div className="col-sm-3 mt-2">
            <label htmlFor="">CNPJ</label>
            <ReactInputMask
              mask="99.999.999/9999-99"
              className="form-control form-control-sm"
              value={company?.cpfCnpj ?? ''}
              onChange={(e) => onChange('cpfCnpj', e.target.value)}
            />
          </div>
        }

        <div className="col-sm-4 mt-2">
          <label htmlFor="">Telefone</label>
          <ReactInputMask
            mask="(99) 9999-9999"
            className="form-control form-control-sm"
            value={company?.phone ?? ''}
            onChange={(e) => onChange('phone', changePhonesRemoveCaracter(e.target.value))}
          />
        </div>
        <div className="col-sm-4 mt-2">
          <label htmlFor="">Whatsapp</label>
          <ReactInputMask
            mask="+55 (99) 99999-9999"
            className="form-control form-control-sm"
            value={company?.whatsapp ?? ''}
            onChange={(e) => onChange('whatsapp', changePhonesRemoveCaracter(e.target.value))}
          />
        </div>
        <div className="col-sm-4 mt-2">
          <label htmlFor="">Área de atuação</label>
          <select value={company.area} onChange={(e) => {
            if (e.target.value === '-------- Selecione --------')
              onChange('area', null)
            else
              onChange('area', e.target.value)
          }} className="form-select form-select-sm" >
            <option selected value={null}>-------- Selecione --------</option>
            {LIST_TYPE_COMPANY.map(value => {
              return (
                <option key={value} value={value}>{value}</option>
              )
            })}
          </select>
        </div>
      </div>
      <div className="divider mt-4"></div>
      <h4 className="text-gray card-title mt-4" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '1.2rem' }}>
        Informações do Responsável
      </h4>

      <div className="row">
        <div className="col-sm-7 mt-2">
          <label htmlFor="">Nome</label>
          <input className="form-control form-control-sm" type="text" value={company?.nameResponsible ?? ''} onChange={(e) => onChange('nameResponsible', e.target.value)} />
        </div>
        <div className="col-sm-2 mt-2">
          <label htmlFor="">CPF</label>
          <input className="form-control form-control-sm" type="text" value={company?.cpfResponsible ?? ''} onChange={(e) => onChange('cpfResponsible', e.target.value)} />
        </div>
        <div className="col-sm-2 mt-2">
          <label htmlFor="">Telefone</label>
          <ReactInputMask
            mask="(99) 9 9999-9999"
            className="form-control form-control-sm"
            value={company?.phoneResponsible ?? ''} onChange={(e) => onChange('phoneResponsible', e.target.value)}
          />
        </div>
      </div>
      <div className="divider mt-4"></div>

      <h2 className="text-gray card-title mt-4" style={{ fontSize: '1.2rem' }}>Informações do Endereço</h2>
      <div className="row">
        <div className="col-sm-2 mt-2">
          <div>
            <label htmlFor="">CEP</label>
            <input className="form-control form-control-sm" type="text" value={company?.cep} onChange={async (e) => {
              onChange('cep', e.target.value)
              if (e.target.value.length === 8) {
                await searchCep(e.target.value)
              }
            }} />
          </div>
        </div>
        <div className="col-sm-5 mt-2">
          <label htmlFor="">Endereço</label>
          <input className="form-control form-control-sm" type="text" value={company?.address} onChange={(e) => onChange('address', e.target.value)} />
        </div>
        <div className="col-sm-2 mt-2">
          <label htmlFor="">Número</label>
          <input className="form-control form-control-sm" type="text" value={company?.number} onChange={(e) => onChange('number', e.target.value)} />
        </div>
        <div className="col-sm-3 mt-2">
          <label htmlFor="">Bairro</label>
          <input className="form-control form-control-sm" type="text" value={company?.district} onChange={(e) => onChange('district', e.target.value)} />
        </div>
        <div className="col-sm-3 mt-2">
          <label htmlFor="">Cidade</label>
          <input className="form-control form-control-sm" type="text" value={company?.city} onChange={(e) => onChange('city', e.target.value)} />
        </div>
        <div className="col-sm-1 mt-2">
          <label htmlFor="">UF</label>
          <input className="form-control form-control-sm" type="text" value={company?.uf} onChange={(e) => onChange('uf', e.target.value)} />
        </div>
      </div>

      <div className="divider mt-4"></div>
      <h2 className="text-gray card-title mt-5" style={{ fontSize: '1.2rem' }}>Informações Adicionais</h2>
      <div className="row">
        <div className="col-sm-12 mt-2 d-flex" style={{ alignItems: 'center', gap: 20 }}>
          <div>
            <label htmlFor="">Link</label>
            <input className="form-control form-control-sm" type="text" value={removeCaracteresEspeciais(company?.link)} onChange={(e) => {
              setIsAvailability(null)
              onChange('link', e.target.value)
            }} />
            <div style={{ transition: 1 }} className="form-text">
              {isAvailability === null && (
                <span className="text-secondary">* Nome do Estabelecimento para aparecer no link</span>
              )}
              {isAvailability === true && (
                <span className="text-success">* Nome disponível.</span>
              )}
              {isAvailability === false && (
                <span className="text-danger">* Este link não está disponível</span>
              )}

            </div>
          </div>
          <button className="btn btn-sm border" onClick={checkLink} style={{ display: 'inline' }}>Consultar</button>
        </div>
        <>
          <div className="col-sm-6 mt-2" style={{ alignItems: 'center', gap: 20 }}>
            <div>
              <label htmlFor="">Configurar Dominio Personalizado</label>
              <input className="form-control form-control-sm" type="text" value={company?.domain} onChange={(e) => onChange('domain', e.target.value)} />
            </div>
            <p className="text-gray mt-2" style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>Adicione seu dominio personalizado, e entre em contato com o suporte para continuar a configuração</p>
          </div>
          <div className="col-sm-6"></div>
        </>


        <div className="col-sm-2 mt-2">
          <div>
            <label htmlFor="">Tempo médio de entrega</label>
            <div className="input-group mb-3">
              <span className="input-group-text" id="basic-addon1">
                <FaHourglassStart />
              </span>
              <input
                className="form-control form-control-sm rounded"
                type="text"
                maxLength={3}
                value={company?.timeDelivery}
                onChange={(e) => dispatch(SET_COMPANY({ ...company, timeDelivery: e.target.value }))}
              />
              <span className="ms-1 mt-1 text-gray">minutos</span>
            </div>
          </div>
        </div>
        <div className="col-sm-2 mt-2">
          <div>
            <label htmlFor="">Tempo médio de retirada</label>
            <div className="input-group mb-3">
              <span className="input-group-text" id="basic-addon1">
                <FaHourglassStart />
              </span>
              <input
                className="form-control form-control-sm rounded"
                type="text"
                maxLength={3}
                value={company?.timeRetirada}
                onChange={(e) => dispatch(SET_COMPANY({ ...company, timeRetirada: e.target.value }))}
              />
              <span className="ms-1 mt-1 text-gray">minutos</span>
            </div>
          </div>
        </div>
      </div>

      <div className="divider mt-2 mb-4"></div>

      <div className="text-start">
        <button className="btn  btn-dark-blue" onClick={() => create()}>Salvar informações</button>
      </div>
    </div >
  )
}