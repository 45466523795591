import { createSlice } from '@reduxjs/toolkit'

export const INITIAL_STATE = {
  data: [],
  category: {
    id: null,
    name: '',
    isLocal: false,
    isMenu: false
  },
}

export const CategoryReducers = createSlice({
  name: 'category',
  initialState: INITIAL_STATE,
  reducers: {
    SET_CATEGORY: (state, action) => {
      state.category = action.payload
    },
    SET_CATEGORY_DATA: (state, action) => {
      state.data = action.payload
    },
    ADD_NEW_CATEGORY: (state, action) => {
      state.data.push(action.payload)
    },
    UPDATE_CATEGORY: (state, action) => {
      state.data = state.data.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload
        }

        return item
      })
    },
  }
})


export const { SET_CATEGORY, ADD_NEW_CATEGORY, SET_CATEGORY_DATA, UPDATE_CATEGORY } = CategoryReducers.actions

export default CategoryReducers.reducer