import { useEffect, useState } from "react";
import { api } from "../api/api";
import { CardContext } from "../context/CardContext";

export const CardProvider = ({ children }) => {
  const [cards, setCards] = useState([])

  useEffect(() => {
    findAll()
  }, [])

  async function findAll() {
    const response = await api.get('/cards')
    const { data } = response.data
    setCards(data)
  }

  return (
    <CardContext.Provider value={{ cards, setCards, findAll }}>
      {children}
    </CardContext.Provider>
  )
}