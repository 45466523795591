import { createSlice } from '@reduxjs/toolkit'

export const MotoboyReducer = createSlice({
  name: 'motoboy',
  initialState: {
    motoboys: []
  },
  reducers: {
    SET_MOTOBOYS: (state, action) => {
      state.motoboys = action.payload
    },
    ADD_NEW_MOTOBOY: (state, action) => {
      state.motoboys.push(action.payload)
    },
    UPDATE_MOTOBOY: (state, action) => {
      state.motoboys = state.motoboys.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload
        }

        return item
      })
    },
  }
})


export const { SET_MOTOBOYS, ADD_NEW_MOTOBOY, UPDATE_MOTOBOY } = MotoboyReducer.actions

export default MotoboyReducer.reducer