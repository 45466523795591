import { useNavigate } from "react-router-dom";
import { api } from "../../api/api";
import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { SearchCep } from "../../services/cep";
import { Toast } from "../../utils/Toast";
import { Carousel } from "../../components/Carousel";
import { LIST_TYPE_COMPANY } from "../../utils/constantes";
import ReactInputMask from "react-input-mask";
import axios from "axios";

export function RegisterPage() {
  const navigate = useNavigate();

  const [infoRegister, setInfoRegister] = useState({
    email: '',
    password: '',
    fullName: '',
    phone: '',
    cpfCnpj: '',
    birthDate: '',
    cep: '',
    city: '',
    uf: '',
    address: '',
    district: '',
    number: '',
    complement: '',
    timeZone: DateTime.local().zoneName,
    nameResponsible: '',
    cpfResponsible: '',
    phoneResponsible: '',
    area: null
  })

  function register(e) {
    if (
      !infoRegister.fullName ||
      !infoRegister.cpfCnpj ||
      !infoRegister.email ||
      !infoRegister.password ||
      !infoRegister.area ||
      !infoRegister.nameResponsible ||
      !infoRegister.cpfResponsible ||
      !infoRegister.phoneResponsible
    ) {
      return Toast.warning("Todos campos são obrigatórios")
    }

    api.post('/users/register', infoRegister)
      .then(async (res) => {
        await axios({
          method: 'POST',
          baseURL: 'https://gestao.bmspedidos.com.br',
          url: '/items/metricts',
          data: {
            type: 'register',
            date: new Date(),
            payload: JSON.stringify(infoRegister)
          }
        }).catch(console.error)

        Toast.success("Registro efetuado com sucesso, você será redirecionado para o login")
        navigate('/')
      })
      .catch(async (err) => {
        await axios({
          method: 'POST',
          baseURL: 'https://gestao.bmspedidos.com.br',
          url: '/items/metricts',
          data: {
            type: 'registration_attemp',
            date: new Date(),
            payload: JSON.stringify({
              data: JSON.stringify(infoRegister),
              error: JSON.stringify(err)
            })
          }
        }).catch(console.error)
      })
  }

  useEffect(() => {
    if (infoRegister.cep.length === 8) {
      SearchCep(infoRegister.cep)
        .then((response) => {
          setInfoRegister(state => {
            return {
              ...state,
              ...response
            }
          })
        })
    }
  }, [infoRegister.cep])

  useEffect(() => {
    if (localStorage.getItem('access_token')?.length > 0) {
      window.location.href = '/home'
    } else {
      localStorage.removeItem('access_token')
      localStorage.removeItem('refresh_token')
    }
  }, [])

  useEffect(() => {
    axios({
      method: 'POST',
      baseURL: 'https://gestao.bmspedidos.com.br',
      url: '/items/metricts',
      data: {
        type: 'registration_attempt',
        date: new Date()
      }
    })
  }, [])


  return (
    <div style={{ width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
      {window.screen.width >= 1115 && (
        <div style={{ width: window.screen.width - 700, height: '100%', display: 'flex', alignItems: 'center', backgroundColor: '#102f46' }}>
          <Carousel />
        </div>
      )}
      <div style={{ width: window.screen.width <= 1115 ? '100%' : 700, height: 50, display: 'flex', alignItems: 'center', backgroundColor: '#fff' }}>
        <div style={{ width: '100%', padding: 20, margin: '0 auto', maxHeight: '100vh', overflow: 'auto', }}>
          <div style={{ textAlign: 'center', }}>
            <img src="https://pedidos-images.s3.amazonaws.com/BMS%20PEDIDO%20-%20LAYOUT%20SISTEMA.png" width={230} alt="" />
            <h2>Cadastrar-se</h2>
          </div>

          <h6 style={{ width: '80%', margin: '0 auto', color: '#0b273d', fontWeight: 600, fontSize: 14, textAlign: 'center', backgroundColor: '#dcdcdc', padding: 10, borderRadius: 5 }}>Informações do estabelecimento</h6>

          <form style={{ width: '80%', margin: '0 auto' }}>
            <div className="mb-3">
              <label htmlFor="name" className="form-label" style={{ fontSize: 14 }}>Empresa</label>
              <input type="text" onChange={(e) => setInfoRegister(state => ({ ...state, fullName: e.target.value }))} value={infoRegister.fullName} className="form-control" id="name" />
            </div>
            <div className="mb-3">
              <label htmlFor="cpfcnpj" className="form-label" style={{ fontSize: 14 }}>CPF/CNPJ</label>
              <input type="text" maxLength={14} onChange={(e) => setInfoRegister(state => ({ ...state, cpfCnpj: e.target.value }))} value={infoRegister.cpfCnpj} className="form-control" id="cpfcnpj" />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label" style={{ fontSize: 14 }}>Telefone</label>
              <ReactInputMask
                mask="(99) 9 9999-9999"
                className="form-control "
                value={infoRegister.phone}
                onChange={(e) => setInfoRegister(state => ({ ...state, phone: e.target.value }))}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" style={{ fontSize: 14 }} className="form-label">E-mail</label>
              <input type="email" onChange={(e) => setInfoRegister(state => ({ ...state, email: e.target.value }))} value={infoRegister.email} className="form-control" id="email" />
            </div>
            <div className="mb-3">
              <label htmlFor="password" style={{ fontSize: 14 }} className="form-label">Senha</label>
              <input onChange={(e) => setInfoRegister(state => ({ ...state, password: e.target.value }))} value={infoRegister.password} type="password" className="form-control" />
            </div>
            <div className="mb-3">
              <label htmlFor="" style={{ fontSize: 14 }}>Área de atuação</label>
              <select value={infoRegister.area} onChange={(e) => {
                if (e.target.value === '-------- Selecione --------')
                  setInfoRegister(state => ({ ...state, area: null }))
                else
                  setInfoRegister(state => ({ ...state, area: e.target.value }))
              }} className="form-select" >
                <option selected value={null}>-------- Selecione --------</option>
                {LIST_TYPE_COMPANY.map(value => {
                  return (
                    <option key={value} value={value}>{value}</option>
                  )
                })}
              </select>
            </div>

            <div style={{ borderTop: '1px solid #0b273d', marginBottom: 10 }}></div>

            <h6 style={{ color: '#0b273d', fontWeight: 600, fontSize: 14, textAlign: 'center', backgroundColor: '#dcdcdc', padding: 10, borderRadius: 5 }}>Informações do responsável</h6>
            <div className="mb-3">
              <label style={{ fontSize: 14 }} className="form-label">Nome</label>
              <input maxLength={100} onChange={(e) => setInfoRegister(state => ({ ...state, nameResponsible: e.target.value }))} value={infoRegister.nameResponsible} type="text" className="form-control" />
            </div>
            <div className="mb-3">
              <label className="form-label" style={{ fontSize: 14 }}>CPF</label>
              <ReactInputMask
                mask="999.999.999-99"
                className="form-control "
                value={infoRegister.cpfResponsible ?? ''}
                onChange={(e) => setInfoRegister(state => ({ ...state, cpfResponsible: e.target.value }))}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" style={{ fontSize: 14 }} className="form-label">Celular</label>
              <ReactInputMask
                mask="(99) 9 9999-9999"
                className="form-control "
                value={infoRegister.phoneResponsible}
                onChange={(e) => setInfoRegister(state => ({ ...state, phoneResponsible: e.target.value }))}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center", gap: 20, flexDirection: "column" }}>
              <button type="button" className="btn btn-lg btn-dark-blue w-100" onClick={(e) => {
                e.preventDefault()
                register()
              }}>Cadastar</button>
              <button type="button" className="btn btn-white border w-50" onClick={(e) => {
                e.preventDefault()
                navigate('/')
              }}>Acessar login</button>
            </div>
          </form>
        </div>
      </div>
    </div >
  )
}