import { useState, useEffect, useCallback, useContext } from "react"

import { INITIAL_STATE_ENABLE_INFO_FISCALE, INITIAL_STATE_INFO_FISCALE, INITIAL_STATE_ICMS, INITIAL_STATE_ALIQUOTA_MVA, INITIAL_STATE_IPI, INITIAL_STATE_PIS, INITIAL_STATE_COFINS, INITIAL_STATE_ISSQN, INITIAL_STATE_ADDITIONAL_DATA, INITIAL_STATE_OBJECT_ICMS } from "../initialStates"

import { HandleInfoFiscale } from "../../../utils/handleInfoFiscale/HandleInfoFicale"
import { Toast } from "../../../utils/Toast"
import { api } from "../../../api/api"
import { ProductContext } from "../../../context/ProductContext"


const InfoFiscaleService = {}
export function UseInfoFiscale() {
  const { productEdit, setProductEdit, findAllRefs } = useContext(ProductContext)

  const [infoFiscale, setInfoFiscale] = useState(INITIAL_STATE_INFO_FISCALE)
  const [additionalData, setAdditionalData] = useState(INITIAL_STATE_ADDITIONAL_DATA)
  const [icms, setIcms] = useState(INITIAL_STATE_ICMS)
  const [ipi, setIpi] = useState(INITIAL_STATE_IPI)
  const [pis, setPis] = useState(INITIAL_STATE_PIS)
  const [cofins, setCofins] = useState(INITIAL_STATE_COFINS)
  const [issqn, setIssqn] = useState(INITIAL_STATE_ISSQN)
  const [aliquotaMva, setAliquotaMva] = useState(INITIAL_STATE_ALIQUOTA_MVA)
  const [enable, setEnable] = useState(INITIAL_STATE_ENABLE_INFO_FISCALE)
  const [objectIcms, setObjectIcms] = useState(INITIAL_STATE_OBJECT_ICMS)
  const [enableIssqn, setEnableIssqn] = useState(false)
  const [refs, setRefs] = useState([])


  const handleComponentDisplay = (value) => {
    setEnable({ ...enable, description: true, cofins: true, createdRefs: true, ipi: true, icms: true, information: true, issqn: true, pis: true, [value]: !enable[value] })
  }

  const handleChangeAdditionalData = useCallback((e) => {
    setAdditionalData({ ...additionalData, [e.currentTarget.name]: e.currentTarget.value })
  }, [additionalData])

  const handleChangeIpi = useCallback((e) => {
    setIpi({ ...ipi, [e.currentTarget.name]: e.currentTarget.value })
  }, [ipi])

  const handleChangePis = useCallback((e) => {
    setPis({ ...pis, [e.currentTarget.name]: e.currentTarget.value })
  }, [pis])

  const handleChangeCofins = useCallback((e) => {
    setCofins({ ...cofins, [e.currentTarget.name]: e.currentTarget.value })
  }, [cofins])

  const handleChangeIssqn = useCallback((e) => {
    setIssqn({ ...issqn, [e.currentTarget.name]: e.currentTarget.value })
  }, [issqn])

  const handleChangeIcms = useCallback((e) => {
    e.currentTarget.name === "nao_contribuinte" ? setIcms({ ...icms, nao_contribuinte: e.target.checked }) : setIcms({ ...icms, [e.currentTarget.name]: e.currentTarget.value })
  }, [icms])

  const handleChangeAliquotaMva = useCallback((e) => {
    setAliquotaMva({ ...aliquotaMva, [e.currentTarget.name]: e.currentTarget.value })
  }, [aliquotaMva])

  const handleSaveOrUpdate = async () => {
    const result = returnNewInfoFiscale()

    await api.post('/nfce/create-ref', {
      idProduct: productEdit?.id ?? null,
      data: result
    })

    // if (productEdit?.id) {
    //   setProductEdit({ ...productEdit, refFiscal: response.data.refFiscal })
    // }

    Toast.success("Imposto cadastrado com sucesso.")
    await findAllRefs()
    clear()
  }

  const deleteInfoFiscale = async (id, classeImposto) => {
    try {
      await InfoFiscaleService.delete(id, classeImposto)

      return Toast.success("REF removido! ✅");
    } catch (error) {
      Toast.warning('Ocorreu um erro ao deletar seus REF');
    }
  }

  const returnNewInfoFiscale = () => {
    const result = HandleInfoFiscale(icms, aliquotaMva, ipi, pis, cofins, issqn, additionalData)

    const newInfoFiscale = {
      ...infoFiscale,
      descricao: result.descricao,
      icms: result.icms,
      ipi: result.ipi,
      pis: result.pis,
      cofins: result.cofins,
      issqn: result.issqn,
      informacoes_fisco: result.informacoes_fisco,
      informacoes_complementares: result.informacoes_complementares
    }

    setInfoFiscale({
      ...infoFiscale,
      ...newInfoFiscale
    })

    return newInfoFiscale
  }

  const clear = () => {
    setInfoFiscale(INITIAL_STATE_INFO_FISCALE)
    setAdditionalData(INITIAL_STATE_ADDITIONAL_DATA)
    setIcms(INITIAL_STATE_ICMS)
    setIpi(INITIAL_STATE_IPI)
    setPis(INITIAL_STATE_PIS)
    setCofins(INITIAL_STATE_COFINS)
    setIssqn(INITIAL_STATE_ISSQN)
    setAliquotaMva(INITIAL_STATE_ALIQUOTA_MVA)
  }


  const assignValuesToTaxes = (infoFiscale) => {
    setInfoFiscale({ ...infoFiscale, id: infoFiscale.id })
    setAdditionalData({
      referencia: infoFiscale?.ref, 
      descricao: infoFiscale?.description, 
      informacoes_fisco: infoFiscale?.payload?.informacoes_fisco || "", 
      informacoes_complementares: infoFiscale?.payload?.informacoes_complementares || ""
    })
    setIcms({ ...icms, ...(infoFiscale?.payload?.icms[0] ?? {}) })
    setIpi({ ...ipi, ...(infoFiscale?.payload?.ipi[0] ?? {}) })
    setPis({ ...pis, ...(infoFiscale?.payload?.pis[0] ?? {}) })
    setCofins({ ...cofins, ...(infoFiscale?.payload?.cofins[0] ?? {}) })

    if ("issqn" in (infoFiscale?.payload ?? {})) {
      setIssqn({ ...issqn, ...(infoFiscale?.payload?.issqn[0] ?? {}) })
    } else {
      setIssqn(INITIAL_STATE_ISSQN)
    }

    if ("aliquota_mva" in (infoFiscale?.payload?.icms[0] ?? {})) {
      setAliquotaMva({ ...aliquotaMva, ...(infoFiscale?.payload?.icms[0]?.aliquota_mva[0] ?? {}) })
    }
  }

  return {
    handleComponentDisplay,
    enable,
    setEnable,
    infoFiscale,
    setInfoFiscale,
    additionalData,
    handleChangeAdditionalData,
    icms,
    setIcms,
    handleChangeIcms,
    aliquotaMva,
    handleChangeAliquotaMva,
    ipi,
    handleChangeIpi,
    pis,
    handleChangePis,
    cofins,
    handleChangeCofins,
    issqn,
    handleChangeIssqn,
    handleSaveOrUpdate,
    clear,
    objectIcms,
    enableIssqn,
    setEnableIssqn,
    refs,
    deleteInfoFiscale,
    assignValuesToTaxes
  }
}