export const loadGMaps = (callback, googleKey) => {
  if (!googleKey)
    return

  const existingScript = document.getElementById('googleMaps');

  if (!existingScript) {
    const script = document.createElement("script");
    script.id = 'googleMaps';
    script.async = true;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places`;

    script.onload = () => {
      if (callback) callback();
    };

    document.body.appendChild(script);
  }
  if (existingScript && callback) callback();
};
