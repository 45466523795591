import { useContext, useEffect, useState } from "react";
import { MapComponent } from "./components/Maps";
import { OrderContext } from "../../../context/OrderContext";
import { maskCurrency, numOrderFormat } from "../../../utils/utils";
import { useCompany } from "../../../hooks/useCompany";
import { loadGMaps } from "../../../utils/loadMaps";

export function RouteMaps() {
  const { orders = [] } = useContext(OrderContext)
  const [selecteds, setSelecteds] = useState([])
  const [loadMapCompleted, setLoadMapCompleted] = useState(false)

  const { configurations } = useCompany()

  useEffect(() => {
    if (configurations.id) {
      loadGMaps(() => {
        setLoadMapCompleted(!loadMapCompleted)
      }, configurations.googleKey)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configurations.googleKey, configurations.id])

  return (
    <div className="modal fade" id="modalRoutesMaps">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="modalRoutesMapsLabel">Melhor trajeto para sua entrega </h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-sm-4 mb-4">
                <div className="card" style={{ width: '100%' }}>
                  <div className="card-header fw-bold">
                    Pedidos
                  </div>
                  <ul className="list-group list-group-flush">
                    {orders?.filter((e) => !!e?.deliveryDistrict?.location).map((item) => {
                      const exists = selecteds.find(e => e.id === item.id)
                      return (
                        <li
                          className="list-group-item pointer"
                          onClick={(e) => {
                            if (exists) {
                              return setSelecteds(selecteds.filter(e => e.id !== item.id))
                            }
                            setSelecteds([...selecteds, item])
                          }}
                          style={{
                            backgroundColor: exists ? '#f7f5f5' : '#fff',
                            borderLeft: exists ? '4px solid green' : '',
                          }}
                          key={item.id}
                        >
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <p className="fw-bold">Pedido</p>
                            <p style={{ fontWeight: 'bold' }}>{numOrderFormat(item?.numOrder)}</p>
                          </div>
                          <hr className="m-0 p-0 mb-1 mt-1" />
                          <p> {item.name} - {maskCurrency(item.total)}</p>
                          <p> Motoboy: {item?.motoboy?.name ?? "Sem entregador"}</p>
                        </li>
                      )
                    })}

                    {orders.length === 0 && <li className="list-group-item text-center">Sem pedidos</li>}
                  </ul>
                </div>
              </div>
              <div className="col-sm-8">
                {loadMapCompleted && <MapComponent dataDestinations={selecteds.map(e => ({ lat: e.deliveryDistrict.location.latitude, lng: e.deliveryDistrict.location.longitude, numOrder: e?.numOrder }))} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}