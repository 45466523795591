import { useDispatch, useSelector } from "react-redux";
import { api } from "../api/api";
import { SET_DISTRICTS } from "../stores/reducers/DistrictsReducers";

export function useDistrict() {
  const districts = useSelector(state => state.district.data)
  const dispatch = useDispatch()

  async function loadDeliveryDistrict() {
    const response = await api.get('/delivery-district')
    dispatch(SET_DISTRICTS(response.data))
  }

  return { districts, loadDeliveryDistrict }
}