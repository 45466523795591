
import React, { useState } from 'react'
import { createPortal } from 'react-dom'

import './style.css'

export const IFrame = ({
  children,
  ...props
}) => {
  const [contentRef, setContentRef] = useState(null)
  const mountNode =
    contentRef?.contentWindow?.document?.body

  return (
    <iframe id="printf" name="printf" {...props} ref={setContentRef} title='teste'>
      {mountNode && createPortal(children, mountNode)}
    </iframe>
  )
}