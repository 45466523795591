import { configureStore } from '@reduxjs/toolkit'

import ConfigurationReducer from './reducers/ConfiguratonReducers'
import DistrictsReducer from './reducers/DistrictsReducers'
import MotoboyReducers from './reducers/MotoboyReducers'
import CompanyReducer from './reducers/CompanyReducers'
import OrderReducers from './reducers/OrderReducers'
import SafeReducers from './reducers/SafeReducers'
import CategoryReducers from './reducers/CategoryReducers'
import SectorsReducers from './reducers/SectorReducers'

export default configureStore({
  reducer: {
    company: CompanyReducer,
    motoboy: MotoboyReducers,
    order: OrderReducers,
    configuration: ConfigurationReducer,
    district: DistrictsReducer,
    safe: SafeReducers,
    category: CategoryReducers,
    sector: SectorsReducers,
  }
})