import { useDispatch, useSelector } from "react-redux";
import { api } from "../api/api";
import { SET_COMPANY, UPDATE_COMPANY } from "../stores/reducers/CompanyReducers";
import { SET_CONFIGURATION, UPDATE_CONFIGURATION } from "../stores/reducers/ConfiguratonReducers";
import { SET_SECTORS_DATA } from "../stores/reducers/SectorReducers";

export function useCompany() {
  const sectors = useSelector(state => state.sector.data)
  const company = useSelector(state => state.company.data)
  const configurations = useSelector(state => state.configuration.data)
  const dispatch = useDispatch()

  async function loadConfiguration() {
    const response = await api.get('/configurations')
    dispatch(SET_CONFIGURATION({
      id: response.data.id,
      pricingMinDelivery: response.data.pricingMinDelivery,
      activeRetirada: Boolean(response.data.activeRetirada),
      activeRating: Boolean(response.data.activeRating),
      idStore: response.data.idStore ?? '',
      methodDelivery: response.data.methodDelivery,
      googleKey: response.data.googleKey,
      messagePrint: response.data.messagePrint,
      sound: response.data.sound,
      autoPrintBalcao: Boolean(response.data.autoPrintBalcao),
      autoPrintMesa: Boolean(response.data.autoPrintMesa),
      autoPrintDelivery: Boolean(response.data.autoPrintDelivery),
      autoPrintOnAddProduct: Boolean(response.data.autoPrintOnAddProduct),
      autoPrintOrder: Boolean(response.data.autoPrintOrder),
      defaultNamePrint: response.data.defaultNamePrint,
      defaultCodePrint: response.data.defaultCodePrint,
      printCancelProductOrder: response.data.printCancelProductOrder,
      printCancelOrder: response.data.printCancelOrder,
      automaticProductAcceptance: response.data.automaticProductAcceptance,
      discountDelivery: response.data.discountDelivery
    }))
  }

  async function loadSectors() {
    const response = await api.get('/sectors')
    dispatch(SET_SECTORS_DATA(response.data))
  }

  async function load() {
    const response = await api.get('/companies')
    dispatch(SET_COMPANY({
      ...response.data,
      timeDelivery: response.data?.timeDelivery,
      timeRetirada: response.data?.timeRetirada
    }))
  }

  function updatedComapny(data) {
    dispatch(UPDATE_COMPANY({ ...data }))
  }

  async function updateConfiguration(data) {
    dispatch(UPDATE_CONFIGURATION({ ...data }))
  }

  return { company, updatedComapny, loadSectors, sectors, configurations, loadConfiguration, load, updateConfiguration }
}