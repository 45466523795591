import { DateTime } from "luxon"
import { useContext, useEffect } from "react"
import { useState } from "react"
import { api } from "../../api/api"
import { ModalMotoboy } from "../../components/Modals/Motoboy"
import { NavBar } from "../../components/NavBar"
import { Toast } from "../../utils/Toast"
import { MotoboyContext } from "../../context/MotoboyContext"
import { useDispatch } from "react-redux"
import { SET_MOTOBOYS } from "../../stores/reducers/MotoboyReducers"
import { FaSearch } from "react-icons/fa"
import { BsFillBoxFill } from "react-icons/bs"
import { OrdersWithMotoboyModal } from "./components/OrdersWithMotoboy"
import ReactInputMask from "react-input-mask"
import { MdOutlineSportsMotorsports } from "react-icons/md"
import { HiTrash } from "react-icons/hi"
import { GlobalContext } from "../../context/GlobalContext"

export function MotoboyPage() {
  const { isAccess } = useContext(GlobalContext)
  const dispatch = useDispatch()
  const { findAllMotoboys, motoboys } = useContext(MotoboyContext)
  const [search, setSearch] = useState('')
  const [timer, setTimer] = useState(null)
  const [selectMotoboyId, setSelectMotoboyId] = useState('')

  async function updated(id, key, valueInput) {
    if (!isAccess('entregadores', 'update')) {
      Toast.warning('Você não tem permissão para editar entregadores.')
      return
    }

    dispatch(SET_MOTOBOYS(motoboys.map((e) => e.id === id ? { ...e, [key]: valueInput } : { ...e })))

    clearTimeout(timer)

    const newTimer = setTimeout(async () => {
      if (!id || !key) {
        Toast.success()
      }
      await api.put('/motoboys/' + id, {
        [key]: valueInput
      }).then(() => {
        Toast.success("Atualizado com sucesso")
      }).catch((res) => {
        Toast.warning()
      })
    }, 800)

    setTimer(newTimer)
  }

  async function deleteMotoboy(id) {
    if (!isAccess('entregadores', 'delete')) {
      Toast.warning('Você não tem permissão para deletar entregadores.')
      return
    }

    const confirmation = await Toast.confirm()
    if (confirmation) {
      await api.delete(`/motoboys/${id}`)
      await findAllMotoboys()
    }
  }

  useEffect(() => {
    if (selectMotoboyId) {
      new window.bootstrap.Modal(document.getElementById('modal-orders-motoboy')).show()
    }
  }, [selectMotoboyId])

  return (
    <>
      <NavBar>
        <ModalMotoboy />
        {selectMotoboyId && <OrdersWithMotoboyModal id={selectMotoboyId} set={setSelectMotoboyId} />}
        <div className="container-fluid">
          <div className="mt-3 p-2 bg-dark-blue-1 rounded fw-bold d-flex justify-content-between  align-items-center border mb-3">
            <p className="text-white" style={{ fontSize: '1.3rem' }}>  <MdOutlineSportsMotorsports className="mb-1" color='white' size={22} /> Entregadores</p>
            {isAccess('entregadores', 'create') && (
              <button className="btn btn-sm bg-light btn-circle" data-bs-toggle="modal" data-bs-target="#modalMotoboy">
                <i classNamupdatee="fas fa-plus"></i>
              </button>
            )}
          </div>

          <div className="row">
            <div className="input-group mb-3">
              <input type="text" className="form-control form-control-sm" value={search} onChange={(e) => setSearch(e.target.value)} />
              <span className="input-group-text bg-white text-gray pointer" id="basic-addon2" onClick={() => findAllMotoboys(search)}>
                <FaSearch />
              </span>
            </div>

          </div>

          <table className="table table-ms" style={{ fontSize: '1rem' }}>
            <thead>
              <tr>
                <th >#</th>
                <th >Nome</th>
                <th >Telefone</th>
                <th >Ativo</th>
                <th >Criado</th>
                <th >Atualizado</th>
                <th >Pedidos</th>
                <th >Excluir</th>
              </tr>
            </thead>
            <tbody>
              {motoboys.map((motoboy) => {
                return (
                  <tr key={motoboy.id}>
                    <th scope="row">
                      <span href={motoboy.id}>#</span>
                    </th>
                    <td>
                      <input className="form-control form-control-sm"
                        style={{ width: 150, marginRight: 10, textAlign: 'center' }} type="text" aria-label=".form-control-sm example"
                        value={motoboy.name}
                        onChange={(e) => updated(motoboy.id, 'name', e.target.value)}
                      />
                    </td>
                    <td>
                      <ReactInputMask
                        style={{ width: 150, marginRight: 10, textAlign: 'center' }}
                        mask="(99) 9 9999-9999"
                        className="form-control form-control-sm"
                        placeholder="Digite seu telefone"
                        value={motoboy.phone}
                        onChange={(e) => updated(motoboy.id, 'phone', e.target.value)}
                      />
                    </td>
                    <td>
                      <div className="form-check form-check-sm form-switch" style={{ fontSize: '1rem' }}>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={motoboy.active}
                          onChange={(e) => updated(motoboy.id, 'active', e.target.checked)}
                          role="switch" id="flexSwitchCheckDefault"
                        />
                      </div>
                    </td>
                    <td>{DateTime.fromISO(motoboy.createdAt).toFormat("dd/MM/yyyy HH:mm")}</td>
                    <td>{DateTime.fromISO(motoboy.updatedAt).toFormat("dd/MM/yyyy HH:mm")}</td>
                    <td>
                      <span className="pointer">
                        <BsFillBoxFill size={'1.5rem'} onClick={() => setSelectMotoboyId(motoboy.id)} />
                      </span>
                    </td>
                    <td><HiTrash onClick={() => deleteMotoboy(motoboy.id)} size={'1.5rem'} color="red" className="pointer" /></td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </NavBar>
    </>
  )
}


