export const INITIAL_STATE_INFO_FISCALE = {
  id: "",
  referencia: "",
  descricao: "",
  icms: [
    {
      tipo_tributacao: "simples_nacional",
      cenario: "saida_dentro_estado",
      tipo_pessoa: "fisica",
      nao_contribuinte: false,
      codigo_cfop: "5102",
      situacao_tributaria: "102",
      aliquota_credito: "",
      aliquota_mva: [
        {
          estado: "",
          aliquota: "0.00",
        },
      ],
      aliquota_diferimento: "0.00",
      aliquota_diferimento_fcp: "0.00",
      aliquota_reducao: "0.00",
      aliquota_reducao_st: "0.00",
      motivo_desoneracao: "9",
      motivo_desoneracao_st: "9",
      beneficio_fiscal: [
        {
          estado: "",
          codigo: "",
        },
      ],
    },
  ],
  ipi: [
    {
      cenario: "padrao",
      tipo_pessoa: "fisica",
      situacao_tributaria: "99",
      codigo_enquadramento: "999",
      aliquota: "0.00",
    },
  ],
  pis: [
    {
      cenario: "padrao",
      tipo_pessoa: "fisica",
      situacao_tributaria: "99",
      aliquota: "0.00",
    },
  ],
  cofins: [
    {
      cenario: "padrao",
      tipo_pessoa: "fisica",
      situacao_tributaria: "99",
      aliquota: "0.00",
    },
  ],
  issqn: [
    {
      cenario: "",
      tipo_pessoa: "",
      codigo_cfop: "",
      exigibilidade: "",
      item_servico: "",
      incentivo_fiscal: "",
      aliquota: "",
    },
  ],
  informacoes_fisco: "",
  informacoes_complementares: "",
}

export const INITIAL_STATE_ENABLE_INFO_FISCALE = {
  description: false,
  icms: true,
  ipi: true,
  pis: true,
  cofins: true,
  issqn: true,
  information: true,
  createdRefs: true,
}

export const INITIAL_STATE_ICMS = {
  tipo_tributacao: "simples_nacional",
  cenario: "saida_dentro_estado",
  tipo_pessoa: "fisica",
  nao_contribuinte: false,
  codigo_cfop: "5102",
  situacao_tributaria: "102",
  aliquota_credito: "0.00",
  aliquota_mva: [
    {
      estado: "",
      aliquota: "0.00",
    },
  ],
  aliquota_diferimento: "0.00",
  aliquota_diferimento_fcp: "0.00",
  aliquota_reducao: "0.00",
  aliquota_reducao_st: "0.00",
  motivo_desoneracao: "9",
  motivo_desoneracao_st: "9",
  beneficio_fiscal: [
    {
      estado: "",
      codigo: "",
    },
  ],
}

export const INITIAL_STATE_ALIQUOTA_MVA = {
  estado: "",
  aliquota: "0.00",
}

export const INITIAL_STATE_IPI = {
  cenario: "padrao",
  tipo_pessoa: "fisica",
  situacao_tributaria: "99",
  codigo_enquadramento: "999",
  aliquota: "0.00",
}

export const INITIAL_STATE_PIS = {
  cenario: "padrao",
  tipo_pessoa: "fisica",
  situacao_tributaria: "99",
  aliquota: "0.00",
}

export const INITIAL_STATE_COFINS = {
  cenario: "padrao",
  tipo_pessoa: "fisica",
  situacao_tributaria: "99",
  aliquota: "0.00",
}

export const INITIAL_STATE_ISSQN = {
  cenario: "",
  tipo_pessoa: "",
  codigo_cfop: "",
  exigibilidade: "",
  item_servico: "",
  incentivo_fiscal: "",
  aliquota: "",
}

export const INITIAL_STATE_ADDITIONAL_DATA = {
  id: "",
  referencia: "",
  descricao: "",
  informacoes_fisco: "",
  informacoes_complementares: "",
}

export const INITIAL_STATE_OBJECT_ICMS = {
  "101": {
    aliquota_credito: true,
    aliquota_mva: false,
    aliquota_diferimento: false,
    aliquota_diferimento_fcp: false,
    aliquota_reducao: false,
    aliquota_reducao_st: false,
    motivo_desoneracao: false,
    motivo_desoneracao_st: false,
    beneficio_fiscal: false,
  },
  "102": {
    aliquota_credito: false,
    aliquota_mva: false,
    aliquota_diferimento: false,
    aliquota_diferimento_fcp: false,
    aliquota_reducao: false,
    aliquota_reducao_st: false,
    motivo_desoneracao: false,
    motivo_desoneracao_st: false,
    beneficio_fiscal: false,
  },
  "103": {
    aliquota_credito: false,
    aliquota_mva: false,
    aliquota_diferimento: false,
    aliquota_diferimento_fcp: false,
    aliquota_reducao: false,
    aliquota_reducao_st: false,
    motivo_desoneracao: false,
    motivo_desoneracao_st: false,
    beneficio_fiscal: false,
  },
  "201": {
    aliquota_credito: true,
    aliquota_mva: true,
    aliquota_diferimento: false,
    aliquota_diferimento_fcp: false,
    aliquota_reducao: true,
    aliquota_reducao_st: true,
    motivo_desoneracao: false,
    motivo_desoneracao_st: false,
    beneficio_fiscal: false,
  },
  "202": {
    aliquota_credito: false,
    aliquota_mva: true,
    aliquota_diferimento: false,
    aliquota_diferimento_fcp: false,
    aliquota_reducao: true,
    aliquota_reducao_st: true,
    motivo_desoneracao: false,
    motivo_desoneracao_st: false,
    beneficio_fiscal: false,
  },
  "203": {
    aliquota_credito: false,
    aliquota_mva: true,
    aliquota_diferimento: false,
    aliquota_diferimento_fcp: false,
    aliquota_reducao: true,
    aliquota_reducao_st: true,
    motivo_desoneracao: false,
    motivo_desoneracao_st: false,
    beneficio_fiscal: false,
  },
  "300": {
    aliquota_credito: false,
    aliquota_mva: false,
    aliquota_diferimento: false,
    aliquota_diferimento_fcp: false,
    aliquota_reducao: false,
    aliquota_reducao_st: false,
    motivo_desoneracao: false,
    motivo_desoneracao_st: false,
    beneficio_fiscal: false,
  },
  "400": {
    aliquota_credito: false,
    aliquota_mva: false,
    aliquota_diferimento: false,
    aliquota_diferimento_fcp: false,
    aliquota_reducao: false,
    aliquota_reducao_st: false,
    motivo_desoneracao: false,
    motivo_desoneracao_st: false,
    beneficio_fiscal: false,
  },
  "500": {
    aliquota_credito: false,
    aliquota_mva: false,
    aliquota_diferimento: false,
    aliquota_diferimento_fcp: false,
    aliquota_reducao: false,
    aliquota_reducao_st: false,
    motivo_desoneracao: false,
    motivo_desoneracao_st: false,
    beneficio_fiscal: false,
  },
  "900": {
    aliquota_credito: false,
    aliquota_mva: true,
    aliquota_diferimento: false,
    aliquota_diferimento_fcp: false,
    aliquota_reducao: true,
    aliquota_reducao_st: true,
    motivo_desoneracao: false,
    motivo_desoneracao_st: false,
    beneficio_fiscal: false,
  },
  "00": {
    aliquota_credito: false,
    aliquota_mva: false,
    aliquota_diferimento: false,
    aliquota_diferimento_fcp: false,
    aliquota_reducao: false,
    aliquota_reducao_st: false,
    motivo_desoneracao: false,
    motivo_desoneracao_st: false,
    beneficio_fiscal: false,
  },
  "10": {
    aliquota_credito: false,
    aliquota_mva: true,
    aliquota_diferimento: false,
    aliquota_diferimento_fcp: false,
    aliquota_reducao: false,
    aliquota_reducao_st: true,
    motivo_desoneracao: false,
    motivo_desoneracao_st: false,
    beneficio_fiscal: false,
  },
  "20": {
    aliquota_credito: false,
    aliquota_mva: false,
    aliquota_diferimento: false,
    aliquota_diferimento_fcp: false,
    aliquota_reducao: true,
    aliquota_reducao_st: false,
    motivo_desoneracao: true,
    motivo_desoneracao_st: true,
    beneficio_fiscal: false,
  },
  "30": {
    aliquota_credito: false,
    aliquota_mva: true,
    aliquota_diferimento: false,
    aliquota_diferimento_fcp: false,
    aliquota_reducao: false,
    aliquota_reducao_st: true,
    motivo_desoneracao: false,
    motivo_desoneracao_st: false,
    beneficio_fiscal: false,
  },
  "40": {
    aliquota_credito: false,
    aliquota_mva: false,
    aliquota_diferimento: false,
    aliquota_diferimento_fcp: false,
    aliquota_reducao: false,
    aliquota_reducao_st: false,
    motivo_desoneracao: true,
    motivo_desoneracao_st: true,
    beneficio_fiscal: false,
  },
  "41": {
    aliquota_credito: false,
    aliquota_mva: false,
    aliquota_diferimento: false,
    aliquota_diferimento_fcp: false,
    aliquota_reducao: false,
    aliquota_reducao_st: false,
    motivo_desoneracao: true,
    motivo_desoneracao_st: true,
    beneficio_fiscal: false,
  },
  "50": {
    aliquota_credito: false,
    aliquota_mva: false,
    aliquota_diferimento: false,
    aliquota_diferimento_fcp: false,
    aliquota_reducao: false,
    aliquota_reducao_st: false,
    motivo_desoneracao: true,
    motivo_desoneracao_st: true,
    beneficio_fiscal: false,
  },
  "51": {
    aliquota_credito: false,
    aliquota_mva: false,
    aliquota_diferimento: true,
    aliquota_diferimento_fcp: true,
    aliquota_reducao: false,
    aliquota_reducao_st: false,
    motivo_desoneracao: true,
    motivo_desoneracao_st: true,
    beneficio_fiscal: false,
  },
  "60": {
    aliquota_credito: false,
    aliquota_mva: false,
    aliquota_diferimento: false,
    aliquota_diferimento_fcp: false,
    aliquota_reducao: false,
    aliquota_reducao_st: false,
    motivo_desoneracao: false,
    motivo_desoneracao_st: false,
    beneficio_fiscal: false,
  },
  "70": {
    aliquota_credito: false,
    aliquota_mva: true,
    aliquota_diferimento: true,
    aliquota_diferimento_fcp: true,
    aliquota_reducao: true,
    aliquota_reducao_st: true,
    motivo_desoneracao: true,
    motivo_desoneracao_st: true,
    beneficio_fiscal: false,
  },
  "90": {
    aliquota_credito: false,
    aliquota_mva: true,
    aliquota_diferimento: true,
    aliquota_diferimento_fcp: true,
    aliquota_reducao: true,
    aliquota_reducao_st: true,
    motivo_desoneracao: true,
    motivo_desoneracao_st: true,
    beneficio_fiscal: false,
  },
}


