import { useContext, useEffect, useRef, useState } from "react"
import { MenuContext } from "../../context/MenuContext"
import { Additional } from "../Additional"
import { Toast } from "../../utils/Toast";
import { api } from "../../api/api";
import { FaAlignJustify, FaAngleDown } from "react-icons/fa";
import _ from "lodash";

export function CategoryAdditional({ item }) {
  const { data, setData, isLocalLoadRef, setIsDragging, isDragging, setCategoryAdditionalId, load, setCopyAndPaste, setCategoryAdditionalEdit } = useContext(MenuContext)
  const [timer, setTimer] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const dragItem = useRef();
  const dragOverItem = useRef();

  const [state, setState] = useState({
    id: null,
    name: '',
    active: false,
    min: 0,
    max: 0
  })

  async function updated(id, key, valueInput) {
    if (!id || !key) {
      Toast.warning()
    }

    clearTimeout(timer)

    const newTimer = setTimeout(async () => {
      await api.put('/category/additional/' + id, {
        [key]: valueInput
      }).then(() => {
        Toast.success('Registro atualizado com sucesso.')
      })
    }, 1000)

    setTimer(newTimer)
  }

  async function destroy(id) {
    const confirmation = await Toast.confirm()
    if (confirmation) {
      await api.delete('/category/additional/' + id)
      await load() // TODO - Colocara para retirar do array que já foi buscado, melhor que recarregar tudo
    }
  }

  useEffect(() => {
    setState(() => ({
      id: item.id,
      name: item.name,
      active: Boolean(Number(item.active)),
      min: item.min,
      max: item.max
    }))
  }, [item])

  function editCategoryAdditional(body) {
    setCategoryAdditionalEdit(body)
  }

  function updateAssortmentAdditionalProduct(items) {
    const calculate = items.map((e, index) => {
      return {
        id: e.id,
        name: e.name,
        assortment: index + 1
      }
    })

    clearTimeout(timer)

    const newTimer = setTimeout(async () => {
      await api.put('/additional/additionals/assortment', calculate)
        .then(() => {
          Toast.success("Informação atualizada.")
        })
    }, 1000)

    setTimer(newTimer)
  }

  function isDraggable() {
    const aux = !modalOpen

    setModalOpen(oldState => aux)

    if (aux) {
      setIsDragging({ name: 'additional', draggable: true })
    } else {
      setIsDragging({ name: 'categoryAdditional', draggable: true })
    }
  }

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = (e) => {
    const copyListItems = [...item.additional];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;

    const dataSorted = _.orderBy(data, isLocalLoadRef.current === false ? 'assortment' : 'assortment_local', 'asc')

    const newData = dataSorted.map((value, index) => {
      return {
        ...value,
        assortment: index + 1,
        productsOfCategory: value.productsOfCategory.map((product, indexProduct) => {
          return {
            ...product,
            assortment: indexProduct + 1,
            categoryAdditional: product.categoryAdditional.map((_value, _index) => {
              return {
                ..._value,
                assortment: _index + 1,
                additional: copyListItems.map((__value, __index) => {
                  return {
                    ...__value,
                    assortment: __index + 1,
                  }
                })
              }
            })
          }
        })
      }
    })

    updateAssortmentAdditionalProduct(copyListItems)
    setData(newData)
  };


  const id = item.id.replace(/-/ig, '').replace(/[0-9]/g, '');
  const name = item.name.split(" ").join("")
  const active = Boolean(Number(item.active))
  const styleAccordion = {
    borderLeft: `5px solid ${active ? '#13293D' : '#dcdcdc  '}`,
    borderRadius: 5,
    marginBottom: 5
  }

  return (
    <div className="accordion-item" key={id} style={styleAccordion}>
      <h2 className="accordion-header" id={`${id}-${name}`} style={{ display: 'flex', alignItems: 'center' }}>
        <button
          style={{ display: 'flex', alignItems: 'center', gap: 10, fontSize: '1rem' }}
          className="accordion-button" type="button"
          data-bs-toggle="collapse" data-bs-target={`#${id}`}
          aria-expanded="true"
          aria-controls={id}
          onClick={() => isDraggable()}
        >
          <FaAlignJustify color="#666" size="1rem" />
          {state.name}
        </button>
        <div style={{ display: 'flex', alignItems: "center", justifyContent: 'space-between', gap: 25 }}>
          <div className="d-flex" >
            <label htmlFor="staticEmail" className="col-sm-6 col-form-label" style={{ fontSize: '0.9rem' }}>Min</label>
            <input className="form-control form-control-sm input-focus"
              style={{ width: 50, height: 15, textAlign: 'center', fontSize: '0.9rem' }} type="text" aria-label=".form-control-sm example"
              value={state.min}
              onChange={(input) => {
                setState({
                  ...state,
                  min: input.target.value
                })
                updated(item.id, 'min', input.target.value)
              }}
            />
          </div>
          <div className="d-flex" >
            <label htmlFor="staticEmail" className="col-sm-6 col-form-label" style={{ fontSize: '0.9rem' }}>Max</label>
            <input className="form-control form-control-sm input-focus"
              style={{ width: 50, height: 16, fontSize: '0.9rem', textAlign: 'center' }} type="text" aria-label=".form-control-sm example"
              value={state.max}
              onChange={(input) => {
                setState({ ...state, max: input.target.value })
                updated(item.id, 'max', input.target.value)
              }}
            />
          </div>
          <div className="form-check form-check-sm form-switch" style={{ fontSize: '1rem' }}>
            <input className="form-check-input" type="checkbox" defaultChecked={active} onChange={(input) => updated(item.id, 'active', input.target.checked)} role="switch" id="flexSwitchCheckDefault" />
          </div>
          <div className="dropdown">
            <button className="btn btn-sm b-border" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="true">
              <FaAngleDown />
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li className="dropdown-item" onClick={() => setCategoryAdditionalId(item.id)} data-bs-toggle="modal" data-bs-target="#modalAdditional">Adicionar</li>
              <li className="dropdown-item" data-bs-toggle="modal" data-bs-target="#modalCategoryAdditional" onClick={() => editCategoryAdditional(item)}>Editar</li>
              <li className="dropdown-item" onClick={() => destroy(item.id)}>Deletar</li>
              <li className="dropdown-item" onClick={() => setCopyAndPaste(item.id)}>Copiar</li>
            </ul>
          </div>
        </div>
      </h2>
      <div id={id} className="accordion-collapse collapse" aria-labelledby={`${id}-${name}`} data-bs-parent="#category-additional">
        <div className="accordion-body">
          <ul className="list-group">
            {item.additional.map((x, index) => (
              <div
                className="pointer"
                onDragStart={(e) => (isDragging.name === 'additional') ? dragStart(e, index) : null}
                onDragEnter={(e) => (isDragging.name === 'additional') ? dragEnter(e, index) : null}
                onDragEnd={() => (isDragging.name === 'additional') ? drop() : null}
                draggable={(isDragging.name === 'additional') ? true : false}
                key={x.id}
              >
                <Additional item={x} key={x.id} />
              </div>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}