import { createSlice } from '@reduxjs/toolkit'

export const CompanyReducer = createSlice({
  name: 'company',
  initialState: {
    data: {
      cpfResponsible: '',
      phoneResponsible: '',
      nameResponsible: '',
      banner: "",
      name: "",
      phone: "",
      whatsapp: "",
      city: "",
      uf: "",
      address: "",
      number: "",
      district: "",
      cep: "",
      link: "",
      domain: "",
      timeDelivery: "",
      timeRetirada: "",
      location: {
        lat: '',
        lng: ''
      }
    }
  },
  reducers: {
    SET_COMPANY: (state, action) => {
      state.data = action.payload
    },
    UPDATE_COMPANY: (state, action) => {
      state.data = { ...state.data, ...action.payload }
    }
  }
})

export const { SET_COMPANY, UPDATE_COMPANY } = CompanyReducer.actions

export default CompanyReducer.reducer