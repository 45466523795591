import { createSlice } from '@reduxjs/toolkit'
import { DateTime } from 'luxon'


export const INITIAL_STATE = {
  data: [],
  safe: {
    id: null,
    type: "saida",
    typePayment: null,
    pricing: 0,
    orderId: null,
    description: "",
    date: DateTime.local().toSQLDate()
  },
}

export const SafeReducer = createSlice({
  name: 'safe',
  initialState: INITIAL_STATE,
  reducers: {
    SET_SAFE: (state, action) => {
      state.safe = action.payload
    },
    SET_SAFE_DATA: (state, action) => {
      state.data = action.payload
    },
    ADD_NEW_SAFE: (state, action) => {
      state.data.push(action.payload)
    },
    UPDATE_SAFE: (state, action) => {
      state.data = state.data.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload
        }

        return item
      })
    },
  }
})

export const { ADD_NEW_SAFE, SET_SAFE_DATA, SET_SAFE, UPDATE_SAFE } = SafeReducer.actions

export default SafeReducer.reducer