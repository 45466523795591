import { MotoboyContext } from "../context/MotoboyContext";
import { useMotoboy } from "../hooks/useMotoboy";

export const MotoboyProvider = ({ children }) => {
  const { motoboys, resetStateMotoboy, stateMotoboy, addNewMotoboy, updateMotoboy, findAllMotoboys } = useMotoboy()

  return (
    <MotoboyContext.Provider value={{ stateMotoboy, resetStateMotoboy, motoboys, addNewMotoboy, updateMotoboy,findAllMotoboys }}>
      {children}
    </MotoboyContext.Provider>
  )
}