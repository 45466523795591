
import { FaEdit, FaUser } from "react-icons/fa";
import { NavBar } from "../../components/NavBar";

import useSWR, { useSWRConfig } from "swr";

import { api } from "../../api/api";
import { PermissionsModal } from "../../components/Modals/Permissions";
import { useState } from "react";

export function PageUsers() {
  const [userId, setUserId] = useState(null)

  const { mutate } = useSWRConfig()
  const { data } = useSWR('/users', async () => {
    const response = await api.get('/users')

    return response.data
  })

  return (
    <>
      <NavBar>
        <PermissionsModal userId={userId} reload={() => mutate('/users')} clearSelectUser={() => setUserId(null)} />
        <div className="container-fluid">
          <div className="mt-3 p-2 bg-dark-blue-1 rounded fw-bold d-flex justify-content-between  align-items-center border mb-3">
            <p className="text-white" style={{ fontSize: '1.3rem' }}>  <FaUser className="mb-1" color='white' size={22} /> Usuários </p>
            <button className="btn btn-sm bg-light btn-circle" onClick={() => new window.bootstrap.Modal(document.getElementById('modalPermissions'), {}).toggle()}>
              <i className="fas fa-plus"></i>
            </button>
          </div>
          <div style={{ maxHeight: 600, minHeight: 200, overflow: 'auto' }}>
            <table className="table table-sm">
              <thead>
                <tr>
                  <th >
                    Nome
                  </th>
                  <th >Tipo</th>
                  <th >Ativo</th>
                  <th >Permissões</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((user) => {
                  return (
                    <tr key={user.id}>
                      <td>{user.email}</td>
                      <td>
                        {{ admin: 'Administrador', sub_admin: 'Gerente', garcom: 'Garçom', user: 'Usuário' }[user.typeUser]}
                      </td>
                      <td>
                        {user.status ? <span className="badge bg-success">Ativo</span> : <span className="badge bg-danger">Inátivo</span>}
                      </td>
                      <td className="">
                        <span>
                          <FaEdit className="pointer text-center" onClick={() => {
                            setUserId(user.id)
                          }} />
                        </span>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </NavBar>
    </>
  )
}