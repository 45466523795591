import { api } from '../api/api'
import axios from 'axios'
import { Toast } from '../utils/Toast';

export async function sendPrint(type, data, idOrder) {
  const response = await api.post('/orders/print', {
    type,
    data,
    idOrder
  })

  if (response.data) {
    var newWindow = window.open('', '', `width=${window.screen.width},height=${window.screen.height}`);
    newWindow.document.write(response.data)
    newWindow.print()
    newWindow.close()
  }
}

export async function sendPrintNota(url = '', codePrint, namePrint) {
  if (!url || !codePrint || !namePrint) {
    Toast.warning('Configure a impressora padrão por favor.')
    window.open(url, '_blank')
    return;
  }

  const response = await axios.get(url)

  await api.post('/printing', {
    codePrint,
    namePrint,
    body: response.data
  })
}

