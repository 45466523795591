import { useState } from "react"
import { Toast } from "../../../utils/Toast";
import { api } from "../../../api/api";
import { DateTime } from "luxon";

function getIP() {
  return new Promise((resolve, reject) => {
    const RTCPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;
    const pc = new RTCPeerConnection({ iceServers: [] });
    const noop = () => { };

    // Cria uma oferta para obter informações sobre a conexão
    pc.createDataChannel('');

    pc.createOffer(pc.setLocalDescription.bind(pc), noop);

    // Escuta os candidatos ICE para obter o endereço IP
    pc.onicecandidate = function (event) {
      if (event && event.candidate && event.candidate.candidate) {
        const ip = event.candidate.candidate.split(' ')[4];
        pc.onicecandidate = noop; // Evita chamadas múltiplas
        return resolve(ip);
      }
    };
  })
}

async function obterLocalizacao() {
  return new Promise((resolve, reject) => {

  });
}


export function Contract() {
  if ("geolocation" in navigator) {
    navigator.geolocation.getCurrentPosition(
      position => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        localStorage.setItem('location', JSON.stringify({ latitude, longitude }));
      },
      error => {
        localStorage.setItem('location', false);
      }
    );
  } else {
    localStorage.setItem('location', false);
  }

  const [termos, setTermos] = useState(false)

  async function sendTerm() {
    try {
      const location = localStorage.getItem('location')
      if (!location || location === 'false') {
        return Toast.warning("Para poder aceita os termos ative a localização por favor.")
      }
      const ip = await getIP()
      await api.post('/mercado-pago/set/term', {
        ip,
        location,
        term: termos,
        date: DateTime.local().toISO()
      })

      if (termos === true) {
        localStorage.setItem('modal-contract', false)
      }

      if (termos === false) {
        localStorage.setItem('modal-contract', true)
      }
      Toast.success('Obrigado!')
      setTimeout(() => {
        window.location = '/home'
      }, 1000);
    } catch (error) {
      return Toast.warning("Erro ao aceitar contrato")
    }
  }

  return (
    <div className="modal fade" id="contractHome" tabIndex="-1" aria-labelledby="contractHomeLabel" aria-hidden="true">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="contractHomeLabel">Manual Inical</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="alert alert-primary" role="alert">
              Aceite os termos para utilizar o sistema.
            </div>
            <iframe loading="lazy" width={'100%'} height={650} title='Manual' src="https://pedidos-images.s3.amazonaws.com/contrato.pdf" frameBorder="1"></iframe>
          </div>
          <div className="ms-3 mb-3">
            <h5>Aceitar termos:</h5>
            <div className="form-check">
              <input style={{ fontSize: 16 }} onChange={(e) => setTermos(e.target.checked)} value={termos} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
              <label style={{ fontSize: 16 }} className="form-check-label" htmlFor="flexRadioDefault1">
                Aceito os termos de uso
              </label>
            </div>
            <div className="form-check">
              <input style={{ fontSize: 16 }} onChange={(e) => setTermos(e.target.checked)} value={termos} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
              <label style={{ fontSize: 16 }} className="form-check-label" htmlFor="flexRadioDefault2">
                Não Aceito os termos de uso
              </label>
            </div>

            <button className="btn btn-success me-3" id="button-finalizar" style={{ float: 'right' }} onClick={async (e) => {
              e.currentTarget.disabled = true
              await sendTerm()
              document.getElementById('button-finalizar').disabled = false
            }}>Concluir</button>
          </div>
        </div>
      </div>
    </div>
  )
}