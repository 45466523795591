import { useEffect, useState } from "react"
import { api } from "../../api/api"
import { Toast } from "../../utils/Toast"
import { convertValueTypeStock } from "../../utils/utils"
import { FaTrashAlt } from "react-icons/fa"
import { v4 } from 'uuid'


const dataSheetDefault = {
  idAdditional: null,
  idProduct: null,
  items: []
}
export function DataSheet({ id, keyValue }) {
  const [loading, setLoading] = useState(false)
  const [stock, setStock] = useState([])
  const [state, setState] = useState({
    idStock: -1,
    name: '',
    stockWriteOff: 0,
    stockAlert: 0,
    typeStock: null
  })

  const [dataSheet, setDatasheet] = useState(dataSheetDefault)

  function addInsumos(item) {
    if (item?.idStock === -1) {
      Toast.warning('Selecione o insumo')
      return
    }

    if (!id) {
      return Toast.warning('O produto deve ser selecionado')
    }

    if (dataSheet.items.find(e => e.idStock === item.idStock)) {
      Toast.warning('Este insumo já está adicionado')
      return
    }

    const aux = {
      ...dataSheet,
      [keyValue]: id,
      items: [
        ...dataSheet.items,
        {
          id: item?.id ?? v4(),
          idStock: item.idStock,
          name: item.name,
          stockWriteOff: item.stockWriteOff,
          stockAlert: item.stockAlert,
          typeStock: item.typeStock,
          stock: item.stock
        }
      ]
    }

    setDatasheet(aux)

    clearState()
  }

  async function findStock() {
    try {
      const response = await api.get('/stocks')

      setStock(response.data)
    } catch (error) {
      setStock([])
    }
  }

  function clearState() {
    setState({
      idStock: -1,
      name: '',
      stockWriteOff: 0,
      stockAlert: 0,
      typeStock: null
    })
  }

  async function findDataSheet(id) {
    const response = await api.get(`/datasheets/${keyValue}/${id}`)

    setDatasheet({
      ...dataSheet,
      items: response.data.map((value) => {
        return {
          id: value.id,
          idStock: value.idStock,
          name: value.name,
          stockWriteOff: value.stockWriteOff,
          stockAlert: value.stockAlert,
          typeStock: value.type,
          stock: value.stock
        }
      })
    })
  }

  async function createDataSheet() {
    if (loading) {
      return
    }

    setLoading(true)

    if (keyValue === 'idProduct') {
      await api.post('/datasheets/product', {
        [keyValue]: id,
        items: dataSheet.items.map((e) => {
          return {
            idStock: e.idStock,
            stockWriteOff: e.stockWriteOff
          }
        })
      })
    }

    if (keyValue === 'idAdditional') {
      await api.post('/datasheets/additional', {
        [keyValue]: id,
        items: dataSheet.items.map((e) => {
          return {
            idStock: e.idStock,
            stockWriteOff: e.stockWriteOff
          }
        })
      })
    }


    Toast.success()
    setLoading(false)
  }

  function removeInsumo(id) {
    setDatasheet({
      ...dataSheet,
      items: dataSheet.items.filter(e => e.id !== id)
    })
  }

  useEffect(() => {
    findStock()
  }, [])

  useEffect(() => {
    if (id)
      findDataSheet(id)
    else
      setDatasheet(dataSheetDefault)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <>
      <div className="row">
        <div className="col-sm-5">
          <label htmlFor="">Estoque</label>
          <select value={state.idStock} onChange={(e) => {
            const selectStock = stock.find(x => x.id === e.target.value)
            if (selectStock) {
              setState({ ...state, idStock: e.target.value, name: selectStock.name, stockAlert: selectStock.stockAlert, typeStock: selectStock.type, stock: selectStock.stock })
            }

            if (e.target.value === -1 || !selectStock) {
              clearState()
            }
          }} className="form-select form-select-sm" aria-label=".form-select-sm example">
            <option value={-1}>Selecione</option>
            {stock.map(value => {
              return <option key={value.id} value={value.id}>{value.name} - {value.type}</option>
            })}
          </select>
        </div>
        <div className="col-sm-3">
          <label htmlFor="">Quantidade</label>
          <input type="number" className="form-control form-control-sm" value={state.stockWriteOff} onChange={(e) => {
            setState({ ...state, stockWriteOff: parseFloat(e.target.value) })
          }} />
        </div>

        <div className="col-sm-2 mt-4">
          <button className="btn btn-sm btn-dark" onClick={() => addInsumos(state)}>Adicionar</button>
        </div>
      </div>
      <hr />
      <div className="mt-2 p-2 rounded">
        <h6>Insumos adicionados</h6>
        <table className="table-sm table mt-2">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Quantidade para baixar</th>
              <th>Estoque Min</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {dataSheet.items.map(value => {
              return (
                <tr key={value.name}>
                  <th>{value.name}</th>
                  <td>
                    {
                      convertValueTypeStock(value.typeStock, value.stockWriteOff)
                    }
                  </td>
                  <td>{convertValueTypeStock(value.typeStock, value.stock)}</td>
                  <td>
                    <div className="fw-bold border p-1 text-center rounded pointer" onClick={() => removeInsumo(value.id)} >
                      <FaTrashAlt />
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <hr />
      <button className="btn btn-success ms-4" disabled={id ? false : true} style={{ float: 'right' }} onClick={() => createDataSheet()}>
        Salvar Ficha
      </button>
      {loading && (
        <div style={{ float: 'right' }} className="spinner-border text-success" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      )}
    </>
  )
}