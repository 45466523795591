import { useContext, useEffect, useState } from "react"
import useSWR, { useSWRConfig } from "swr"
import { BiTable } from "react-icons/bi"
import _ from 'lodash'

import { api } from "../../api/api";
import { GlobalContext } from "../../context/GlobalContext";
import { ModalTable } from "./ModalTable";
import { Toast } from "../../utils/Toast";
import './style.css'
import { OrderContext } from "../../context/OrderContext";
import { Card } from "../../pages/Home/components/Card";
import { maskCurrency } from "../../utils/utils";
import { sendPrint } from "../../reporting/print";


export function TableComponent({ setCountTables, printPage, dataProductsNotSeen }) {
  const { isAccess } = useContext(GlobalContext)
  const { orders, selectOrder } = useContext(OrderContext)
  const [filter, setFilter] = useState('')

  const [selectTable, setSelectTable] = useState({
    table: null,
  })

  const { mutate } = useSWRConfig()
  const { data, isLoading } = useSWR('list-tables', async () => {
    const response = await api.get('/tables')

    setCountTables(response?.data.body?.filter(e => Boolean(e.available) === false)?.length ?? 0)

    return response.data
  }, { refreshInterval: 20000, refreshWhenHidden: true })

  async function openTable(value) {
    // if (Boolean(value.available) === false) {
    //   setSelectTable({
    //     table: value,
    //   })
    //   return;
    // }

    // if (value.commands.length === 0) {
    //   if (!isAccess('mesas', 'abrir_mesa')) {
    //     return Toast.warning('Você não tem permissão para criar um pedido de mesa')
    //   }

    //   const selectValue = await Toast.selectValue('Qual o tipo de mesa?', '', { command: 'Comanda', simple: 'Sem comanda' })

    //   if (!selectValue) return;

    //   if (selectValue) {
    //     if (selectValue === 'simple') {

    //       const response = await api.post('/commands', {
    //         idTable: value.id, code: value.id
    //       })

    //       let { idCommand, idOrder } = response.data

    //       value.commands = [
    //         ...value.commands,
    //         {
    //           id: idCommand,
    //           idTable: value.id,
    //           code: value.id,
    //           idOrder: idOrder,
    //           status: 'aprovado'
    //         }
    //       ]
    //     }

    //     if (selectValue === 'command') {
    //       await api.put('/tables/' + value.id, {
    //         available: false
    //       })

    //       value.available = false
    //     }

    //     await mutate('list-tables')

    //     setSelectTable({
    //       table: value,
    //     })

    //     return;
    //   }
    // }

    // setSelectTable({
    //   table: value,
    // })
    setSelectTable({
      table: value,
    })
  }

  async function createTable() {
    if (!isAccess('mesas', 'criar_mesa')) {
      return Toast.warning('Você não tem permissão para criar mesa');
    }

    const quantity = await Toast.getValue('Digite a quantidade de mesa do seu estabelecimento', null, null, null, 'number')

    if (!quantity) {
      return
    }

    await api.post('/tables', { quantity })
    await mutate('list-tables')
  }

  async function transferCommand(idCommand, idTableSelect) {
    if (!isAccess('mesas', 'transferir_comanda')) {
      return Toast.warning('Você não tem permissão para transferir comanda de mesa');
    }

    if (!idCommand) {
      return
    }
    document.getElementById('modaltable-close').click()

    const tables = _.orderBy(data.filter(e => e.id !== idTableSelect).map(e => e.name))
    const tableDestianion = await Toast.selectValue('Para qual mesa deseja transferir?', '', tables)
    const tableSelect = data.find(e => e.name === tables[tableDestianion])

    if (!tableSelect || !tableDestianion) {
      return
    }

    await api.get(`/commands/transfer/${idCommand}/${tableSelect.id}`)
    Toast.success("Comanda transferida com sucesso.")
    await mutate('list-tables')
  }

  useEffect(() => {
    if (selectTable?.table) {
      const myModal = new window.bootstrap.Modal(document.getElementById('ModalTable'), {
        keyboard: false
      })

      myModal.show()
    }
  }, [selectTable?.table])

  return (
    <>
      <ModalTable
        payload={selectTable.table}
        transferCommand={transferCommand}
        clear={() => setSelectTable({
          table: null,
          type: null
        })} />

      <div id="container-table" className="container-fluid" style={{ maxHeight: 'calc(100vh - 250px)' }}>
        <div className="mt-3 p-2 bg-dark-blue-1 rounded fw-bold d-flex justify-content-between  align-items-center border mb-3">
          <p className="text-white" style={{ fontSize: '1.3rem' }}>
            <BiTable color='white' size={22} className="mb-1" /> Controle de mesas
          </p>
          {isAccess() !== 'garcom' && (
            <button className="btn btn-sm bg-light btn-circle" onClick={() => createTable()}>
              <i className="fas fa-plus"></i>
            </button>
          )}
        </div>

        <br />

        {isLoading && (
          <>
            <div style={{ width: '100%', height: 500, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </>
        )}

        {!isLoading && (
          <>
            <div className="container-orders-mesa" style={{ display: 'flex', alignItems: 'flex-start', maxHeight: window.screen.width < 1380 ? 350 : 600, gap: 10 }}>
              {(isAccess() !== 'garcom' && orders.filter((order) => order.typeOrder === 'mesa' && !['cancelado', 'finalizado'].includes(order.status)).length > 0) && (
                <div className="list-orders-mesa" style={{ minWidth: 350, maxHeight: window.screen.width < 1380 ? 350 : 600, border: '1px solid #dcdcdc', borderRadius: 5, padding: 10, overflow: 'auto' }}>
                  <input value={filter} onChange={(e) => setFilter(e.target.value)} type="text" placeholder="Digite o número da comanda" className="form-control form-control-sm" />
                  <br />
                  {_.orderBy(orders
                    .map((x) => ({ ...x, isSeen: (dataProductsNotSeen?.groupNumOrder[x.numOrder]?.length ?? 0) > 0 }))
                    .filter(x => filter ? x?.commandCode?.includes(filter) : true), ['isSeen'], 'desc')
                    .filter((order) => order.typeOrder === 'mesa' && !['cancelado', 'finalizado'].includes(order.status)).map((order) => {
                      return (
                        <Card
                          isMesa={true}
                          id={order.id}
                          commandCode={order?.commandCode?.length === 36 ? `Mesa - ${order.nameTable}` : `Comanda - ${order?.commandCode}`}
                          numOrder={order?.numOrder}
                          methodDelivery={order.methodDelivery}
                          key={order.id}
                          name={order.name}
                          pricing={maskCurrency(order.total)}
                          createdAt={order.createdAt}
                          isUpdated={(dataProductsNotSeen?.groupNumOrder[order.numOrder]?.length ?? 0) > 0}
                          selectOrder={() => selectOrder(order.id)}
                          print={async () => await sendPrint('order', {}, order.id)}
                          status={order.status}
                          address={`${order.address}, ${order.number}, ${order.complement}`}
                          responseWebmania={order?.statusNota === 'Emitida' ? order.response : null}
                        />
                      )
                    })}
                </div>
              )}

              <div className="tables-columns">
                {_.orderBy(data?.map(x => { x.name = Number(x.name); return x }) ?? [], 'name', 'asc')?.map(value => {
                  return (
                    <>
                      <div
                        key={value.id}
                        onClick={async () => await openTable(value)}
                        style={{ height: 100, width: 100, display: 'flex', justifyContent: 'center', alignItems: "center" }}
                        className={`pointer card rounded ${value.available ? 'bg-success text-white' : 'bg-danger text-white'}`}
                      >

                        <h3 className="card-title">
                          {value.name}
                        </h3>
                      </div>
                    </>
                  )
                })}
              </div>
            </div>
          </>
        )}
      </div >
    </>
  )
}