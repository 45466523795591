import { NavBar } from "../../components/NavBar";
import { useEffect, useState } from "react";
import { api } from "../../api/api";
import { DateTime } from "luxon";
import { FaSearch, FaStar } from "react-icons/fa";
import { RiUserStarLine } from "react-icons/ri";
import { ModalOrders } from "../../components/Modals/Orders";
import { useDispatch } from "react-redux";
import { SELECT_ORDER } from "../../stores/reducers/OrderReducers";

export function RatingOrdersPage() {
  const dispatch = useDispatch()
  const [ratings, setRatings] = useState([])
  const [ratingOrderAvg, setRatingOrdersAvg] = useState(0)
  const [link, setLink] = useState('')
  const [loadding, setLoadding] = useState(false)
  const [filter, setFilter] = useState({
    startDate: '',
    endDate: '',
    phone: ''
  })

  async function search() {
    setLoadding(true)
    api.get(`/rating-order?startDate=${filter.startDate}&endDate=${filter.endDate}&phone=${filter.phone}`)
      .then(async (res) => {
        const response = await api.get('/companies/generate/link')
        setLink(response.data)
        setRatings(res.data)
      }).finally(() => setLoadding(false))
  }


  async function avgRatingOrders(){
    const response = await api.get(`/rating-order/rating/avg`)

    setRatingOrdersAvg(response.data.avg)
  } 

  function returnStarRender(score = 0) {
    const elements = []
    for (let index = 0; index < score; index++) {
      elements.push(<FaStar color="#FFDE59" />)
    }
    return elements
  }

  async function openOrder(id) {
    const response = await api.get(`/orders/${id}`)

    dispatch(SELECT_ORDER(response.data))
    const myModal = new window.bootstrap.Modal(document.getElementById('modalOrder'), {})
    myModal.show()
  }

  useEffect(() => {
    search()
    avgRatingOrders()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <ModalOrders />
      <NavBar>
        <div className="container-fluid" style={{ height: 'auto' }}>
          <div className="mt-3 p-2 bg-dark-blue-1 rounded fw-bold d-flex justify-content-between  align-items-center border mb-3">
            <p className="text-white" style={{ fontSize: '1.3rem' }}>
              <RiUserStarLine color='white' className="mb-1" size={22} /> Avaliações
            </p>
          </div>

          <div className="mt-3 mb-3">
            <div className="row">
              <div className="col-sm-4 row">
                <div className="col-sm-6">
                  <label htmlFor="">Inicio</label>
                  <input onChange={(e) => setFilter(state => {
                    return {
                      ...state,
                      startDate: DateTime.fromSQL(e.target.value).startOf('day').toSQLDate(),
                    }
                  })} className="form-control form-control-sm" type="date" />
                </div>
                <div className="col-sm-6">
                  <label htmlFor="">Fim</label>
                  <input onChange={(e) => setFilter(state => {
                    return {
                      ...state,
                      endDate: DateTime.fromSQL(e.target.value).endOf('day').toSQLDate()
                    }
                  })} className="form-control form-control-sm" type="date" />
                </div>
              </div>
              <div className="col-sm-3">
                <label htmlFor="">Telefone</label>
                <input type="text" onChange={(e) => setFilter(state => {
                  return {
                    ...state,
                    phone: e.target.value,
                  }
                })} className="form-control form-control-sm" />
              </div>
              <div className="col-sm-2">
                <button className="btn btn-sm btn-dark-blue mt-4" onClick={() => search()}>Pesquisar</button>
              </div>

              <div className="col-sm-3 text-center mt-2 border p-2 rounded">
                <h5>Avaliação média</h5>
                <h6>{returnStarRender(ratingOrderAvg)}</h6>
              </div>
            </div>
          </div>

          <div className="table-responsive">
            <table className="table table-sm">
              <thead>
                <tr>
                  <th>Pedido</th>
                  <th>Data</th>
                  <th>Comentario</th>
                  <th>Avaliação</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {!loadding && (
                  <>
                    {ratings.map((rating, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <a target="_blank" href={`${link}/${rating.idOrder}`} rel="noreferrer" style={{ textDecoration: 'none' }} >Pedido</a>
                          </td>
                          <td>{DateTime.fromISO(rating.createdAt).toFormat('dd/MM/yyyy HH:mm')}</td>
                          <td style={{ width: '50%' }}>{rating.comment}</td>
                          <td>{returnStarRender(rating.score)}</td>
                          <td>
                            <span className='me-2 pointer' onClick={() => openOrder(rating.idOrder)}><FaSearch size={20} /></span>
                          </td>
                        </tr>
                      )
                    })}
                  </>
                )}
              </tbody>
            </table>
          </div>

          {loadding && (
            <div style={{ height: '80vh', display: 'flex', alignItems: 'center', gap: 15, justifyContent: 'center' }}>
              <h4>Carregando informações  </h4>
              <div className="spinner-border mb-2" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>
      </NavBar>
    </>
  )
}


