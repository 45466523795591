import { useEffect, useState } from "react"
import { api } from "../../../api/api"
import { Toast } from "../../../utils/Toast"
import { useSWRConfig } from 'swr'

const DEFAULT_STATE = {
  id: null,
  name: '',
  codePrint: '',
  namePrint: '',
  description: ''
}

export function ModalSector({ sectorSelect }) {
  const { mutate } = useSWRConfig()

  const [state, setState] = useState(DEFAULT_STATE)

  async function save() {
    try {
      const response = await api.post('/sectors', state)
      setState({ ...state, id: response.data.id })
      Toast.success()
      setState(DEFAULT_STATE)
      await mutate('/sectors')
    } catch (error) { }
  }

  async function update() {
    try {
      const response = await api.put('/sectors/' + state.id, state)
      setState({ ...state, id: response.data.id })
      Toast.success()
      setState(DEFAULT_STATE)
      await mutate('/sectors')
    } catch (error) { }
  }

  useEffect(() => {
    if (sectorSelect?.id) {
      setState(sectorSelect)
    } else {
      setState(DEFAULT_STATE)
    }
  }, [sectorSelect])

  return (
    <>
      <div className="modal fade" id="sectorModal" tabIndex="-1" aria-labelledby="sectorModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="sectorModalLabel">Cadastro de setor</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-4">
                  <label htmlFor="">Nome</label>
                  <input type="text" onChange={(e) => setState({ ...state, name: e.target.value })} value={state.name} className="form-control form-control-sm" />
                </div>

                <div className="col-sm-4">
                  <label htmlFor="">Código Impressora</label>
                  <input type="text" onChange={(e) => setState({ ...state, codePrint: e.target.value })} value={state.codePrint} className="form-control form-control-sm" />
                </div>

                <div className="col-sm-4">
                  <label htmlFor="">Nome Impressora</label>
                  <input type="text" onChange={(e) => setState({ ...state, namePrint: e.target.value })} value={state.namePrint} className="form-control form-control-sm" />
                </div>

                <div>
                  <label htmlFor="">Descrição</label>
                  <textarea onChange={(e) => setState({ ...state, description: e.target.value })} value={state.description} className="form-control form-control-sm" cols="10" rows="3"></textarea>
                </div>
              </div>

              <button className="btn btn-sm btn-success btn-sm mt-2" onClick={() => state.id ? update() : save()}>Salvar</button>
              <button className="btn border btn-sm mt-2 ms-2" onClick={() => setState(DEFAULT_STATE)}>Novo</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}