import { useContext } from "react"
import { useEffect } from "react"
import { useState } from "react"
import { api } from "../../../api/api"
import { MenuContext } from "../../../context/MenuContext"
import { Toast } from "../../../utils/Toast"
import { mascaraMoeda, maskCurrency } from "../../../utils/utils"
import { DataSheet } from "../../DataSheet"


export function AdditionalModal() {
  const { categoryAdditionalId, load, additional } = useContext(MenuContext)

  const [state, setState] = useState({
    id: null,
    name: "",
    categoryAdditionalId: 0,
    pricing: 0,
    active: false,
  })

  useEffect(() => {
    setState((oldState) => ({
      ...oldState,
      categoryAdditionalId: categoryAdditionalId
    }))
  }, [categoryAdditionalId])

  useEffect(() => {
    if (additional && additional.id) {
      setState({
        ...additional,
        active: Boolean(additional.active)
      })
    }
  }, [additional])


  function clear() {
    setState({
      id: null,
      name: "",
      categoryAdditionalId: categoryAdditionalId,
      pricing: 0,
      active: false,
    })
  }

  async function created(e) {
    e.preventDefault()

    if (!state.name) {
      return Toast.warning('Campo nome é obrigatório.')
    }

    await api.post("/additional", state)
    Toast.success()
    await load()
    clear()
  }

  async function updated(e) {
    e.preventDefault()

    if (!state.name) {
      return Toast.warning('Campo nome é obrigatório.')
    }

    await api.put("/additional/" + state.id, state)
    Toast.success()
    clear()
    await load()
  }

  return (
    <>
      <div className="modal fade" id="modalAdditional" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Cadastrar adicional</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => clear()}></button>
            </div>
            <div className="modal-body">
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">
                    Adicional
                  </button>
                  <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">
                    Ficha
                  </button>
                </div>
              </nav>
              <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                  <form className="row">
                    <div className="form-group col-sm-6">
                      <label>Nome</label>
                      <input maxLength={40} type="text" value={state.name} onChange={(e) => setState({
                        ...state,
                        name: e.target.value
                      })} className="form-control" id="name" />
                      <small id="nameHelp" className="form-text text-muted">Digite o nome do Produto.</small>
                    </div>
                    <div className="form-group col-sm-6">
                      <label>Preço</label>
                      <input type="text" value={maskCurrency(state.pricing)} onChange={(e) => setState({
                        ...state,
                        pricing: mascaraMoeda(e.target.value)
                      })} className="form-control" id="pricing" />
                    </div>
                    <div className="form-group form-check mt-2" style={{ marginLeft: 10 }}>
                      <input type="checkbox" checked={state.active} onChange={(e) => setState({
                        ...state,
                        active: Boolean(e.target.checked)
                      })} className="form-check-input" id="activeCategory" />
                      <label className="form-check-label" htmlFor="activeCategory">Ativar</label>
                    </div>
                  </form>
                  <div style={{ float: 'right', width: 150 }}>
                    <button type="submit" className="btn btn-sm border shadow-sm" style={{ width: '100%' }} onClick={state.id ? updated : created}>Salvar</button>
                  </div>
                </div>
                <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                  <div className="mt-2">
                    <DataSheet id={state?.id} keyValue={'idAdditional'} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}