import { useContext, useState } from "react"
import { api } from "../../api/api"
import { ModalCard } from "../../components/Modals/Card"
import { NavBar } from "../../components/NavBar"
import { CardContext } from "../../context/CardContext"
import { Toast } from "../../utils/Toast"
import { FaTrashAlt, FaPlus } from 'react-icons/fa'

/* eslint-disable jsx-a11y/anchor-is-valid */
export function CardPage() {
  const { cards, setCards, findAll } = useContext(CardContext)
  const [timer, setTimer] = useState(null)

  async function updated(id, key, valueInput) {
    setCards(cards.map((e) => e.id === id ? { ...e, [key]: valueInput } : { ...e }))

    clearTimeout(timer)

    const newTimer = setTimeout(async () => {
      if (!id || !key) {
        Toast.success()
      }
      await api.put('/cards/' + id, {
        [key]: valueInput
      }).then(() => {
        Toast.success("Informação atualizada")
      }).catch((res) => {
        Toast.warning()
      })
    }, 200)

    setTimer(newTimer)
  }

  async function destroy(id) {
    const confimation = await Toast.confirm()
    if (confimation) {
      await api.delete('/cards/' + id)
      await findAll()
    }
  }

  return (
    <>
      <NavBar>
        <ModalCard />
        <div className="container-fluid col-sm-12">
          <div className="card shadow mt-4">
            <div className="card-header d-flex" style={{ justifyContent: "space-between", alignItems: 'center' }}>
              <span>Pesquisar</span>
              <button type="button" className="btn btn-sm border" data-bs-toggle="modal" data-bs-target="#modalCard">
                <FaPlus />
              </button>
            </div>
            <div className="card-body">
              <table className="table">
                <thead>
                  <tr>
                    <th >#</th>
                    <th >Nome</th>
                    <th >Crédito</th>
                    <th >Debito</th>
                    <th >Voucher</th>
                    <th >Ativo</th>
                    <th >Deletar</th>
                  </tr>
                </thead>
                <tbody>
                  {cards.map((card) => {
                    return (
                      <tr key={card.id}>
                        <th scope="row">
                          <span href={card.id}>#</span>
                        </th>
                        <td>
                          <input className="form-control form-control-sm input-focus"
                            style={{ width: 150, marginRight: 10, textAlign: 'center' }} type="text" aria-label=".form-control-sm example"
                            value={card.name}
                            onChange={(e) => updated(card.id, 'name', e.target.value)}
                          />
                        </td>
                        <td>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultChecked={card.isCredit}
                            onChange={(e) => updated(card.id, 'isCredit', e.target.checked)}
                            role="switch" id="flexSwitchCheckDefault"
                          />
                        </td>
                        <td>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultChecked={card.isDebit}
                            onChange={(e) => updated(card.id, 'isDebit', e.target.checked)}
                            role="switch" id="flexSwitchCheckDefault"
                          />
                        </td>
                        <td>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultChecked={card.isVoucher}
                            onChange={(e) => updated(card.id, 'isVoucher', e.target.checked)}
                            role="switch" id="flexSwitchCheckDefault"
                          />
                        </td>
                        <td>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultChecked={card.active}
                            onChange={(e) => updated(card.id, 'active', e.target.checked)}
                            role="switch" id="flexSwitchCheckDefault"
                          />
                        </td>
                        <td className="pointer" onClick={() => destroy(card.id)} >
                          <FaTrashAlt />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </NavBar>
    </>
  )
}


