import { useContext, useState } from "react"
import { useDispatch } from "react-redux";

import { OrderContext } from "../../../context/OrderContext"
import { Toast } from "../../../utils/Toast"
import { CLEAR_SELECT_ORDER } from "../../../stores/reducers/OrderReducers";
import { GlobalContext } from "../../../context/GlobalContext";
import { sendPrint } from "../../../reporting/print";

export function ModalRefusedOrder() {
  const { isAccess } = useContext(GlobalContext)

  const dispatch = useDispatch()
  const { refusedOrder, orderSelected } = useContext(OrderContext)

  const [motivation, setMotivation] = useState('')
  const [motivationMessage, setMotivationMessage] = useState('')

  async function confirmation() {
    let message = ''

    if (motivationMessage !== '')
      message = motivationMessage
    else
      message = motivation

    await refused(message)
  }

  async function refused(messsage) {
    if (!isAccess('pedidos', 'cancelar_pedido')) {
      return Toast.warning('Você não tem permissão para cancelar pedido');
    }

    if (!messsage || !orderSelected.id) {
      return Toast.warning("Favor selecionar um motivo.")
    }

    await sendPrint('cancel-order', {}, orderSelected.id)

    await refusedOrder(orderSelected.id, 'cancelado', messsage).then(() => {
      setMotivation('')
      setMotivationMessage('')
      document.getElementById('close-with-modal-refused').click()
      document.getElementById('modalOrderClose').click()
    })
  }


  function onChange(value) {
    if (value !== "Outro")
      setMotivationMessage('')

    setMotivation(value)
  }

  return (
    <>
      <div className="modal fade" id="modalRefusedOrder" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modalModalRefusedOrderLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="modalModalRefusedOrderLabel">Qual motivo da recusa do pedido?</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" id="close-with-modal-refused" aria-label="Close" onClick={() => {
                setMotivation('')
                setMotivationMessage('')
                dispatch(CLEAR_SELECT_ORDER())
              }}></button>
            </div>
            <div className="modal-body">
              <div className="form-check">
                <input className="form-check-input" type="radio" checked={motivation === ' O cliente solicitou o cancelamento.'} name="flexRadioDefault" id="flexRadioDefault1" onChange={() => onChange(' O cliente solicitou o cancelamento.')} />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                  O cliente solicitou o cancelamento.
                </label>
              </div>
              <div className="form-check">
                <input className="form-check-input" type="radio" checked={motivation === 'Infelizmente não teremos estoque para atender o seu pedido.'} name="flexRadioDefault" id="flexRadioDefault2" onChange={() => onChange('Infelizmente não teremos estoque para atender o seu pedido.')} />
                <label className="form-check-label" htmlFor="flexRadioDefault2">
                  Infelizmente não teremos estoque para atender o seu pedido.
                </label>
              </div>
              <div className="form-check">
                <input className="form-check-input" type="radio" checked={motivation === 'Desculpe, estamos passando por manutenções internas e não poderemos aceitar o seu pedido no momento.'} name="flexRadioDefault" id="flexRadioDefault3" onChange={() => onChange('Desculpe, estamos passando por manutenções internas e não poderemos aceitar o seu pedido no momento.')} />
                <label className="form-check-label" htmlFor="flexRadioDefault3">
                  Desculpe, estamos passando por manutenções internas e não poderemos aceitar o seu pedido no momento.
                </label>
              </div>
              <div className="form-check">
                <input className="form-check-input" type="radio" checked={motivation === 'Por motivo de força maior, infelizmente não poderemos dar prosseguimento ao seu pedido. Pedimos desculpas pelo transtorno.'} name="flexRadioDefault" id="flexRadioDefault4" onChange={() => onChange('Por motivo de força maior, infelizmente não poderemos dar prosseguimento ao seu pedido. Pedimos desculpas pelo transtorno.')} />
                <label className="form-check-label" htmlFor="flexRadioDefault4">
                  Por motivo de força maior, infelizmente não poderemos dar prosseguimento ao seu pedido. Pedimos desculpas pelo transtorno.
                </label>
              </div>

              <div className="form-check">
                <input className="form-check-input" type="radio" checked={motivation === 'Outro'} name="flexRadioDefault" id="flexRadioDefault5" onChange={() => onChange('Outro')} />
                <label className="form-check-label" htmlFor="flexRadioDefault5">
                  Outro
                </label>
              </div>

              {motivation === 'Outro' &&
                <>
                  <div className="divider mt-2 mb-2"></div>
                  <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Digite o motivo</label>
                    <input type="text" className="form-control" value={motivationMessage} onChange={(e) => setMotivationMessage(e.target.value)} />
                  </div>
                </>
              }

              <br />
              <button className="btn btn-sm btn-dark-blue" onClick={(e) => confirmation()}>Confirmar</button>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}