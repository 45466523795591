import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { ADD_NEW_MOTOBOY, SET_MOTOBOYS, UPDATE_MOTOBOY } from "../stores/reducers/MotoboyReducers";
import MotoboyService from "../services/MotoboyService";

export function useMotoboy() {
  const motoboys = useSelector(state => state.motoboy.motoboys)
  const dispatch = useDispatch()

  const [stateMotoboy, setStateMotoboy] = useState({
    id: null,
    name: "",
    active: false
  })

  async function findAllMotoboys(name = "") {
    const data = await MotoboyService.findAll(name)
    dispatch(SET_MOTOBOYS(data))
  }

  function resetStateMotoboy() {
    setStateMotoboy({
      id: null,
      name: "",
      active: false
    })
  }

  function addNewMotoboy(body) {
    dispatch(ADD_NEW_MOTOBOY(body))
  }

  function updateMotoboy(body) {
    dispatch(UPDATE_MOTOBOY(body))
  }

  useEffect(() => {
    if (motoboys.length === 0) {
      findAllMotoboys()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return { motoboys, stateMotoboy, resetStateMotoboy, addNewMotoboy, updateMotoboy, findAllMotoboys }
}