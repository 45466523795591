import { api } from "../api/api";
import { Toast } from "../utils/Toast";

function CompanyService() {
  const uploadImageBanner = async (base64) => {
    try {
      const formData = new FormData();
      formData.append("file_banner", base64);
      await api.post('/companies/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      Toast.success("Imagem enviada com sucesso.")
    } catch (error) {
      Toast.warning("Ocorreu um erro ao enviar imagem.")
    }
  }

  const uploadLogo = async (base64) => {
    try {
      const formData = new FormData();
      formData.append("logo", base64);
      await api.post('/companies/upload/logo', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      Toast.success("Imagem enviada com sucesso.")
    } catch (error) {
      Toast.warning("Ocorreu um erro ao enviar imagem.")
    }
  }

  return {
    uploadImageBanner,
    uploadLogo
  }
}

export default CompanyService()