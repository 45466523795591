import { useState } from "react"
import { api } from "../../../api/api"
import { Toast } from "../../../utils/Toast"

export function ModalReportNfe() {
  const [state, setState] = useState({
    starDate: '',
    endDate: '',
    status: 'emitidas',
    email: '',
  })

  async function xmlLote() {
    const response = await api.get(`/nfce/report/xml-lote?starDate=${state.starDate}&endDate=${state.endDate}&status=${state.status}&email=${state.email}`)

    document.getElementById("close-modal-report-nfe").click()

    if (response.status === 200) {
      return Toast.success('Gerado com sucesso')
    }

    return Toast.success('Erro ao gerar relatório.')
  }


  return (
    <>
      <div className="modal fade" id="modalReportNFe" tabindex="-1" aria-labelledby="modalReportNFeLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalReportNFeLabel">Relatório fiscal</h5>
              <button type="button" className="btn-close" onClick={()=>{
                  setState({
                    starDate: '',
                    endDate: '',
                    status: 'emitidas',
                    email: '',
                  })
              }} data-bs-dismiss="modal" aria-label="Close" id="close-modal-report-nfe"></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="mb-3 col-sm-12">
                  <label for="exampleFormControlInput1" className="form-label">E-mail</label>
                  <input type="email" value={state.email} onChange={(e) => setState({ ...state, email: e.target.value })} className="form-control form-control-sm" />
                </div>
                <div>
                  <select value={state.status} onChange={(e) => setState({ ...state, status: e.target.value })} className="mb-3 form-select form-select-sm" aria-label=".form-select-sm example">
                    <option value='emitidas'>Emitidas</option>
                    <option value='canceladas'>Canceladas</option>
                  </select>
                </div>
                <div className="mb-3 col-sm-6">
                  <label for="exampleFormControlInput1" className="form-label">Inicio</label>
                  <input value={state.starDate} onChange={(e) => setState({ ...state, starDate: e.target.value })} type="date" className="form-control form-control-sm" />
                </div>
                <div className="mb-3 col-sm-6">
                  <label for="exampleFormControlInput1" className="form-label">Fim</label>
                  <input value={state.endDate} type="date" onChange={(e) => setState({ ...state, endDate: e.target.value })} className="form-control form-control-sm" />
                </div>
                <div>
                  <button className="btn btn-sm btn-success w-100" onClick={() => xmlLote()}>Enviar relatório</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}