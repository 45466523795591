import { CategoryAdditionalModal } from "../../components/Modals/CategoryAddtional";
import { MenuHelper } from "../../components/Modals/Helpers/MenuHelper";
import { AdditionalModal } from "../../components/Modals/Additional";
import { CategoryModal } from "../../components/Modals/Category";
import { ProductModal } from "../../components/Modals/Product";
import { NavBar } from "../../components/NavBar";
import Cardapio from "./components/cardapio";
import { useContext, useEffect, useState } from "react";
import { MenuContext } from "../../context/MenuContext";

export function MenuPage() {
  const { isLocalLoadRef, load } = useContext(MenuContext)
  const [selectCardapio, setSelectCardapio] = useState('menu')

  if (selectCardapio === 'menu') {
    isLocalLoadRef.current = false
  } else if (selectCardapio === 'local') {
    isLocalLoadRef.current = true
  }

  useEffect(() => {
    load()
  }, [selectCardapio])

  return (
    <NavBar>
      <div className="container-fluid mt-4">
        <select onChange={(e) => setSelectCardapio(e.target.value)} class="form-select" aria-label="Default select example">
          <option selected value={'menu'}>Cardápio Delivery</option>
          <option value="local">Cardápio local</option>
        </select>
      </div>
      
      <CategoryModal />
      <ProductModal isLocal={false} isMenu={true} />
      <CategoryAdditionalModal />
      <AdditionalModal />
      <MenuHelper />

      {isLocalLoadRef.current === true ? <Cardapio isLocal={true} /> : <Cardapio isLocal={false} />}
    </NavBar>
  )
}


