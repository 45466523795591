import { api } from "../api/api";
import { Toast } from "../utils/Toast";

export async function createPreference() {
  const response = await api.post('/mercado-pago/create-preference')
  return response.data
}

export async function addPayment(type = null) {
  if (!type) {
    return Toast.warning("Entre em contato com o BMS Pedidos por favor!")
  }

  const { data } = await api.post('/mercado-pago/create-preference', {
    type,
  })
  return data.url
}