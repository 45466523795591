/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react"
import { HiDocumentReport } from "react-icons/hi"
import { useDispatch } from "react-redux"
import { DateTime } from "luxon"

import { api } from "../../api/api"
import { SELECT_ORDER } from "../../stores/reducers/OrderReducers"
import { ModalOrders } from "../../components/Modals/Orders"
import { useMotoboy } from "../../hooks/useMotoboy"
import { ModalRefusedOrder } from "../../components/Modals/RefusedOrder"
import { IoOpenOutline } from "react-icons/io5"

export function OrdersReporting() {
  const [orders, setOrders] = useState([])
  const [filter, setFilter] = useState({})
  const dispatch = useDispatch()
  const { motoboys } = useMotoboy()

  const [loading, setLoading] = useState(null)

  function searchOrders() {
    let params = '?'

    for (const key in filter) {
      if (filter[key])
        params += `${key}=${filter[key]}&`
    }

    api.get(`/orders/search${params}`)
      .then((res) => setOrders(res.data))
      .catch((err) => console.log(err))
      .finally(() => setLoading(null))
  }

  return (
    <>
      <ModalOrders />
      <ModalRefusedOrder />
      <div>
        <div className="mt-3 p-2 bg-dark-blue-1 rounded fw-bold d-flex justify-content-between  align-items-center border mb-3">
          <p className="text-white" style={{ fontSize: '1.3rem' }}>
            <HiDocumentReport color='white' className="mb-1" size={22} />  Listagem de pedidos
          </p>
        </div>

        <div className="mt-4" style={{ width: '100%' }}>
          <div className="row border p-1 pb-3" style={{ margin: '0 auto' }}>
            <p style={{ marginTop: 5 }}>Pesquisa</p>
            <div className="col-sm-3">
              <label htmlFor="">Inicio</label>
              <input type="date" onChange={(e) => setFilter({ ...filter, 'startDate': e.target.value })} className="form-control form-control-sm" />
            </div>
            <div className="col-sm-3">
              <label htmlFor="">Fim</label>
              <input type="date" onChange={(e) => setFilter({ ...filter, 'endDate': e.target.value })} className="form-control form-control-sm" />
            </div>
            <div className="col-sm-2">
              <label htmlFor="">Status</label>
              <select defaultValue={" "} onChange={(e) => {
                if (e.target.value === 'Selecione') {
                  setFilter({ ...filter, 'status': null })
                  return setOrders([])
                }

                if (e.target.value !== null)
                  setFilter({ ...filter, 'status': e.target.value })

              }} className="form-select form-select-sm" aria-label=".form-select-sm example">
                <option key={'selecione'} value={'Selecione'}>Selecione</option>
                {['aguardando', 'aprovado', 'pronto_para_retirada', 'em_entrega', 'finalizado', 'recusado', 'cancelado'].map(e => {
                  return (
                    <option key={e} value={e}>{e.replace(/_/ig, ' ').toUpperCase()}</option>
                  )
                })}
              </select>
            </div>
            <div className="col-sm-2">
              <label htmlFor="">Motoboy</label>
              <select defaultValue={"Selecione"} onChange={(e) => {
                if (e.target.value === 'Selecione') {
                  setFilter({ ...filter, 'motoboy': null })
                  return setOrders([])
                }

                if (e.target.value !== null)
                  setFilter({ ...filter, 'motoboy': e.target.value })

              }} className="form-select form-select-sm" aria-label=".form-select-sm example">
                <option key={'selecione'} value={'Selecione'}>Selecione</option>
                {motoboys.map(e => {
                  return (
                    <option key={e.id} value={e.id}>{e.name}</option>
                  )
                })}
              </select>
            </div>

            <div className="col-sm-2">
              <button className="btn btn-sm btn-dark-blue mt-4" onClick={() => searchOrders()}>Pesquisar</button>
            </div>
          </div>
          <table className="table table-sm" style={{ width: '100%' }}>
            <thead>
              <tr>
                <th >

                </th>
                <th rowSpan={2}>
                  Data
                </th>
                <th rowSpan={2}>
                  Motoboy
                </th>
                <th rowSpan={2}>
                  Status
                </th>
                <th rowSpan={2}>
                  Tipo
                </th>
                <th rowSpan={2}>
                  Ações
                </th>
              </tr>
            </thead>
            <tbody style={{ width: '100%' }}>
              {(loading === null || loading === false) && (
                <>
                  {orders.map((order) => {
                    return (
                      <tr key={order.id}>
                        <td>{order.name}</td>
                        <td>{DateTime.fromISO(order.createdAt).toLocal().toFormat('dd/MM/yyyy HH:mm')}</td>
                        <td>{order?.motoboy?.name ?? 'Sem entregador'}</td>
                        <td className="text-primary" style={{ fontWeight: 'bold' }}>{order?.status?.capitalize() ?? '-'}</td>
                        <td className="text-success">{order?.methodDelivery?.capitalize() ?? (order?.typeOrder?.capitalize() ?? '-')}</td>
                        <td>
                          <button onClick={() => {
                            dispatch(SELECT_ORDER(order))
                            const myModal = new window.bootstrap.Modal(document.getElementById('modalOrder'), {})
                            myModal.show()
                          }} className="btn btn-sm btn btn-sm border " type="button">
                            <IoOpenOutline />
                          </button>
                        </td>
                      </tr>
                    )
                  })}
                </>
              )}
            </tbody>
          </table>
          {loading === true && (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', gap: 15, alignItems: 'center' }}>
              <h5>Carregando informações  </h5>
              <div className="spinner-border mb-2" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}


