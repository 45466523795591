import { useContext, useState } from "react"
import { api } from "../../../api/api"
import { Toast } from "../../../utils/Toast"
import { CardContext } from "../../../context/CardContext"

export function ModalCard() {
  const { findAll } = useContext(CardContext)

  const [state, setState] = useState({
    id: null,
    name: "",
    isCredit: false,
    isDebit: false,
    isVoucher: false,
    active: false
  })

  async function created(e) {
    e.preventDefault()
    const response = await api.post("/cards", state)
    const { id } = response.data
    setState({
      ...state,
      id
    })
    Toast.success()
    await findAll()
    clear()
    return id
  }

  async function updated(e) {
    e.preventDefault()
    const response = await api.put("/cards", state)
    const { id } = response.data
    setState({
      ...state,
      id
    })
    Toast.success()
    await findAll()
    clear()
    return id
  }

  function clear() {
    setState({
      id: null,
      name: "",
      isCredit: false,
      isDebit: false,
      isVoucher: false,
      active: false
    })
  }

  return (

    <>
      <div className="modal fade" id="modalCard" tabIndex="-1" aria-labelledby="modalCardLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="modalCardLabel">Cadastrar</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Nome</label>
                  <input type="text" value={state.name} onChange={(e) => setState({
                    ...state,
                    name: e.target.value
                  })} className="form-control" id="name" aria-describedby="nameHelp" />
                  <small id="nameHelp" className="form-text text-muted">Digite o nome do Cartao</small>
                </div>
                <div className="form-group form-check mt-1">
                  <input type="checkbox" checked={state.isCredit} onChange={(e) => setState({
                    ...state,
                    isCredit: e.target.checked
                  })} className="form-check-input" id="activeMotoboy" />
                  <label className="form-check-label" htmlFor="activeMotoboy">Crédito</label>
                </div>
                <div className="form-group form-check mt-1">
                  <input type="checkbox" checked={state.isDebit} onChange={(e) => setState({
                    ...state,
                    isDebit: e.target.checked
                  })} className="form-check-input" id="activeMotoboy" />
                  <label className="form-check-label" htmlFor="activeMotoboy">Débito</label>
                </div>
                <div className="form-group form-check mt-1">
                  <input type="checkbox" checked={state.isVoucher} onChange={(e) => setState({
                    ...state,
                    isVoucher: e.target.checked
                  })} className="form-check-input" id="activeMotoboy" />
                  <label className="form-check-label" htmlFor="activeMotoboy">Voucher</label>
                </div>
                <div className="divider mt-2 mb-2"></div>
                <div className="form-group form-check mt-1">
                  <input type="checkbox" checked={state.active} onChange={(e) => setState({
                    ...state,
                    active: e.target.checked
                  })} className="form-check-input" id="activeMotoboy" />
                  <label className="form-check-label" htmlFor="activeMotoboy">Ativar</label>
                </div>
                <button type="submit" className="btn btn-sm border mt-2" onClick={state.id ? updated : created}>Salvar</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}