/* eslint-disable jsx-a11y/anchor-is-valid */
import { BsFillInboxesFill } from 'react-icons/bs'
import { NavBar } from '../../components/NavBar'
import useSWR from 'swr'
import { api } from '../../api/api'
import { mascaraMoeda, maskCurrency } from '../../utils/utils'
import { useContext, useEffect, useState } from 'react'
import { Toast } from '../../utils/Toast'
import { TbAlertOctagonFilled } from 'react-icons/tb'
import { GlobalContext } from '../../context/GlobalContext'
import { ReportProdutFiscalOrNotFiscal } from '../../components/report/productsFiscalOrNotFiscal'

const STATE_DEFAULT = {
  name: '',
  pricing: '',
  stock: '',
  type: 'UN'
}

export function StockPage() {
  const { isAccess } = useContext(GlobalContext)

  const { data, isLoading, mutate } = useSWR('list-stocks', async () => {
    const value = await api.get('/stocks')
    return value?.data
  })

  const [state, setState] = useState(STATE_DEFAULT)
  const [reportRefFiscalValue, setReportRefFiscalValue] = useState(null)

  async function onChange(id, key, value) {
    if (!isAccess('estoque', 'update')) {
      Toast.warning('Você não tem acesso para atualizar um estoque')
      return
    }

    const oldValue = [...data]
    try {
      const newData = data.map(e => {
        if (e.id === id) {
          return { ...e, [key]: value }
        }
        return e
      })

      mutate(newData, { revalidate: false })

      if (value !== null && value !== undefined && value !== "") {
        await api.put('/stocks/' + id, {
          [key]: value
        })
      }

    } catch (error) {
      mutate(oldValue, { revalidate: false })
    }
  }

  async function created(body) {
    const confirmation = await Toast.confirm("Caso movimentar este estoque com está unidade, futuramente não poderá ser alterada", "Cancelar", "Confirmar")

    if (!confirmation) {
      return
    }

    const response = await api.post('/stocks', body)
    Toast.success()

    const newData = [...data, {
      id: response.data.id,
      name: body.name,
      pricing: body.pricing,
      stock: body.stock,
      type: body.type || 'UN'
    }]

    mutate(newData, { revalidate: false })
    setState(STATE_DEFAULT)
  }

  // useEffect(() => {
  //   if (reportRefFiscalValue !== null) {
  //     setTimeout(() => {
       
  //       setReportRefFiscalValue(null)
  //     }, 300);
  //   }
  // }, [reportRefFiscalValue])

  return (
    <>
      <ReportProdutFiscalOrNotFiscal refFiscal={reportRefFiscalValue} set={setReportRefFiscalValue} />
      <NavBar>
        <div className="container-fluid">
          <div className="mt-3 p-2 bg-dark-blue-1 rounded fw-bold d-flex justify-content-between  align-items-center border mb-3">
            <p className="text-white" style={{ fontSize: '1.3rem' }}>  <BsFillInboxesFill className="mb-1" color='white' size={22} /> Estoque</p>
          </div>
          <div>
            {isLoading && (
              <div style={{ width: '100%', height: '85vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
            {!isLoading && (
              <>
                {isAccess('estoque', 'create') && (
                  <div className='row'>
                    <div className="col-sm-3">
                      <label htmlFor="">Nome</label>
                      <input type="text" value={state.name} onChange={(e) => setState({ ...state, name: e.target.value })} className='form-control form-control-sm' />
                    </div>
                    <div className="col-sm-2">
                      <label htmlFor="">Preço</label>
                      <input type="text" value={maskCurrency(state.pricing)} onChange={(e) => setState({ ...state, pricing: mascaraMoeda(e.target.value) })} className='form-control form-control-sm' />
                    </div>
                    <div className="col-sm-2">
                      <label htmlFor="">Estoque</label>
                      <input type="number" value={state.stock} onChange={(e) => setState({ ...state, stock: e.target.value })} className='form-control form-control-sm' />
                    </div>
                    <div className="col-sm-2">
                      <label htmlFor="">Tipo</label>
                      <select
                        value={state.type}
                        className="form-select form-select-sm"
                        onChange={(e) => setState({ ...state, type: e.target.value })}
                      >
                        <option value="UN">UN</option>
                        <option value="LT">LT</option>
                        <option value="KG">KG</option>
                      </select>
                    </div>
                    <div className="col-sm-2">
                      <button className='btn btn-sm btn-success mt-4' onClick={() => created(state)}>Adicionar</button>
                    </div>
                    <div className="col-sm-2 mt-2 mb-1">
                      <div class="dropdown">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                          Relatório de estoque
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                          <li><a className="dropdown-item pointer" onClick={() => setReportRefFiscalValue(true)}>Estoque com informação fiscal</a></li>
                          <li><a className="dropdown-item pointer" onClick={() => setReportRefFiscalValue(false)}>Estoque sem informação fiscal</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
                <table className="table table-sm">
                  <thead>
                    <tr>
                      <th className='text-center'>#</th>
                      <th >Nome</th>
                      <th >Preço (ex: R$ 10,00 o KG)</th>
                      <th >Qtd Estoque</th>
                      <th >Tipo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map(value => {
                      return (
                        <tr key={value.id}>
                          <th className='text-center'>
                            {value.stock <= value.stockAlert && <TbAlertOctagonFilled size={20} color='rgb(rgb(105, 0, 0))' />}
                          </th>
                          <th>
                            <input
                              type="text"
                              value={value.name}
                              onChange={(e) => onChange(value.id, 'name', e.target.value)}
                              className='form-control form-control-sm'
                            />
                          </th>
                          <td>
                            <input
                              type="text"
                              value={maskCurrency(value?.pricing ?? 0)}
                              onChange={(e) => onChange(value.id, 'pricing', mascaraMoeda(e.target.value))}
                              className='form-control form-control-sm'
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              onChange={(e) => onChange(value.id, 'stock', e.target.value)}
                              value={value?.stock}
                              className='form-control form-control-sm'
                            />
                          </td>
                          <td>
                            <select
                              value={value.type}
                              className="form-select form-select-sm"
                              aria-label=".form-select-sm example"
                              onChange={(e) => onChange(value.id, 'type', e.target.value)}
                            >
                              <option value="UN">UN</option>
                              <option value="LT">LT</option>
                              <option value="KG">KG</option>
                            </select>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>
      </NavBar>
    </>
  )
}