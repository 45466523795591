import { api } from "../api/api";

export async function SearchCep(cep) {
  const response = await api.get(`https://brasilapi.com.br/api/cep/v2/${cep}`)
  return {
    city: response.data.city,
    uf: response.data.state,
    address: response.data.street,
    district: response.data.neighborhood,
  }
}