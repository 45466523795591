import { api } from "../api/api";
import { Toast } from "../utils/Toast";

function MotoboyService() {
  const findAll = async (name) => {
      const response = await api.get(`/motoboys${name ? `?name=${name}` : ''}`)
      const { data: { data } } = response
      return data
   
  }

  return {
    findAll
  }
}

export default MotoboyService()