export const DEFAULT_IMAGE = 'https://pedidos-images.s3.amazonaws.com/default/image-default.png'

export const LIST_STATUS = [
  { name: 'Aguardando', status: 'aguardando' },
  { name: 'Aprovado', status: 'aprovado' },
  { name: 'Em entrega', status: 'em_entrega' },
  { name: 'Pronto para retirada', status: 'pronto_para_retirada' },
  { name: 'Finalizado', status: 'finalizado' },
  { name: 'Recusado', status: 'recusado' },
  { name: 'Cancelado', status: 'cancelado' },
]

export const LIST_TYPE_COMPANY = [
  "Açaí",
  "Bebidas",
  "Cafeteria",
  "Casa de carnes",
  "Casa de crepes",
  "Casa de frangos",
  "Casa de frutos-do-mar",
  "Casa de lanches",
  "Casa de panqueca",
  "Casa de peixes",
  "Casa de salgados",
  "Casa de sucos",
  "Casa de tapioca",
  "Casa de yakisoba",
  "Comidas congeladas fit",
  "Comidas congeladas",
  "Cozinha africana",
  "Cozinha alemã",
  "Cozinha árabe",
  "Cozinha argentina",
  "Cozinha asiática",
  "Cozinha baiana",
  "Cozinha brasileira",
  "Cozinha chinesa",
  "Cozinha colombiana",
  "Cozinha contemporânea",
  "Cozinha coreana",
  "Cozinha espanhola",
  "Cozinha francesa",
  "Cozinha gaúcha",
  "Cozinha grega",
  "Cozinha indiana",
  "Cozinha italiana",
  "Cozinha japonesa",
  "Cozinha marroquina",
  "Cozinha mediterrânea",
  "Cozinha mexicana",
  "Cozinha mineira",
  "Cozinha nordestina",
  "Cozinha paraense",
  "Cozinha peruana",
  "Cozinha portuguesa",
  "Cozinha rápida",
  "Cozinha saudável",
  "Cozinha tailandesa",
  "Cozinha típica do Norte",
  "Cozinha vegana e vegetariana",
  "Doces e bolos",
  "Hamburgueria",
  "Itens de conveniência",
  "Itens de mercado",
  "Itens para presentes",
  "Marmitaria",
  "Padaria",
  "Pastelaria",
  "Pizzaria",
  "Sopas e caldos",
  "Sorveteria",
  "Outros"
]