import { useContext, useEffect, useState } from "react"
import { api } from "../../../api/api"
import { Toast } from "../../../utils/Toast"
import { useSelector } from "react-redux"
import { MotoboyContext } from "../../../context/MotoboyContext"
import { DateTime } from "luxon"
import ReactInputMask from "react-input-mask"

export function ModalMotoboy({ id }) {
  const { addNewMotoboy, updateMotoboy } = useContext(MotoboyContext)

  const motoboys = useSelector(state => state.motoboy.motoboys)

  const [state, setState] = useState({
    id: null,
    name: "",
    phone: "",
    active: false
  })

  useEffect(() => {
    if (id) {
      setState(motoboys.filter(item => item.id === id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  async function created(e) {
    e.preventDefault()
    const response = await api.post("/motoboys", state)
    const { id } = response.data
    const body = {
      ...state,
      id
    }
    setState(body)
    addNewMotoboy({
      ...body,
      createdAt: DateTime.local().toISO(),
      updatedAt: DateTime.local().toISO(),
    })
    Toast.success()
    return id
  }

  async function updated(e) {
    e.preventDefault()
    const response = await api.put("/motoboys", state)
    const { id } = response.data
    const body = {
      ...state,
      id
    }
    setState(body)
    updateMotoboy(body)
    Toast.success()
    return id
  }

  function clear() {
    setState({
      id: null,
      name: "",
      phone: "",
      active: false
    })
  }

  return (
    <>
      <div className="modal fade" id="modalMotoboy" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="modalMotoboyLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="modalMotoboyLabel">Cadastrar</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => clear()}></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Nome</label>
                  <input type="text" value={state.name} onChange={(e) => setState({
                    ...state,
                    name: e.target.value
                  })} className="form-control" id="name" aria-describedby="nameHelp" />
                  <small id="nameHelp" className="form-text text-muted">Digite o nome do Motoboy</small>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Telefone</label>
                  <ReactInputMask
                    mask="(99) 9 9999-9999"
                    className="form-control form-control-sm"
                    placeholder="Digite seu telefone"
                    value={state.phone}
                    onChange={(e) => setState({
                      ...state,
                      phone: e.target.value
                    })}
                  />
                </div>
                <div className="form-group form-check mt-1">
                  <input type="checkbox" checked={state.active} onChange={(e) => setState({
                    ...state,
                    active: e.target.checked
                  })} className="form-check-input" id="activeMotoboy" />
                  <label className="form-check-label" htmlFor="activeMotoboy">Ativar</label>
                </div>
                <button type="submit" className="btn btn-sm border mt-2" onClick={state.id ? updated : created}>Salvar</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}