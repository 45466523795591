import { FiscalContext } from "../context/FiscalContext";
import { useState } from 'react'

export const FiscalProvider = ({ children }) => {
  const [idOrder, setIdOrder] = useState(null)

  return (
    <FiscalContext.Provider value={{
      idOrder, setIdOrder
    }}>
      {children}
    </FiscalContext.Provider>
  )
}