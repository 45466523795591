
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useRef, useState } from "react";
import { BsQuestionCircleFill } from 'react-icons/bs';
import { AiOutlineClear } from 'react-icons/ai';
import { TbCategory } from 'react-icons/tb';
import { FaRegCopy } from 'react-icons/fa';

import { MenuContext } from "../../../../context/MenuContext";
import { Category } from "../../../../components/Category";
import { Toast } from "../../../../utils/Toast";
import { api } from "../../../../api/api";
import _ from "lodash";

export default function Cardapio({ isLocal = false }) {
  const { data, setData, isDragging, copyAndPaste, setCopyAndPaste, load } = useContext(MenuContext)
  const [timer, setTimer] = useState(null)
  const dragItem = useRef();
  const dragOverItem = useRef();

  async function updated(items) {
    const calculate = items.map((e, index) => {
      return {
        id: e.id,
        name: e.name,
        [isLocal ? 'assortment_local' : 'assortment']: index + 1
      }
    })

    clearTimeout(timer)

    const newTimer = setTimeout(async () => {
      await api.put('/category/categories/assortment', calculate)
        .then(async () => {
          Toast.success("Informação atualizada.")
          await load()
        })
    }, 1000)

    setTimer(newTimer)
  }

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = (e) => {
    const copyListItems = _.orderBy([...data], (isLocal ? 'assortment_local' : 'assortment'), 'asc');
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setData(copyListItems);
    updated(copyListItems)
  };

  useEffect(() => {
    load(isLocal)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="container-fluid" style={{ overflow: 'auto', height: '90vh' }}>
        <div className="mt-3 fw-bold d-flex justify-content-end" role="alert">
          <span className="pointer" data-bs-toggle="modal" data-bs-target="#helperMenu">Ajuda <BsQuestionCircleFill size={25} /> </span>
        </div>
        <div style={{ minWidth: '800px' }}>
          <div className="mt-3 p-2 bg-dark-blue-1 rounded fw-bold d-flex justify-content-between  align-items-center border mb-3">
            <p className="text-white" style={{ fontSize: '1.3rem' }}><TbCategory size={20} /> Criar Nova Categoria</p>
            <button className="btn btn-sm bg-light btn-circle" data-bs-toggle="modal" data-bs-target="#modalCategory">
              <i className="fas fa-plus"></i>
            </button>
          </div>
          {copyAndPaste &&
            <div className="alert border" role="alert" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <strong> <FaRegCopy />  Selecione para qual produto deseja copiar as informações</strong>
              <button className="btn btn-sm border" onClick={() => setCopyAndPaste('')}>
                <AiOutlineClear color="#666" size={20} /> Cancelar Copia
              </button>
            </div>
          }

          <div className="accordion" id="categories">
            {_.orderBy(data, (isLocal ? 'assortment_local' : 'assortment'), 'asc')?.map((item, index) => {
              return (
                <div
                  onDragStart={(e) => (isDragging.name === 'category') ? dragStart(e, index) : null}
                  onDragEnter={(e) => (isDragging.name === 'category') ? dragEnter(e, index) : null}
                  onDragEnd={() => (isDragging.name === 'category') ? drop() : null}
                  draggable={(isDragging.name === 'category') ? true : false}
                  key={item.id}
                >
                  <Category key={item.id} item={item} />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}