import { createSlice } from '@reduxjs/toolkit'


const initialState = {
  orders: [],
  orderSelect: {
    id: null,
    methodDelivery: '',
    name: '',
    cpf: '',
    discount: 0,
    phone: '',
    address: '',
    number: '',
    complement: '',
    referencePoint: '',
    cep: '',
    status: '',
    messageRefused: '',
    moneyChange: 0,
    paymentMethod: '',
    description: '',
    motoboy: {
      id: null,
      name: '',
      active: 1,
      createdAt: '',
      updatedAt: ''
    },
    deliveryDistrict: {
      id: '',
      district: '',
      pricing: 0,
      active: 0,
      createdAt: '',
      updatedAt: ''
    },
    createdAt: '',
    updatedAt: '',
    idCustomer: '',
    cupom: '',
    cupomDiscount: '',
    cupomType: '',
    productsOrders: [],
    payments: [],
    statusNota: '',
    response: null,
    typeOrder: null,
    nameTable: '',
    commandCode: ''
  }
}

export const OrderReducers = createSlice({
  name: 'order',
  initialState: initialState,
  reducers: {
    SET_ORDERS: (state, action) => {
      if (action.payload)
        state.orders = action.payload
    },
    SELECT_ORDER: (state, action) => {
      if (Object.keys(action.payload).length > 0) {
        state.orderSelect = action.payload
      } else {
        state.orderSelect = state.orders.find((item) => item.id === action.payload)
      }
    },
    SELECT_ORDER_OF_EDIT: (state, action) => {
      state.orderSelect = action.payload
    },
    UPDATE_SELECT_ORDER: (state, action) => {
      state.orderSelect = {
        ...state.orderSelect,
        ...action.payload
      }
    },
    CLEAR_SELECT_ORDER: (state) => {
      state.orderSelect = initialState.orderSelect
    },
    ADD_NEW_ORDERS: (state, action) => {
      state.orders.push(action.payload)
    },
    UPDATE_ORDER: (state, action) => {
      state.orders = state.orders.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            ...action.payload,
          }
        }
        return item
      })

      state.orderSelect = state.orders.find((item) => item.id === action.payload.id)
    },
  }
})

export const { SET_ORDERS, ADD_NEW_ORDERS, SELECT_ORDER, CLEAR_SELECT_ORDER, UPDATE_ORDER, UPDATE_SELECT_ORDER, SELECT_ORDER_OF_EDIT } = OrderReducers.actions

export default OrderReducers.reducer