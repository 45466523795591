import Swal from "sweetalert2";
import { toast } from 'react-toastify';
const footer = '<a href="https://api.whatsapp.com/send?phone=553138919414">Precisa de ajuda?</a>'

function _Toast() {
  return {
    success(message = 'Registro salvo.', time = 300) {
      toast.success(message, {
        autoClose: time,
        isLoading: false,
        hideProgressBar: true
      })
    },
    warning(message = 'Ocorreu um erro, entre em contato com o suporte.', time = 1000) {
      toast.warning(message, {
        autoClose: time,
        isLoading: false,
        hideProgressBar: true
      })
    },
    async message(title, html = '') {
      await Swal.fire({
        title,
        html,
      })
    },
    async confirm(title = "Deseja deletar?", denyButtonText = "Não deletar", confirmButtonText = 'Sim deletar', showCloseButton = false) {
      const result = await Swal.fire({
        title,
        showDenyButton: true,
        denyButtonText,
        confirmButtonText,
        footer,
        showCloseButton
      })

      
      return result.isConfirmed
    },
    async acceptedOrNoCancelleationOrder(title = "Deseja deletar?", denyButtonText = "Não deletar", confirmButtonText = 'Sim deletar', showCancelButton = false) {
      const result = await Swal.fire({
        title,
        showDenyButton: true,
        denyButtonText,
        confirmButtonText,
        footer,
        showCancelButton
      })
      return result
    },
    async whenTypeOrder(title, denyButtonText, confirmButtonText) {
      const result = await Swal.fire({
        title,
        showDenyButton: true,
        denyButtonText,
        confirmButtonText,
        footer
      })

      return result.value
    },
    async getValue(title = '', inputLabel = '', textValidator = "O código deve ser digitado.", target = 'body', inputType = 'text', inputValidator) {
      const { value } = await Swal.fire({
        title,
        input: inputType,
        inputLabel,
        inputValue: '',
        showCancelButton: true,
        footer,
        keydownListenerCapture: true,
        target,
        inputValidator: inputValidator ? inputValidator() : (value) => {
          if (!value) {
            return textValidator;
          }
        }
      });

      return value
    },
    async selectValue(title = '', inputPlaceholder = '', inputOptions = {}, defaultValue = '') {
      const { value } = await Swal.fire({
        title,
        input: "select",
        inputOptions,
        inputPlaceholder,
        showCancelButton: true,
        footer,
        inputValue: defaultValue,
        inputValidator: (value) => {
          if (!value) {
            return 'O campo deve ser selecionado'
          }
        }
      });
      return value
    },
    async clear() {
      toast.dismiss()
    }
  }
}

export const Toast = _Toast()