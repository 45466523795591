import { api } from "../api/api";
import { Toast } from "../utils/Toast";

function ProductService() {
  const upload = async (base64, id) => {
    try {
      const formData = new FormData();
      formData.append("file_banner", base64);
      await api.post('/products/upload/' + id, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      Toast.success("Imagem enviada com sucesso.")
    } catch (error) {
      Toast.warning("Ocorreu um erro ao enviar imagem.")
    }
  }

  return { upload }
}

export default ProductService()