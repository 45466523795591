import { createSlice } from '@reduxjs/toolkit'

export const DistrictsReducer = createSlice({
  name: 'configuration',
  initialState: {
    data: []
  },
  reducers: {
    SET_DISTRICTS: (state, action) => {
      state.data = action.payload
    },
  }
})


export const { SET_DISTRICTS } = DistrictsReducer.actions

export default DistrictsReducer.reducer