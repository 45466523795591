import { useContext, useEffect, useState } from "react"
import { InfoFiscaleContext } from "../context"

import { Header } from "../components/UI/Header"

import { Description } from "../components/UI/Description"
import { Icms } from "../components/UI/Icms"
import { Ipi } from "../components/UI/Ipi"
import { Pis } from "../components/UI/Pis"
import { Cofins } from "../components/UI/Cofins"
import { Issqn } from "../components/UI/Issqn"
import { Information } from "../components/UI/Information"

import { ContentInfoFiscal, CardInfoFiscal } from "./styles"
import { Div, AnimateCard, ContentGeneralButtons, ContentButtonsSaveOrClear, Button } from "../../styles"
import { ProductContext } from "../../../context/ProductContext"

export function Taxes() {
  const { listRef } = useContext(ProductContext)
  const { enable, handleSaveOrUpdate, clear, assignValuesToTaxes } = useContext(InfoFiscaleContext)
  const [refSelected, setRefSelected] = useState(-1)

  function findRefById(id) {
    setRefSelected(id)
    if (!id || id === '-1') {
      clear()
      return
    }

    const result = listRef.find(x => x.id === id)

    if (!result) {
      return;
    }

    assignValuesToTaxes(result)
  }

  return (
    <ContentInfoFiscal className="bg-dark card mt-3">
      <div className="col-sm-12">
        <label style={{ color: 'white' }}>REF Fiscal </label>
        <select defaultValue={refSelected} onChange={(e) => findRefById(e.target.value)} className="form-select form-select-sm">
          <option value={-1} >---- Selecione ----</option>
          {listRef.map((value) => {
            return (
              <option value={value.id}>{value.ref} - {value.description}</option>
            )
          })}
        </select>
      </div>
      <Header />

      <AnimateCard>
        <CardInfoFiscal className="row col-sm-12 col-md-12 col-lg-12 col-xl-12 animate">
          <Div hidden={enable.description} className="animate">
            <Description />
          </Div>

          <Div hidden={enable.icms} className="animate">
            <Icms />
          </Div>

          <Div hidden={enable.ipi} className="animate">
            <Ipi />
          </Div>

          <Div hidden={enable.pis} className="animate">
            <Pis />
          </Div>

          <Div hidden={enable.cofins} className="animate">
            <Cofins />
          </Div>

          <Div hidden={enable.issqn} className="animate">
            <Issqn />
          </Div>

          <Div hidden={enable.information} className="animate">
            <Information />
          </Div>
        </CardInfoFiscal>

        <ContentGeneralButtons className='row mt-2 p-2 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
          <ContentButtonsSaveOrClear className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
            <Button type="button" className="btn btn-light btn-sm" onClick={handleSaveOrUpdate}>Salvar</Button>
            <Button type="button" className="btn btn-light btn-sm" onClick={clear}>Limpar</Button>
          </ContentButtonsSaveOrClear>
        </ContentGeneralButtons>
      </AnimateCard >

    </ContentInfoFiscal>
  )
}