import { useEffect, useState } from "react"
import { api } from "../../api/api"
import { NavBar } from "../../components/NavBar"
import { Toast } from "../../utils/Toast"
import { CiMoneyBill } from "react-icons/ci"

const cards = [
  {
    title: 'Cartão de Crédito',
    type: 'isCredit',
    list: ['Elo', 'Visa', 'MasterCard', 'American Express', 'Diners', 'Hipercard', 'Goodcard', 'Verdecard', 'Sorocred', 'Credishop', 'Banricocompras'],
  },
  {
    title: 'Cartão de Débito',
    type: 'isDebit',
    list: ['Visa', 'Elo', 'MasterCard', 'Banricocompras'],
  },
  {
    title: 'Vales',
    type: 'isVoucher',
    list: [
      'Alelo Alimentação', 'Alelo Refeição', 'Ben Visa Vale', 'Cooper Card', 'Credi Refeição', 'Green Card', 'PlanVale',
      'Refeisul', 'Sodexo', 'Sodexo Alimentação', 'Ticked Restaurante', 'Up Brasil', 'VR Alimentação', 'VR Refeição',
      'Vale Card', 'VeroCard', 'VeroCheque', 'VR Smart'
    ],
  },
  {
    title: 'Formas de pagamento aceitas',
    type: 'money-and-pix',
    list: ['Dinheiro', 'PIX', 'Cartão']
  }
]

export function PaymentMethodPage() {
  const [state, setState] = useState({
    id: null,
    isCard: false,
    isMoney: false,
    isPix: false,
    data: {
      name: '',
      typeKey: 'telefone',
      key: ''
    },
  })

  const [listCards, setLisCards] = useState([])

  const [loading, setLoading] = useState(true)

  async function selectCard(name, active, key) {
    await api.post('/cards', {
      name,
      [key]: active,
      active: active
    })
  }

  async function selectPaymentMethod(value, key, toast = true) {
    const data = {
      [key]: value,
    }

    await api.post('/payment-method', data)
    if (toast)
      Toast.success()
  }

  async function find() {
    const response = await api.get('/payment-method')
    if (response?.data?.id) {
      setState({
        ...response.data,
        data: response.data.data,
        isCard: Boolean(response.data.isCard),
        isMoney: Boolean(response.data.isMoney),
        isPix: Boolean(response.data.isPix),
      })
    }
  }

  async function findCards() {
    const response = await api.get('/cards')
    setLisCards(response.data.data)
  }

  useEffect(() => {
    (async () => {
      await find()
      await findCards()
      setLoading(false)
    })()
  }, [])


  return (
    <>
      <NavBar>
        <div className="container-fluid">
          <div className="mt-3 p-2 bg-dark-blue-1 rounded d-flex justify-content-between  align-items-center border mb-3">
            <p className="text-white" style={{ fontSize: '1.3rem' }}> <CiMoneyBill color='white' className='mb-1' size={22} /> Formas de Pagamento</p>
          </div>
          <div>

            {loading === true && (
              <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}

            {loading === false && (
              <>
                {cards.map((card) => {
                  return (
                    <div className="card mt-3 p-2 shadow-sm" style={{ border: '1px solid #dcdcdc' }} key={card.title}>
                      <div className="card-body">
                        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: "center" }}>
                          <div style={{ textAlign: "center" }}>
                            <p style={{ fontSize: '1.2rem' }}>{card.title}</p>
                            <p style={{ fontSize: '.8rem', color: '#666' }}>Selecione as opções disponíveis</p>
                          </div>
                          <div>
                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 20, justifyContent: 'start' }}>
                              {card.list.map((item) => {
                                let checked = false

                                if (card.type === 'money-and-pix') {
                                  if (item === 'Cartão' && state.isCard) {
                                    checked = true
                                  }

                                  if (item === 'PIX' && state.isPix) {
                                    checked = true
                                  }

                                  if (item === 'Dinheiro' && state.isMoney) {
                                    checked = true
                                  }
                                }

                                const data = listCards.find(e => e.name === item)

                                if (card.type === 'isCredit' && data && item === data.name && Boolean(data.isCredit)) {
                                  checked = true
                                }

                                if (card.type === 'isDebit' && data && item === data.name && Boolean(data.isDebit)) {
                                  checked = true
                                }

                                if (card.type === 'isVoucher' && data && item === data.name && Boolean(data.isVoucher)) {
                                  checked = true
                                }

                                return (
                                  <div className="form-check" key={item}>
                                    <input onChange={(e) => {
                                      if (card.type === 'isCredit') {
                                        selectCard(item?.name ?? item, e.target.checked, 'isCredit')

                                        setLisCards(listCards.map((value) => {
                                          if (value.name === (item?.name || item)) {
                                            return { ...value, isCredit: e.target.checked }
                                          }
                                          return value
                                        }))
                                      }

                                      if (card.type === 'isDebit') {
                                        selectCard(item?.name ?? item, e.target.checked, 'isDebit')
                                        setLisCards(listCards.map((value) => {
                                          if (value.name === (item?.name || item)) {
                                            return { ...value, isDebit: e.target.checked }
                                          }
                                          return value
                                        }))
                                      }

                                      if (card.type === 'isVoucher') {
                                        selectCard(item?.name ?? item, e.target.checked, 'isVoucher')
                                        setLisCards(listCards.map((value) => {
                                          if (value.name === (item?.name || item)) {
                                            return { ...value, isVoucher: e.target.checked }
                                          }
                                          return value
                                        }))
                                      }

                                      if (card.type === 'money-and-pix') {
                                        if (item === 'Dinheiro') {
                                          selectPaymentMethod(e.target.checked, 'isMoney')
                                          setState((value) => {
                                            return {
                                              ...value,
                                              isMoney: e.target.checked
                                            }
                                          })
                                        }

                                        if (item === 'PIX') {
                                          selectPaymentMethod(e.target.checked, 'isPix')
                                          setState((value) => {
                                            return {
                                              ...value,
                                              isPix: e.target.checked
                                            }
                                          })
                                        }

                                        if (item === 'Cartão') {
                                          selectPaymentMethod(e.target.checked, 'isCard')
                                          setState((value) => {
                                            return {
                                              ...value,
                                              isCard: e.target.checked
                                            }
                                          })
                                        }
                                      }

                                    }} className="form-check-input" type="checkbox" checked={checked} id={`${item}-${card.type ?? 1}`} />
                                    <label className="form-check-label" style={{ fontSize: '0.9rem' }} htmlFor={`${item}-${card.type ?? 1}`}>
                                      {item?.name ?? item}
                                    </label>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}

                <div className="col-xl-6 col-sm-12 animate mb-5" style={{ display: Boolean(state.isPix) ? 'block' : 'none' }}>
                  <h6 className="text-white p-1 rounded border bg-dark-blue-1 mt-4">Configurações do PIX</h6>
                  <div style={{ display: 'flex', gap: 10, }}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        onChange={(e) => setState({ ...state, data: { ...state.data, typeKey: 'telefone' } })}
                        checked={state.data?.typeKey === 'telefone'}
                        name="flexRadioDefault" id="telefone"
                      />
                      <label className="form-check-label" htmlFor="telefone">
                        Telefone
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="radio"
                        onChange={(e) => setState({ ...state, data: { ...state.data, typeKey: 'email' } })}
                        checked={state.data?.typeKey === 'email'}
                        name="flexRadioDefault" id="email"
                      />
                      <label className="form-check-label" htmlFor="email">
                        E-mail
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input"
                        type="radio"
                        onChange={(e) => setState({ ...state, data: { ...state.data, typeKey: 'cpf' } })}
                        checked={state.data?.typeKey === 'cpf'}
                        name="flexRadioDefault" id="cpf"
                      />
                      <label className="form-check-label" htmlFor="cpf">
                        CPF
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input"
                        type="radio"
                        onChange={(e) => setState({ ...state, data: { ...state.data, typeKey: 'cnpj' } })}
                        checked={state.data?.typeKey === 'cnpj'}
                        name="flexRadioDefault" id="cnpj"
                      />
                      <label className="form-check-label" htmlFor="cnpj">
                        CNPJ
                      </label>
                    </div>
                  </div>
                  <div>
                    <div className="mb-3">
                      <label htmlFor="nome" className="form-label">Nome</label>
                      <input
                        type="text"
                        className="form-control form-control-sm" id="nome"
                        value={state.data?.name}
                        onChange={(e) => setState({ ...state, data: { ...state.data, name: e.target.value } })}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="chave" className="form-label">Chave</label>
                      <input
                        type="text"
                        className="form-control form-control-sm" id="chave"
                        value={state.data?.key}
                        onChange={(e) => setState({ ...state, data: { ...state.data, key: e.target.value } })}
                      />
                    </div>

                    <button className="btn btn-sm btn-dark-blue" onClick={() => selectPaymentMethod(state.data, 'data')}>Salvar informações</button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </NavBar>
    </>
  )
}


