import { DateTime } from "luxon"
import useSWR from 'swr'

import { PaymentMethod } from "../../../utils/PaymentMethods"
import { maskCurrency } from "../../../utils/utils"
import { api } from "../../../api/api"

export function OrdersByCustomers({ id, clear }) {
  const { data, isLoading } = useSWR(`/orders/list/${id}`, async () => {
    const response = await api.get('/orders/list/' + id)
    const { data } = response.data
    return data
  })

  return (
    <div className="modal fade" id="modalOrdersByCustomer" tabIndex="-1" data-bs-backdrop="static" aria-labelledby="modalOrdersByCustomerLabel" aria-hidden="true">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title" id="modalOrdersByCustomer">Historico do cliente - <span className="fw-bold">{data?.length ? data[0]?.phone : ''}</span></h6>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => clear()}></button>
          </div>
          <div className="modal-body">
            <div className="container">
              {isLoading && (
                <div className="d-flex justify-content-center align-items-center">
                  <div className="spinner-border " role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              )}
              {data && (
                <div className="table-responsive" style={{ maxHeight: 250 }}>
                  <table className="table" style={{ fontSize: 15 }}>
                    <thead>
                      <tr>
                        <th >Nº Pedido</th>
                        <th >Data</th>
                        <th >Cliente</th>
                        <th >Status</th>
                        <th >Valor</th>
                        <th >Frete</th>
                        <th >Meio de pagamento</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.map((order) => {
                        return (
                          <tr key={order.id}>
                            <th>{String(order?.numOrder)?.padStart(6, '0')}</th>
                            <td>{DateTime.fromISO(order.createdAt).plus({ hours: -3 }).toFormat("dd/MM/yyyy HH:mm:ss")}</td>
                            <td>{order.name}</td>
                            <td>
                              {String(order.status[0].toUpperCase()) + order.status?.substr(1)}
                            </td>
                            <td>{maskCurrency(order.total)}</td>
                            <td>{maskCurrency(order?.deliveryDistrict?.pricing ?? 0)}</td>
                            <td className="fw-bold">{PaymentMethod[order.paymentMethod]}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}