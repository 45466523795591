import { NavBar } from "../../components/NavBar";
import { useCompany } from "../../hooks/useCompany";
import { DistrictProvider } from "../../providers/DistrictProvider";
import { DeliveryDistrictPage } from "../DeliveryDistrict/index";
import { MapPage } from './components/Maps'
import { MdOutlineDeliveryDining } from "react-icons/md";
import { api } from "../../api/api";
import { Toast } from "../../utils/Toast";
import { useNavigate } from "react-router-dom";

import { SiGooglemaps } from 'react-icons/si'
import { FaAddressCard, FaQuestionCircle } from "react-icons/fa";
import { mascaraMoeda, maskCurrency } from "../../utils/utils";
import { useEffect, useState } from "react";

export function MethodDelivery() {
  const navigate = useNavigate()
  const { configurations, updateConfiguration } = useCompany()

  const [discount, setDiscount] = useState({
    active: false,
    pricingDiscount: 0,
    minPrincingOrder: 0,
    typeDiscount: null
  })

  async function saveDiscoutnDelivery(data) {
    if (
      discount.active === true &&
      (discount.minPrincingOrder === 0 ||
        discount.pricingDiscount === 0 ||
        discount.typeDiscount === null)
    ) {
      return Toast.warning('Todos campos são obrigatórios')
    }

    await api.post('/configurations', {
      discountDelivery: JSON.stringify({
        active: data.active,
        discounts: [data]
      })
    })

    Toast.success('Configurações salva.')
  }

  async function setMethodDeliveryOfConfiguration(methodDelivery) {
    if (methodDelivery === 'district') {
      updateConfiguration({ methodDelivery })
      await api.post('/configurations', { methodDelivery })
        .catch(() => window.location.reload())
    }

    if (methodDelivery === 'maps') {
      updateConfiguration({ methodDelivery })
      await api.post('/configurations', { methodDelivery })
        .catch(() => window.location.reload())
    }

    return Toast.success('Alterado método de entrega!')
  }

  useEffect(() => {
    setDiscount({
      active: configurations?.discountDelivery?.active,
      pricingDiscount: configurations?.discountDelivery?.discounts[0]?.pricingDiscount || 0,
      minPrincingOrder: configurations?.discountDelivery?.discounts[0]?.minPrincingOrder || 0,
      typeDiscount: 'valor_fixo',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configurations.id])

  return (
    <NavBar>

      <div className="container-fluid">
        <div className="mt-3 p-2 bg-dark-blue-1 rounded fw-bold d-flex justify-content-between  align-items-center border mb-3">
          <p className="text-white" style={{ fontSize: '1.3rem' }}>
            <MdOutlineDeliveryDining color='white' size={22} className="mb-1" /> Entrega
          </p>
        </div>

        <div className="card p-2" >
          <div style={{ display: 'flex', alignItems: "center", justifyContent: 'center', gap: 10 }}>
            <div>
              <p style={{ fontSize: '1rem' }}>Desconto em Taxas de Entrega?</p>
            </div>
            <div className="form-check form-switch">
              <input className="form-check-input" checked={discount.active} onChange={async (e) => {
                setDiscount({ ...discount, active: e.target.checked })
                saveDiscoutnDelivery({ ...discount, active: e.target.checked })
              }} type="checkbox" role="switch" id="flexSwitchCheckDefault" />
            </div>
            <div>
              <FaQuestionCircle onClick={() => {
                Toast.message('Dúvidas', 'Com essa funcionalidade, você consegue dar descontos em frentes, com base em valores')
              }} size={20} className="pointer mb-1" />
            </div>

          </div>


          {discount.active && (
            <>
              <table className="table text-center">
                <thead>
                  <tr>
                    <th scope="col">Valor minimo de pedido</th>
                    <th scope="col">Desconto</th>
                    {/* <th scope="col">Tipo</th> */}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input type="text" value={maskCurrency(discount.minPrincingOrder)} onChange={(e) => setDiscount({ ...discount, minPrincingOrder: mascaraMoeda(e.target.value) })} className="form form-control form-control-sm" />
                    </td>
                    <td>
                      <input type="text" value={maskCurrency(discount.pricingDiscount)} onChange={(e) => setDiscount({ ...discount, pricingDiscount: mascaraMoeda(e.target.value) })} className="form form-control form-control-sm" />
                    </td>
                    {/* <td>
                      <select className="form-select form-select-sm" defaultValue={discount.typeDiscount} onChange={(e) => setDiscount({ ...discount, typeDiscount: e.target.value })}>
                        <option selected>Selecione</option>
                        <option value="porcentagem">Porcentagem</option>
                        <option value="valor_fixo">Valor Fixo</option>
                      </select>
                    </td> */}
                  </tr>
                </tbody>
              </table>
            </>
          )}

          <div>
            <button className="btn btn-outline-primary btn-sm" onClick={() => saveDiscoutnDelivery(discount)}>Salvar desconto</button>
          </div>
        </div>

        <br />

        <div className="card p-2">
          <div className="mt-4" style={{ display: 'flex', justifyContent: "center", alignItems: "center", gap: 20 }}>
            <div onClick={() => setMethodDeliveryOfConfiguration('maps')} className={`p-2 border text-center rounded pointer ${configurations.methodDelivery === 'maps' ? 'shadow' : ''}`}
              style={{ display: 'flex', justifyContent: "center", flexDirection: 'column', gap: 7, alignItems: 'center', height: 80, width: '300px', backgroundColor: configurations.methodDelivery === 'maps' ? '#f5f5f5' : '' }}>
              <SiGooglemaps size={30} />
              <div className="form-check pointer">
                <input className="form-check-input pointer" type="radio" name="flexRadioDisabled" id="flexRadioDisabled" checked={configurations.methodDelivery === 'maps'} />
                <label className="form-check-label pointer" htmlFor="flexRadioDisabled">
                  {configurations.methodDelivery === 'maps' ? 'Habilitado' : 'Desabilitado'}
                </label>
              </div>
            </div>

            <div onClick={() => setMethodDeliveryOfConfiguration('district')} className={`p-2 border text-center rounded pointer ${configurations.methodDelivery === 'district' ? 'shadow' : ''}`}
              style={{ display: 'flex', justifyContent: "center", flexDirection: 'column', gap: 7, alignItems: 'center', height: 80, width: '300px', backgroundColor: configurations.methodDelivery === 'district' ? '#f5f5f5' : '' }}>
              <FaAddressCard size={30} />
              <div className="form-check pointer">
                <input className="form-check-input pointer" type="radio" name="flexRadioDisabled" id="flexRadioCheckedDisabled" checked={configurations.methodDelivery === 'district'} />
                <label className="form-check-label pointer" htmlFor="flexRadioCheckedDisabled">
                  {configurations.methodDelivery === 'district' ? 'Habilitado' : 'Desabilitado'}
                </label>
              </div>
            </div>
          </div>

          {configurations.methodDelivery === 'district' && (
            <DistrictProvider>
              <DeliveryDistrictPage />
            </DistrictProvider>
          )}

          {(configurations.methodDelivery === 'maps' && configurations.googleKey) && (
            <MapPage />
          )}

          {!configurations.googleKey && configurations.methodDelivery === 'maps' && (
            <div style={{ height: '65vh', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
              <p className="mt-4 fw-bold" style={{ fontSize: '1.5rem', fontWeight: "bold" }}>Configure as keys do google maps para usar</p>
              <button className="btn btn-sm btn-success mt-3" onClick={() => navigate('/integration')}>Ir para configurações</button>
            </div>
          )}
        </div>
        <br />
        <br />
      </div>
    </NavBar>
  )
}