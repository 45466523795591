import { useState } from "react";

import { ProductContext } from "../context/ProductContext";
import { api } from "../api/api";

export const ProductProvider = ({ children }) => {
  const [productEdit, setProductEdit] = useState({})
  const [listRef, setListRef] = useState([])
  const [listSector, setListSector] = useState([])

  async function findAllRefs() {
    const response = await api.get('/products/list-refs')
    setListRef(response.data)
  }

  async function findAllSectors() {
    const response = await api.get('/sectors/combobox')
    setListSector(response.data)
  }

  return (
    <ProductContext.Provider value={{ productEdit, setProductEdit, listRef, setListRef, findAllRefs, findAllSectors, listSector }}>
      {children}
    </ProductContext.Provider>
  )
}