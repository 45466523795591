import { useContext } from "react"
import { useEffect } from "react"
import { useState } from "react"
import { api } from "../../../api/api"
import { MenuContext } from "../../../context/MenuContext"
import { Toast } from "../../../utils/Toast"


export function CategoryAdditionalModal() {
  const { productId, load, categoryAdditionalEdit } = useContext(MenuContext)

  const [state, setState] = useState({
    id: null,
    name: "",
    productId: productId,
    min: 0,
    max: 0,
    active: false,
  })

  useEffect(() => {
    setState({
      ...state,
      productId: productId
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId])

  useEffect(() => {
    if (categoryAdditionalEdit.id)
      setState({
        id: categoryAdditionalEdit.id,
        name: categoryAdditionalEdit.name,
        productId: categoryAdditionalEdit.productId,
        min: categoryAdditionalEdit.min,
        max: categoryAdditionalEdit.max,
        active: Boolean(categoryAdditionalEdit.active),
      })
  }, [categoryAdditionalEdit])


  async function created(e) {
    e.preventDefault()

    if (!state.name) {
      return Toast.warning('Campo nome é obrigatório.')
    }

    const response = await api.post("/category/additional", state)
    const { id } = response.data
    setState({
      ...state,
      id
    })
    Toast.success()
    await load()
    clear()
    return id
  }

  async function updated(e) {
    e.preventDefault()

    if (!state.name) {
      return Toast.warning('Campo nome é obrigatório.')
    }
    
    const response = await api.put("/category/additional/" + state.id, state)
    const { id } = response.data
    setState({
      ...state,
      id
    })
    Toast.success()
    await load()
    clear()
    return id
  }

  function clear() {
    setState({
      id: null,
      name: "",
      productId: productId,
      min: 0,
      max: 0,
      active: false,
    })
  }

  return (
    <>
      <div className="modal fade" id="modalCategoryAdditional" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Cadastrar Categoria adicional</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => clear()}></button>
            </div>
            <div className="modal-body">
              <form className="row">
                <div className="form-group col-sm-12">
                  <label>Nome</label>
                  <input type="text" value={state.name} onChange={(e) => setState({
                    ...state,
                    name: e.target.value
                  })} className="form-control" id="name" />
                  <small id="nameHelp" className="form-text text-muted">Digite o nome do Produto.</small>
                </div>
                <div className="form-group col-sm-6">
                  <label>Quantidade Minima</label>
                  <input type="text" value={state.min} onChange={(e) => setState({
                    ...state,
                    min: e.target.value
                  })} className="form-control" id="pricing" />
                </div>
                <div className="form-group col-sm-6">
                  <label>Quantidade Máxima</label>
                  <input type="text" value={state.max} onChange={(e) => setState({
                    ...state,
                    max: e.target.value
                  })} className="form-control" id="oldPricing" />
                </div>
                <div className="form-group form-check" style={{ marginLeft: 10 }}>
                  <input type="checkbox" checked={state.active} onChange={(e) => setState({
                    ...state,
                    active: Boolean(e.target.checked)
                  })} className="form-check-input" id="activeCategory" />
                  <label className="form-check-label" htmlFor="activeCategory">Ativar</label>
                </div>
              </form>
              <div style={{ float: 'right', width: 150 }}>
                <button type="submit" className="btn btn-sm border shadow-sm" style={{ width: '100%' }} onClick={state.id ? updated : created}>Salvar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}