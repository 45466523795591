import { useState, useContext } from "react"
import { api } from "../../../api/api"
import { Toast } from "../../../utils/Toast"
import { DistrictContext } from "../../../context/DistrictContext"
import { mascaraMoeda, maskCurrency } from "../../../utils/utils"
import { GlobalContext } from "../../../context/GlobalContext"

export function ModalDeliveryDistrict() {
  const { isAccess } = useContext(GlobalContext)

  const { findAll } = useContext(DistrictContext)
  const [state, setState] = useState({
    id: null,
    district: "",
    pricing: 0,
    active: false
  })

  function clear() {
    setState({
      id: null,
      district: "",
      pricing: 0,
      active: false
    })
  }

  async function created(e) {
    if (!isAccess('metodo_entrega', 'create')) {
      return Toast.warning('Você não tem permissão para criar entrega.')
    }

    e.preventDefault()
    const response = await api.post("/delivery-district", state)
    const { id } = response.data
    setState({
      ...state,
      id
    })
    Toast.success()
    clear()
    await findAll()
    return id
  }

  async function updated(e) {
    if (!isAccess('metodo_entrega', 'update')) {
      return Toast.warning('Você não tem permissão para atualizar entrega.')
    }
    e.preventDefault()
    await api.put("/delivery-district", state)
    Toast.success()
    clear()
    await findAll()
  }

  return (
    <>
      <div className="modal fade" id="modalDeliveryDistrict" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">Cadastrar</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Nome</label>
                  <input type="text"
                    value={state.district} onChange={(e) => setState({
                      ...state,
                      district: e.target.value
                    })}
                    className="form-control"
                    id="name"
                    aria-describedby="nameHelp"
                  />
                  <small id="nameHelp" className="form-text text-muted">Digite o nome do distrito.</small>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Preço</label>
                  <input type="text"
                    value={maskCurrency(state.pricing)} onChange={(e) => setState({
                      ...state,
                      pricing: mascaraMoeda(e.target.value)
                    })}
                    className="form-control"
                    id="name"
                    aria-describedby="nameHelp"
                  />
                </div>
                <div className="form-group form-check">
                  <input type="checkbox" value={state.active} onChange={(e) => setState({
                    ...state,
                    active: e.target.checked
                  })} className="form-check-input" id="activeCategory" />
                  <label className="form-check-label" htmlFor="activeCategory">Ativar</label>
                </div>
                <div style={{ float: 'right' }}>
                  <button type="submit" className="btn btn-sm border" onClick={state.id ? updated : created}>Salvar</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}