import { useContext, useState } from "react"
import _ from "lodash"
import { api } from "../../api/api"
import { DateTime } from "luxon"
import { ModalDeliveryDistrict } from "../../components/Modals/DeliveryDistrict"
import { Toast } from "../../utils/Toast"
import { DistrictContext } from "../../context/DistrictContext"
import { mascaraMoeda, maskCurrency } from "../../utils/utils"
import { FaPlus, FaSearch } from "react-icons/fa"
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai"
import { GlobalContext } from "../../context/GlobalContext"

export function DeliveryDistrictPage() {
  const { isAccess } = useContext(GlobalContext)

  const { findAll, deliveryDistrict, setDeliveryDistrict } = useContext(DistrictContext)
  const [search, setSearch] = useState('')
  const [timer, setTimer] = useState(null)
  const [orderBy, setOrderby] = useState({
    field: 'district',
    order: 'asc'
  })

  async function updated(id, key, valueInput) {
    if (!isAccess('metodo_entrega', 'update')) {
      return Toast.warning('Você não tem permissão para atualizar entrega.')
    }

    setDeliveryDistrict(deliveryDistrict.map((e) => e.id === id ? { ...e, [key]: valueInput } : { ...e }))

    clearTimeout(timer)

    const newTimer = setTimeout(async () => {
      if (!id || !key) {
        Toast.success()
      }
      await api.put('/delivery-district/' + id, {
        [key]: valueInput
      }).then(() => {
        return Toast.success("Registro atualizado com sucesso.")
      }).catch((res) => {
        if (res.response.data.includes('Esse bairro já está cadastrado.')) {
          return Toast.warning('Esse bairro já está cadastrado.')
        }
        Toast.warning()
      })
    }, 800)

    setTimer(newTimer)
  }

  return (
    <>
      <ModalDeliveryDistrict />
      <div className="col-sm-12">
        <div className="mt-4">
          <div className="p-2 rounded d-flex" style={{ justifyContent: "space-between", alignItems: 'center' }}>
            <span className="fw-bold">Pesquisar</span>
            <button type="button" className="btn btn-sm border fw-bold" data-bs-toggle="modal" data-bs-target="#modalDeliveryDistrict">
              <FaPlus />
            </button>
          </div>
          <div className="card-body border-bottom p-1 rounded">
            <div className="row">
              <div className="input-group mb-3">
                <input type="text" className="form-control form-control-sm" value={search} onChange={(e) => setSearch(e.target.value)} />
                <span className="input-group-text text-gray pointer" id="basic-addon2" onClick={() => findAll(search)}>
                  <FaSearch />
                </span>
              </div>

            </div>

            <div className="overflow-auto " style={{ maxHeight: '45vh' }}>
              <table className="table" style={{ fontSize: '1rem', }}>
                <thead>
                  <tr>
                    <th  onClick={() => orderBy.order === 'asc' ? setOrderby({ field: 'district', order: 'desc' }) : setOrderby({ field: 'district', order: 'asc' })}>
                      Nome {orderBy.order === 'asc' ? <AiOutlineArrowDown className="pointer" /> : <AiOutlineArrowUp className="pointer" />}
                    </th>
                    <th  onClick={() => orderBy.order === 'asc' ? setOrderby({ field: 'pricing', order: 'desc' }) : setOrderby({ field: 'pricing', order: 'asc' })}>
                      Preço {orderBy.order === 'asc' ? <AiOutlineArrowDown className="pointer" /> : <AiOutlineArrowUp className="pointer" />}
                    </th>
                    <th >Frete Grátis</th>
                    <th >Ativo</th>
                    <th >Criado</th>
                    <th >Atualizado</th>
                  </tr>
                </thead>
                <tbody>
                  {_.orderBy(deliveryDistrict, (a) => a[orderBy.field], orderBy.order).map((item) => {
                    return (
                      <tr key={item.id}>
                        <td>
                          <input className="form-control form-control-sm input-focus"
                            style={{ width: 150, marginRight: 10, textAlign: 'center' }} type="text" aria-label=".form-control-sm example"
                            value={item.district}
                            onChange={(e) => updated(item.id, 'district', e.target.value)}
                          />
                        </td>
                        <td>
                          <input className="form-control form-control-sm input-focus"
                            style={{ width: 100, marginRight: 10, textAlign: 'center' }} type="text" aria-label=".form-control-sm example"
                            value={maskCurrency(item.pricing)}
                            onChange={(e) => updated(item.id, 'pricing', mascaraMoeda(e.target.value))}
                          />
                        </td>
                        <td>
                          <div className="form-check form-check-sm form-switch" style={{ fontSize: '1rem' }}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={item.free}
                              onChange={(e) => updated(item.id, 'free', e.target.checked)}
                              role="switch" id="flexSwitchCheckDefault"
                            />
                          </div>
                        </td>
                        <td>
                          <div className="form-check form-check-sm form-switch" style={{ fontSize: '1rem' }}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={item.active}
                              onChange={(e) => updated(item.id, 'active', e.target.checked)}
                              role="switch" id="flexSwitchCheckDefault"
                            />
                          </div>
                        </td>
                        <td>{DateTime.fromISO(item.createdAt).toFormat("dd/MM/yyyy HH:mm")}</td>
                        <td>{DateTime.fromISO(item.updatedAt).toFormat("dd/MM/yyyy HH:mm")}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}