import { AiFillPrinter } from "react-icons/ai";
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import { useSWRConfig } from 'swr'
import { maskCurrency } from "../../../../utils/utils";
import { IoIosRemoveCircle } from "react-icons/io";
import { api } from "../../../../api/api";
import { FaCheck } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { UPDATE_SELECT_ORDER } from "../../../../stores/reducers/OrderReducers";
import { sendPrint } from "../../../../reporting/print";

export function ListProducts({ products, removeProduct, removeDisabled }) {
  const dispatch = useDispatch()
  const { mutate } = useSWRConfig()

  const order = useSelector(state => state.order.orderSelect)

  async function setSeen(id) {
    await api.put('/product-order/set-seen-printed', { ids: [id] })

    dispatch(UPDATE_SELECT_ORDER({
      productsOrders: order.productsOrders.map(x => {
        if (x.id === id) {
          return {
            ...x,
            isSeen: true
          }
        }
        return x
      })
    }))

    await mutate('/orders')
  }

  return (
    <>
      {_.orderBy(products, (a) => a.isSeen, 'asc')?.map((product) => {
        let pricingAdditional = 0
        return (
          <div className="mb-2" key={product.id} style={{ borderBottom: '1px solid #dcdcdc' }}>
            <div style={{ backgroundColor: '#dcdcdc', padding: 5, borderRadius: 2, fontWeight: 900, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <p>{product.quantity}x {product.name}</p>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 10 }}>
                <p>{maskCurrency(product.pricing)}</p>
                {!removeDisabled && (
                  <span onClick={() => removeProduct(product.id)} className="pointer">
                    <IoIosRemoveCircle size={16} />
                  </span>
                )}
                <span onClick={async () => await sendPrint('product-order', { product }, order.id)} className="pointer">
                  <AiFillPrinter size={16} />
                </span>
                {product.isSeen ? <FaCheck color="green" /> : <MdClose className="pointer" onClick={async () => await setSeen(product.id)} color="red" />}
              </div>
            </div>
            {product.observation && (
              <div className="bg-warning mt-2 p-1 rounded">
                <p><strong>Observação:</strong> {product?.observation}</p>
              </div>
            )}
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-end' }}>
              {Object.keys(product.additinal).length > 0 && (
                Object.keys(product.additinal).map((key, index) => {
                  return (
                    <>
                      <div key={`${key}-${index}`} style={{ marginTop: 5, marginBottom: 5, width: '80%' }}>
                        <p style={{ fontWeight: 600, textAlign: 'left' }}>{key.replace(/_/ig, ' ')}</p>
                        {product.additinal[key].map((value) => {
                          pricingAdditional += (Number(value.quantity || '0') * Number(value.pricing || '0'))
                          return (
                            <div key={value.id} style={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                              <p>{value.quantity}x {value.name}</p>
                              <p className="fw-bold">{maskCurrency(value.pricing)}</p>
                            </div>
                          )
                        })}
                      </div>
                    </>
                  )
                })
              )}
            </div>
            <div className="mt-2" style={{ color: '#666', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <p>Subtotal</p>
              <p>{maskCurrency(((product.quantity * product.pricing) + pricingAdditional))}</p>
            </div>
          </div>
        )
      })}
    </>
  )
}