import { createSlice } from '@reduxjs/toolkit'

export const INITIAL_STATE = {
  data: [],
  sector: {
    id: null,
    name: '',
    codePrint: '',
    namePrint: '',
    description: ''
  }
}

export const SectorsReducers = createSlice({
  name: 'sectors',
  initialState: INITIAL_STATE,
  reducers: {
    SET_SECTOR: (state, action) => {
      state.sector = action.payload
    },
    SET_SECTORS_DATA: (state, action) => {
      state.data = action.payload
    },
  }
})


export const { SET_SECTOR, SET_SECTORS_DATA } = SectorsReducers.actions

export default SectorsReducers.reducer