import { useEffect, useState } from "react"

export function Carousel() {
  const [list, setList] = useState([
    {
      image: "https://pedidos-images.s3.amazonaws.com/default/cell_and_desktop.png",
      text1: 'Bem vindo',
      text2: 'A melhor plataforma para gestão de pedidos do seu Delivery, com Cardápio Digital!',
      select: true
    },
    {
      image: "https://pedidos-images.s3.amazonaws.com/default/cardapio.png",
      text1: 'Cardápio Digital',
      text2: 'Intuitivo, moderno e simples!',
      text3: 'Seu cliente faz o pedido em poucos cliques!',
      textBottom: 'Rápido e prático como você e seu cliente nunca viram!',
      select: false
    },
    {
      image: "https://pedidos-images.s3.amazonaws.com/default/funcionalidades.png",
      text1: 'Bem vindo',
      text2: 'A melhor plataforma para gestão de pedidos do seu Delivery,com Cardápio Digital!',
      textBottom: '*Integrações disponíveis no plano COMPLETO',
      select: false
    },
  ])

  const [init, setInit] = useState(false)

  const item = list.find(e => e.select === true)

  useEffect(() => {
    setInit(true)
    if (!init)
      setInterval(() => {
        const index = list.findIndex(e => e.select === true)
        const listCopy = [...list]
        listCopy[index].select = false
        if ((index + 1) > listCopy.length - 1) {
          listCopy[0].select = true
        } else {
          listCopy[1 + index].select = true
        }

        setList(listCopy)
      }, 8000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div style={{ transition: '1s linear', margin: '0 auto', color: '#fff', fontFamily: 'Poppins', width: '80%' }}>
      <h1>{item.text1}</h1>
      <br />
      <p style={{ fontSize: '1.5rem' }}>{item.text2}</p>
      <p style={{ fontSize: '1.5rem' }}>{item.text3}</p>
      <img src={item.image} alt="..." />
      <p>{item.textBottom}</p>
    </div>
  )
}