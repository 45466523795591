import { createContext } from "react";
import { UseInfoFiscale } from "../hooks";

const InfoFiscaleContext = createContext({})

function InfoFiscaleProvider({ children }) {
  const {
    handleComponentDisplay, enable, setEnable, infoFiscale, setInfoFiscale, additionalData, handleChangeAdditionalData,
    icms, setIcms, handleChangeIcms, aliquotaMva, handleChangeAliquotaMva, ipi, handleChangeIpi, pis, handleChangePis, cofins,
    handleChangeCofins, issqn, handleChangeIssqn, handleSaveOrUpdate, objectIcms, enableIssqn, setEnableIssqn, refs, findById,
    deleteInfoFiscale, clear, assignValuesToTaxes } = UseInfoFiscale()

  return (
    <InfoFiscaleContext.Provider value={{
      handleComponentDisplay, enable, setEnable, infoFiscale, setInfoFiscale, additionalData, handleChangeAdditionalData,
      icms, setIcms, handleChangeIcms, aliquotaMva, handleChangeAliquotaMva, ipi, handleChangeIpi, pis, handleChangePis, cofins,
      handleChangeCofins, issqn, handleChangeIssqn, assignValuesToTaxes, handleSaveOrUpdate, objectIcms, enableIssqn, setEnableIssqn, refs, findById, deleteInfoFiscale, clear
    }}>
      {children}
    </InfoFiscaleContext.Provider>
  )
}

export { InfoFiscaleProvider, InfoFiscaleContext }