import { useEffect, useState } from 'react'
import { GiHamburgerMenu } from 'react-icons/gi'
import { api } from '../../../api/api'
import { Toast } from '../../../utils/Toast'
import _ from 'lodash'

const translate = {
  "update": "Atualizar",
  "cancelar_pedido": "Cacelar pedido",
  "create_pedido_balcao": "Criar pedido de balcão",
  "view": "Visualizar",
  "create": "Criar",
  "delete": "Deletar",
  "emissao": "Emitir nota",
  "open_safe": "Abrir caixa",
  "close_safe": "Fechar caixa",
  "posicao_estabelecimento": "Selecionar posição",
  'criar_mesa': "Criar mesa",
  'adicionar_comanda': "Adicionar comanda",
  'deletar_comanda': "Remover comanda",
  'transferir_comanda': "Transferir comanda de mesa",
  'abrir_mesa': "Abrir mesa",
  'fechar_mesa': "Fechar mesa",
  'alterar_codigo_comanda': "Alterar código de comanda"
}

const DEFAULT_PERMISSION = [
  {
    title: "Cardápio",
    page: 'cardapio',
    permissions: ['view'],
    selected: []
  },
  {
    title: "Pedidos",
    page: 'pedidos',
    permissions: ['update', 'cancelar_pedido', 'create_pedido_balcao'],
    selected: []
  },
  {
    title: "Produtos",
    page: 'produtos',
    permissions: ['view', 'create', 'update', 'delete'],
    selected: []
  },
  {
    title: "Estoque",
    page: 'estoque',
    permissions: ['view', 'create', 'update'],
    selected: []
  },
  {
    title: "Fiscal",
    page: 'fiscal',
    permissions: ['view', 'emissao', 'cancelar'],
    selected: []
  },
  {
    title: "Clientes",
    page: 'clientes',
    permissions: ['view', 'create', 'update', 'delete'],
    selected: []
  },
  {
    title: "Entregadores",
    page: 'entregadores',
    permissions: ['view', 'create', 'update', 'delete'],
    selected: []
  },
  {
    title: "Cupons",
    page: 'cupons',
    permissions: ['view', 'create', 'update', 'delete'],
    selected: []
  },
  {
    title: "Método de entrega",
    page: 'metodo_entrega',
    permissions: ['view', 'create', 'update', 'delete'],
    selected: []
  },
  {
    title: "Avaliações",
    page: 'avaliacao',
    permissions: ['view'],
    selected: []
  },
  {
    title: "Relatório",
    page: 'relatorio',
    permissions: ['view'],
    selected: []
  },
  {
    title: "Formas de Pagamento",
    page: 'forma_pagamento',
    permissions: ['view'],
    selected: []
  },
  {
    title: "Integrações",
    page: 'integracao',
    permissions: ['view'],
    selected: []
  },
  {
    title: "Caixa",
    page: 'caixa',
    permissions: ['view', 'create', 'open_safe', 'close_safe'],
    selected: []
  },
  {
    title: "Setor",
    page: 'setor',
    permissions: ['view', 'create', 'update'],
    selected: []
  },
  {
    title: "Usuário",
    page: 'user',
    permissions: ['view'],
    selected: []
  },
  {
    title: "Configurações",
    page: 'configuracoes',
    permissions: ['posicao_estabelecimento', 'view'],
    selected: []
  },
  {
    title: "Mesas",
    page: 'mesas',
    permissions: ['criar_mesa', 'adicionar_comanda', 'deletar_comanda', 'transferir_comanda', 'abrir_mesa', 'fechar_mesa', 'alterar_codigo_comanda'],
    selected: []
  },
]

function Item({ title, payload, change }) {
  return (
    <>
      <div style={{ marginBottom: 20, display: 'flex', alignItems: 'center' }} className="border w-100 p-2 rounded mb-2" id={`click-${payload.page}`} data-bs-toggle="collapse" href={`#${payload.page}`} role="button" >
        <GiHamburgerMenu className='me-2' /> <span>{title}</span>
      </div>
      <div className="collapse mb-3" id={payload.page}>
        <div className="card card-body">
          <div style={{ display: 'flex', gap: 10, paddingBottom: 10, justifyContent: 'space-around', maxWidth: '100%', overflow: 'auto' }}>
            {_.orderBy(payload?.permissions, 'asc').map(e => {
              return <span
                key={e}
                className={`pointer badge ${payload?.selected?.includes(e) ? 'bg-primary' : 'bg-secondary'}`}
                onClick={() => {
                  if (payload?.selected?.includes(e)) {
                    payload.selected = payload.selected.filter(select => select !== e)
                    change({ ...payload, selected: payload.selected })
                  } else {
                    change({ ...payload, selected: [...new Set([...payload?.selected ?? [], e])] })
                  }
                }}
              >{translate[e]}</span>
            })}
          </div>
        </div>
      </div>
    </>
  )
}

const DEFAULT_USER_INFO = {
  id: null,
  email: '',
  password: '',
  typeUser: ['admin'],
  status: false
}

export function PermissionsModal({ userId, reload, clearSelectUser }) {
  const [userInfo, setUserInfo] = useState(DEFAULT_USER_INFO)
  const [permissions, setPermissions] = useState(DEFAULT_PERMISSION)

  function change(newValue) {
    setPermissions(state => {
      return state.map(e => {
        if (e.title === newValue.title) {
          return newValue
        }

        return e
      })
    })
  }

  async function saveOrUpdated() {
    let id = userInfo?.id
    if (userInfo.id) {
      await api.put('/users/' + userInfo.id, userInfo)
      Toast.success('Registro atualizado com sucesso')
    } else {
      const response = await api.post('/users', userInfo)
      id = response.data.id
      Toast.success('Registro salva com sucesso')
    }

    const body = permissions.map(value => { return { [value.page]: value.selected } }).reduce((result, item) => {
      const key = Object.keys(item)[0];
      const value = item[key];

      result[key] = _.orderBy(value, 'asc');
      return result;
    }, {});

    await api.post('/users/permissions/user', {
      userId: id,
      ...body
    })

    await reload()
  }

  async function findInfo(id) {
    const response = await api.get('/users/list-info/' + id)
    const userInfoData = response.data.user
    const permissionsData = DEFAULT_PERMISSION?.map((value) => {
      value.selected = response?.data?.permission ? response?.data?.permission[value.page] : null
      return value
    })

    setPermissions(permissionsData)
    setUserInfo({
      email: userInfoData.email,
      status: userInfoData.status,
      password: '',
      typeUser: [userInfoData.typeUser],
      id: userInfoData.id
    })
  }

  useEffect(() => {
    (async () => {
      if (userId) {
        await findInfo(userId)
        new window.bootstrap.Modal(document.getElementById('modalPermissions'), {}).toggle()
      }
    })()
  }, [userId])

  return (
    <div className="modal fade" id="modalPermissions" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="modalPermissionsLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div style={{ position: 'relative', width: '100%' }}>
            <h5 style={{ position: 'absolute', left: 10, top: 10, zIndex: 9999 }}>Lista de permissões</h5>
            <button onClick={() => clearSelectUser()} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ position: 'absolute', right: 10, top: 10, zIndex: 9999 }}></button>
          </div>
          <br />
          <br />
          <div className="modal-body">
            <div className='row'>
              <div className='col-sm-6'>
                <label htmlFor="">E-mail</label>
                <input value={userInfo.email} onChange={(e) => setUserInfo({ ...userInfo, email: e.target.value })} type="text" className='form-control form-control-sm' />
              </div>
              <div className='col-sm-6'>
                <label htmlFor="">Senha</label>
                <input type="text" value={userInfo.password} onChange={(e) => setUserInfo({ ...userInfo, password: e.target.value })} className='form-control form-control-sm' />
              </div>
              <div className='col-sm-4'>
                <label htmlFor="">Tipo de usuário</label>
                <select className="form-select form-select-sm" onChange={(e) => setUserInfo({ ...userInfo, typeUser: [e.target.value] })} value={userInfo.typeUser[0]} aria-label="Default select example">
                  <option value="admin">Administrador</option>
                  <option value="garcom">Garçom</option>
                  <option value="user">Usuário</option>
                </select>
              </div>
              <div className="form-check form-switch col-sm-6 ms-3 mt-4">
                <input className="form-check-input" checked={userInfo.status} onChange={(e) => setUserInfo({ ...userInfo, status: e.target.checked })} type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Usuário {userInfo.status ? 'Ativo' : 'Inativo'}</label>
              </div>
            </div>
            {userInfo.typeUser.includes('user') && (
              <>
                <hr />
                <div style={{ maxHeight: 300, overflow: 'auto' }}>
                  {permissions?.map(value => {
                    return (<Item key={value.title} title={value.title} payload={value} change={change} />)
                  })}
                </div>
                <hr />
              </>
            )}

            <button className='btn btn-success' style={{ float: 'right' }} onClick={() => saveOrUpdated()}>Salvar informações</button>
          </div>
        </div>
      </div>
    </div>
  )
}