
import { BsArrowsFullscreen } from "react-icons/bs"
import useSWR from 'swr'
import _ from 'lodash'

import { ModalSector } from "../../components/Modals/Sector"
import { NavBar } from "../../components/NavBar"
import { api } from "../../api/api"
import './style.css'
import { useContext, useState } from "react"
import { GlobalContext } from "../../context/GlobalContext"

export function SectorPage() {
  const { isAccess } = useContext(GlobalContext)

  const [selectSector, setSelectSelector] = useState(null)

  const { data, isLoading } = useSWR('/sectors', async () => {
    const response = await api.get('/sectors')
    return response.data
  })

  return (
    <>
      <ModalSector sectorSelect={selectSector} />
      <NavBar>
        <div className="container-fluid">
          <div className="mt-3 p-2 bg-dark-blue-1 rounded fw-bold d-flex justify-content-between  align-items-center border mb-3">
            <p className="text-white" style={{ fontSize: '1.3rem' }}>  <BsArrowsFullscreen className="mb-1" color='white' size={22} /> Setores</p>

            {isAccess('sector', 'create') && (
              <button className="btn btn-sm bg-light btn-circle" onClick={() => setSelectSelector(null)} data-bs-toggle="modal" data-bs-target="#sectorModal">
                <i className="fas fa-plus"></i>
              </button>
            )}

          </div>

          {isLoading && (
            <div style={{ width: '100%', height: 600 }}>
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}

          {!isLoading && (
            <>
              <div className="row">
                {_.orderBy(data, (a) => a.name, 'asc').map(sector => {
                  return (
                    <div key={sector.name} className="col-sm-3 col-lg-3 pointer card-sector" onClick={() => {
                      if (isAccess('sector', 'update')) {
                        setSelectSelector(sector)
                        new window.bootstrap.Modal(document.getElementById('sectorModal'), {}).toggle()
                      }
                    }} >
                      <div className="card">
                        <div className="card-body">
                          <div className="alert alert-secondary text-center" style={{ fontWeight: 'bold' }} role="alert">
                            {sector.name}
                          </div>
                          {sector.description && (
                            <>
                              <hr />
                              <p className="card-text text-center" style={{ fontSize: 12 }}>{sector.description}</p>
                              <hr />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </>
          )}
        </div>
      </NavBar>
    </>
  )
}


