import { useNavigate } from "react-router-dom";
import { api } from "../../api/api";
import { useContext, useEffect, useState } from "react";
import { Carousel } from "../../components/Carousel";
import { GlobalContext } from "../../context/GlobalContext";

export function LoginPage() {
  const { setUser, setUserPermissions } = useContext(GlobalContext)

  const navigate = useNavigate();

  const [login, setLogin] = useState({
    email: '',
    password: ''
  })

  function auth() {
    api.post('/users/auth', login)
      .then(res => {
        localStorage.setItem('access_token', res.data.token)
        localStorage.setItem('isModuleNota', res.data.isModuleNota)
        localStorage.setItem('active', res.data.active)
        localStorage.removeItem('ja_mostro')
        localStorage.removeItem('modal-caixa')
        localStorage.setItem('modal-contract', res.data.assinaturaDigital)
        setUser(res.data.user)
        setUserPermissions(res.data.permission)
        localStorage.setItem('user', JSON.stringify(res.data.user))
        navigate("/home");
      })
      .catch(console.error)
  }

  useEffect(() => {
    if (localStorage.getItem('access_token')?.length > 0) {
      window.location.href = '/home'
    } else {
      localStorage.removeItem('access_token')
      localStorage.removeItem('refresh_token')
    }
  }, [])

  return (
    <div style={{ width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'space-around', backgroundColor: '#102f46' }}>
      {window.screen.width >= 1115 && (
        <div style={{ width: window.screen.width - 700, height: '100%', display: 'flex', alignItems: 'center', backgroundColor: '#102f46', position: 'relative' }}>
          <Carousel />
        </div>
      )}
      <div style={{ width: window.screen.width <= 1115 ? '100%' : 700, height: '100%', display: 'flex', alignItems: 'center', backgroundColor: '#fff' }}>
        <div style={{ zIndex: 10, width: '80%', paddingTop: 30, display: 'flex', margin: '0 auto', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <img src="https://pedidos-images.s3.amazonaws.com/BMS%20PEDIDO%20-%20LAYOUT%20SISTEMA.png" width={230} alt="" />
          <h2>Acessar</h2>

          <form style={{ width: '80%' }}>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label" style={{ fontSize: 16 }}>E-mail</label>
              <input type="email" style={{ border: 'none', borderBottom: '1px solid #dcdcdc', borderRadius: 0, outline: 'none' }} onChange={(e) => setLogin(state => ({ ...state, email: e.target.value }))} value={login.email} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputPassword1" className="form-label" style={{ fontSize: 16 }}>Senha</label>
              <input style={{ border: 'none', borderBottom: '1px solid #dcdcdc', borderRadius: 0, outline: 'none' }} onChange={(e) => setLogin(state => ({ ...state, password: e.target.value }))} value={login.password} type="password" className="form-control" id="exampleInputPassword1" />
            </div>
            <button type="button" className="btn btn-lg btn-dark-blue w-100" onClick={(e) => {
              e.preventDefault()
              auth()
            }}>Entrar</button>
            <div className="mt-4">
              <a href={`${process.env.REACT_APP_DIRECTUS}/admin/reset-password`} target="_blank" className='m-0 p-0' rel="noreferrer">Esqueceu a senha?</a>
            </div>
          </form>

          <h3 onClick={() => navigate('/register')} style={{ cursor: 'pointer', color: '#0b273d', fontWeight: 'bold', marginTop: 20, textDecoration: 'underline' }}>VERSÃO TESTE! Clique aqui.</h3>

          <img width={70} style={{ marginTop: 30 }} src="https://pedidos-images.s3.amazonaws.com/default/logo_vertical_bms.png" alt="" />
        </div>
      </div>
    </div >
  )
}