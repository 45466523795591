import { useRef, useState } from "react";
import { MenuContext } from "../context/MenuContext";
import { api } from "../api/api";
import { useDispatch } from "react-redux";
import { SET_CATEGORY_DATA } from "../stores/reducers/CategoryReducers";

export const MenuProvider = ({ children }) => {
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const [copyAndPaste, setCopyAndPaste] = useState('')
  const isLocalLoadRef = useRef(false)

  const [categoryId, setCategoryId] = useState('')
  const [productId, setProductId] = useState('')
  const [categoryAdditionalId, setCategoryAdditionalId] = useState('')
  const [additional, setAdditional] = useState({
    id: null,
    name: "",
    categoryAdditionalId: 0,
    pricing: 0,
    active: false,
  })

  const [categoryEdit, setCategoryEdit] = useState({})
  const [categoryAdditionalEdit, setCategoryAdditionalEdit] = useState({})

  const [isDragging, setIsDragging] = useState({
    name: 'category',
    draggable: true
  })

  async function findAllCategory() {
    const response = await api.get(`/category?isLocal=${isLocalLoadRef.current}`)
    dispatch(SET_CATEGORY_DATA(response.data))
    return response.data
  }

  async function findAllProductsOfCategories() {
    const { data: { data } } = await api.get("/products")
    return data
  }

  async function findAllCategoryAdditional() {
    const { data: { data } } = await api.get("/category/additional")
    return data
  }

  async function findAllAdditional() {
    const { data: { data } } = await api.get("/additional")
    return data
  }

  async function load() {
    const [category, products, categoryAdditional, dataAdditional] = await Promise.all([
      findAllCategory(),
      findAllProductsOfCategories(),
      findAllCategoryAdditional(),
      findAllAdditional()
    ])

    if (isLocalLoadRef.current) {
      const aux = category.filter(e => Boolean(e.isLocal) === true).map((item) => {
        const productsOfCategory = products.filter((x) => x.categoryId === item.id && Boolean(x.isLocal) === true).map((e) => {
          return {
            ...e,
            categoryAdditional: categoryAdditional.filter(cc => cc.productId === e.id).map((z) => {
              return {
                ...z,
                additional: dataAdditional.filter(v => v.categoryAdditionalId === z.id)
              }
            })
          }
        })

        return {
          ...item,
          productsOfCategory
        }
      })

      setData(aux)
    } else {
      const aux = category.filter(x => Boolean(x.isMenu) === true).map((item) => {
        const productsOfCategory = products.filter((x) => x.categoryId === item.id && Boolean(x.isMenu) === true).map((e) => {
          return {
            ...e,
            categoryAdditional: categoryAdditional.filter(cc => cc.productId === e.id).map((z) => {
              return {
                ...z,
                additional: dataAdditional.filter(v => v.categoryAdditionalId === z.id)
              }
            })
          }
        })

        return {
          ...item,
          productsOfCategory
        }
      })

      setData(aux)
    }
  }

  return (
    <MenuContext.Provider value={{
      categoryId,
      setCategoryId,
      productId,
      setProductId,
      categoryAdditionalId,
      setCategoryAdditionalId, data, load, setData, isDragging, setIsDragging,
      additional, setAdditional,
      copyAndPaste, setCopyAndPaste,
      categoryEdit, setCategoryEdit,
      categoryAdditionalEdit, setCategoryAdditionalEdit,
      findAllCategory,
      isLocalLoadRef
    }}>
      {children}
    </MenuContext.Provider>
  )
}