import { IoAlertOutline } from "react-icons/io5";
import { mascaraMoeda, maskCurrency } from "../../../utils/utils";
import { Toast } from "../../../utils/Toast";
import { api } from "../../../api/api";
import { useState } from "react";

export function ModalCloseSafe({ reload }) {
  const [info, setInfo] = useState({
    card: 0,
    money: 0,
    pix: 0
  })

  async function closeSafe() {
    const confirmation = await Toast.confirm("Você confirma, que quer fechar o caixa?", 'Não', 'Sim')
    if (confirmation) {
      await api.post('/safe/close', { info })
      await reload()
      Toast.success()
      document.getElementById('close-modal-safe').click()
    }
  }
  return (
    <div className="modal fade" id="modalCloseSafe" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modalCloseSafeLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="modalCloseSafeLabel">Fechar caixa</h5>
            <button type="button" id="close-modal-safe" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body" >
            <div className="alert alert-warning" role="alert" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <p>FINALIZADORA - Valores declarados:</p> <IoAlertOutline className="mb-1" size={20} />
            </div>
            <div style={{ marginBottom: 10, display: 'flex', flexDirection: "row" }}>
              <p style={{ width: '50%' }}>Dinheiro</p>
              <input
                type="text"
                value={maskCurrency(info.money)}
                onChange={(e) => setInfo({ ...info, money: mascaraMoeda(e.target.value) })}
                style={{ textAlign: 'center' }}
                className="form-control form-control-sm"
              />
            </div>
            <div style={{ marginBottom: 10, display: 'flex', flexDirection: "row" }}>
              <p style={{ width: '50%' }}>Cartão</p>
              <input
                type="text"
                value={maskCurrency(info.card)}
                onChange={(e) => setInfo({ ...info, card: mascaraMoeda(e.target.value) })}
                style={{ textAlign: 'center' }}
                className="form-control form-control-sm"
              />
            </div>
            <div style={{ marginBottom: 10, display: 'flex', flexDirection: "row" }}>
              <p style={{ width: '50%' }}>PIX</p>
              <input
                type="text"
                value={maskCurrency(info.pix)}
                onChange={(e) => setInfo({ ...info, pix: mascaraMoeda(e.target.value) })}
                style={{ textAlign: 'center' }}
                className="form-control form-control-sm"
              />
            </div>

            <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>

            <button className="btn btn-outline-success btn-sm mt-2" onClick={() => closeSafe()}>Fechar caixa</button>
          </div>
        </div>
      </div>
    </div>
  )
}