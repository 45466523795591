import { useContext, useRef, useState } from "react";
import { ProductsOfCategory } from "../ProductsOfCategory";
import { MenuContext } from "../../context/MenuContext";
import { Toast } from "../../utils/Toast";
import { api } from "../../api/api";
import { FaAlignJustify, FaAngleDown } from "react-icons/fa";
import _ from "lodash";

export function Category({ item }) {
  const [loadProducts, setLoadProducts] = useState({ id: null, load: false })

  const { setCategoryId, isLocalLoadRef, setIsDragging, isDragging, data, setData, load, setCategoryEdit } = useContext(MenuContext)
  const [modalOpen, setModalOpen] = useState(false)
  const [timer, setTimer] = useState(null)

  const dragItem = useRef();
  const dragOverItem = useRef();

  async function updated(id, key, valueInput) {
    if (!id || !key) {
      Toast.warning()
    }
    await api.put('/category/' + id, {
      [key]: valueInput
    }).then(() => {
      Toast.success("Informação atualizada.")
    })
  }

  function updateAssortmentProduct(items) {
    const calculate = items.map((e, index) => {
      return {
        id: e.id,
        name: e.name,
        assortment: index + 1
      }
    })

    clearTimeout(timer)

    const newTimer = setTimeout(async () => {
      await api.put('/products/update/assortment', calculate)
        .then(() => {
          Toast.success("Informação atualizada.")
        })
        .catch((res) => Toast.warning())
    }, 1000)

    setTimer(newTimer)
  }

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = (e) => {
    const copyListItems = [...item.productsOfCategory];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;

    const dataSorted = _.orderBy(data, isLocalLoadRef.current === false ? 'assortment' : 'assortment_local', 'asc')
    // eslint-disable-next-line eqeqeq
    const _data = dataSorted.filter((value) => value.id !== item.id)
    const aux = item
    aux.productsOfCategory = copyListItems

    const indexRegister = dataSorted.findIndex((value) => value.id === item.id)
    _data.splice(indexRegister, 0, aux);

    const newData = _data.map((value, index) => {
      return {
        ...value,
        assortment: index + 1,
        productsOfCategory: value.productsOfCategory.map((product, indexProduct) => {
          return {
            ...product,
            assortment: indexProduct + 1,
          }
        })
      }
    })

    updateAssortmentProduct(copyListItems)
    setData(newData)
  };

  function isDraggable() {
    const aux = !modalOpen
    setModalOpen(aux)
    if (aux) {
      setIsDragging({ name: 'product', draggable: true })
    } else {
      setIsDragging({ name: 'category', draggable: true })
    }
  }

  async function destroy(id) {
    const confirmation = await Toast.confirm()
    if (confirmation) {
      await api.delete('/category/' + id)
      await load() // TODO - Colocara para retirar do array que já foi buscado, melhor que recarregar tudo
    }
  }

  const id = item.id.replace(/-/ig, '').replace(/[0-9]/g, '');
  const name = item.name.split(" ").join("")
  const active = Boolean(Number(item.active))
  const styleAccordion = {
    borderLeft: `5px solid ${active ? '#13293D' : '#dcdcdc  '}`,
    borderRadius: 5,
    marginBottom: 5,
  }

  function editCategory(body) {
    setCategoryEdit(body)
  }

  return (
    <div className="accordion-item" style={{ ...styleAccordion }}>
      <h2 className="accordion-header" style={{ width: '100%' }} id={`${id}-${name}`}>
        <button onClick={() => {
          isDraggable()
          if (loadProducts.id === id)
            setLoadProducts(state => {
              return { id: state.id, load: !state.load }
            })
          else
            setLoadProducts(state => {
              return { id: loadProducts.id, load: true }
            })
        }} style={{ display: 'flex', alignItems: 'center', gap: 10, fontSize: '1rem' }} className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#${id}`} aria-expanded="true" aria-controls={id}>
          <FaAlignJustify color="#666" size="1rem" />
          {item.name}
        </button>
        <div className="d-flex" style={{ justifyContent: 'flex-end', alignItems: 'center' }}>
          <div className="form-check form-check-sm form-switch" style={{ fontSize: '1rem' }}>
            <input className="form-check-input" type="checkbox" defaultChecked={active} onChange={(e) => updated(item.id, 'active', e.target.checked)} role="switch" id="flexSwitchCheckDefault" />
          </div>

          <div className="dropdown">
            <button className="btn btn-sm border shadow-sm" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="true">
              <FaAngleDown style={{ fontSize: '1rem' }} />
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li className="dropdown-item" onClick={() => setCategoryId(item.id)} data-bs-toggle="modal" data-bs-target="#modalProduct">Adicionar</li>
              <li className="dropdown-item" data-bs-toggle="modal" data-bs-target="#modalCategory" onClick={() => editCategory(item)}>Editar</li>
              <li className="dropdown-item" onClick={() => destroy(item.id)}>Deletar</li>
            </ul>
          </div>
        </div>
      </h2>
      <div id={id} className='accordion-collapse collapse' aria-labelledby={`${id}-${name}`} data-bs-parent="#categories">
        <div className="accordion-body">
          <div className="accordion" id="accordionProducts">
            {loadProducts.load && (
              <>
                {item.productsOfCategory.map((value, index) => {
                  return (
                    <div
                      onDragStart={(e) => (isDragging.name === 'product') ? dragStart(e, index) : null}
                      onDragEnter={(e) => (isDragging.name === 'product') ? dragEnter(e, index) : null}
                      onDragEnd={() => (isDragging.name === 'product') ? drop() : null}
                      draggable={(isDragging.name === 'product') ? true : false}
                      key={value.id}
                    >
                      <ProductsOfCategory item={value} />
                    </div>
                  )
                })}
              </>
            )}

          </div>
        </div>
      </div>
    </div>
  )
}