import { TbInfoCircle } from 'react-icons/tb'
import { BiTask } from 'react-icons/bi'



export function MenuHelper() {
  return (
    <div className="modal fade" id="helperMenu" tabIndex="-1" aria-labelledby="helperMenuLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="helperMenuLabel">Ajuda</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="alert" role="alert">
              <span className="fw-bold"> <BiTask size={25} /> Passos: <br /></span>
              <hr />
              <p className="fw-bold">
                1 - Crie a categoria do seu produto (ex: Bebidas) <br />
                2 - Adicione produtos a essa categoria (ex: Refrigerante) <br />
                3 - Adicione uma sub categoria a esse produto (ex: Refrigerantes sem açucar) <br />
                4 - Adicione as opções para essa sub categoria (ex: Coca-Cola, Guaraná Antarctica) <br />
              </p>
              <hr />
              <span> <TbInfoCircle size={25} /> Após todos cadastro o produto caso esteja <b>Ativo</b> será disponibilizado online para seus clientes</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}