import { api } from "../api/api";
import { DistrictContext } from "../context/DistrictContext";
import { useState, useEffect } from 'react'

export const DistrictProvider = ({ children }) => {
  const [deliveryDistrict, setDeliveryDistrict] = useState([])

  useEffect(() => {
    findAll()
  }, [])

  async function findAll(district) {
    const response = await api.get(`/delivery-district${district ? `?district=${district}` : ''}`)
    const { data } = response
    setDeliveryDistrict(data)
  }


  return (
    <DistrictContext.Provider value={{
      deliveryDistrict, setDeliveryDistrict, findAll
    }}>
      {children}
    </DistrictContext.Provider>
  )
}