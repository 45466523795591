import { useEffect, useState } from "react"
import { api } from "../../../api/api"
import { DateTime } from "luxon"
import { maskCurrency, numOrderFormat } from "../../../utils/utils"
import { LIST_STATUS } from "../../../utils/constantes"

export function OrdersWithMotoboyModal({ id, set }) {
  const [orders, setOrders] = useState([])
  const [dates, setDates] = useState({
    startDate: DateTime.utc().startOf('month').toSQLDate(),
    endDate: DateTime.utc().endOf('month').toSQLDate()
  })

  async function findOrders(idMotoboy, startDate, endDate) {
    let url = `/orders/reporting/${idMotoboy}/orders`

    if (startDate && endDate) {
      url += `?startDate=${DateTime.fromSQL(startDate).toSQL()}&endDate=${DateTime.fromSQL(endDate).toSQL()}`
    }

    const response = await api.get(url)
    setOrders(response.data)
  }

  useEffect(() => {
    if (id)
      findOrders(id, dates.startDate, dates.endDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const { calculatePricingCostFrete, calculatePricingFretePaymentWithCustomer } = orders
    .reduce((prev, current) => {
      const aux = current?.deliveryDistrict

      if (!aux) {
        return prev
      }

      if (aux?.free) {
        return {
          ...prev,
          calculatePricingCostFrete: prev.calculatePricingCostFrete + (aux.pricing ?? 0)
        }
      }

      return {
        ...prev,
        calculatePricingFretePaymentWithCustomer: prev.calculatePricingFretePaymentWithCustomer + (aux.pricing ?? 0)
      }
    }, {
      calculatePricingCostFrete: 0,
      calculatePricingFretePaymentWithCustomer: 0
    })

  return (
    <div className="modal fade" id="modal-orders-motoboy" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="modal-orders-motoboyLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel" style={{ fontSize: '1rem' }}>Lista de pedidos entregues mês</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => set(null)}></button>
          </div>
          <div className="modal-body">
            <div className="row mb-2">
              <div className="col-sm-4">
                <label htmlFor="">Data inicial</label>
                <input type="date" value={dates.startDate} onChange={(e) => setDates({ ...dates, startDate: e.target.value })} className="form-control form-control-sm" />
              </div>
              <div className="col-sm-4">
                <label htmlFor="">Data Final</label>
                <input type="date" value={dates.endDate} onChange={(e) => setDates({ ...dates, endDate: e.target.value })} className="form-control form-control-sm" />
              </div>
              <div className="col-sm-4">
                <button className="btn btn-sm btn btn-sm border mt-4" onClick={() => findOrders(id, dates.startDate, dates.endDate)}>Pesquisar</button>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-sm" style={{ fontSize: '1rem' }}>
                <caption className="text-gray text-end p-2 mt-4 fs-6 border rounded">
                  <p>Valor de frete pago pelo cliente: {maskCurrency(calculatePricingFretePaymentWithCustomer)}</p>
                  <p>Custo real de frete: {maskCurrency(calculatePricingCostFrete)}</p>
                </caption>
                <thead>
                  <tr>
                    <th >Nº Pedido</th>
                    <th >Cliente</th>
                    <th >Status</th>
                    <th >Preço entrega</th>
                    <th >Data</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order) => {
                    return (
                      <tr key={order.id}>
                        <th>{numOrderFormat(order?.numOrder)}</th>
                        <th>{order.name}</th>
                        <td>{LIST_STATUS.find(e => e.status === order.status).name}</td>
                        <td>
                          {(order?.deliveryDistrict?.free ?? null) && 'Grátis'}
                          {!(order?.deliveryDistrict?.free ?? null) && maskCurrency(order?.deliveryDistrict?.pricing ?? 0)}
                        </td>
                        <td>{DateTime.fromISO(order.createdAt).toLocal().toFormat("dd/MM/yyyy HH:mm")}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}