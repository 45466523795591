import { NavBar } from "../../components/NavBar"
import { HiOutlineDocumentReport } from "react-icons/hi"
import _ from 'lodash'

import './style.css'
import { useEffect, useState } from "react"
import { api } from "../../api/api"
import { maskCurrency } from "../../utils/utils"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';

import { Bar, Doughnut } from 'react-chartjs-2';
import { DateTime } from "luxon"
import { OrdersReporting } from "../OrdersReporting"
import { FaArrowAltCircleLeft, FaArrowAltCircleRight, FaTrashAlt } from "react-icons/fa"
import { Toast } from "../../utils/Toast"

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement, Tooltip, Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
  },
};

export const optionsHorizontal = {
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'right',
    },
    title: {
      display: true,
      text: 'Tipos de recebimentos',
    },
  },
};

const labels = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

export function ReportPage() {
  const [dates, setDates] = useState({
    startDate: DateTime.local().startOf('month').toSQLDate(),
    endDate: DateTime.local().endOf('month').toSQLDate(),
  })

  const [countOrder, setCountOrder] = useState(0)
  const [sumTicket, setSumTicket] = useState(0)
  const [countCustomer, setCountCustomer] = useState(0)
  const [sumProducts, setSumProducts] = useState(0)
  const [metrics, setMetrics] = useState({
    countMetrics: 0,
    dataCustomers: 0,
    abandonedCarts: 0,
    listCustomers: [],
    abandonedProducts: [],
    pricingCartsAbandoned: 0
  })

  const [tipoRendimentosEntrega, setTipoRendimentosEntrega] = useState({
    labels: [labels[new Date().getMonth()]],
    datasets: [],
  })

  const [tipoRendimentosRetirada, setTipoRendimentosRetirada] = useState({
    labels: [labels[new Date().getMonth()]],
    datasets: [],
  })

  const [loadding, setloadding] = useState(true)

  const [reportingChart, setReportingChart] = useState({
    labels,
    datasets: [
      {
        label: 'Produtos vendidos por mês',
        data: [],
      }
    ],
  })

  const [reportingChartRendiments, setReportingChartRendiments] = useState({
    labels,
    datasets: [
      {
        label: 'Rendimentos por mês',
        data: [],
      }
    ],
  })

  const [reportingChartQuantityMethodDelivery, setReportingChartQuantityMethodDelivery] = useState({
    labels: ['Entrega', 'Retirada'],
    datasets: [
      {
        data: [],
      }
    ],
  })

  useEffect(() => {
    async function findAllReports() {
      try {
        const queryParams = `?startDate=${dates.startDate}&endDate=${dates.endDate}`

        const auxOrdersReportingResponse = await api.get(`/orders/reporting` + queryParams)
        const auxReportingChartQuantityMethodDelivery = auxOrdersReportingResponse.data?.reportingWithMethodQuantity?.sort((a, b) => a.methodDelivery - b.methodDelivery)
        const auxReportingChartSumMethodDelivery = auxOrdersReportingResponse.data?.reporting?.sort((a, b) => a.methodDelivery - b.methodDelivery)

        setCountOrder(auxOrdersReportingResponse.data?.reportQuantityOrders || 0)

        setReportingChartQuantityMethodDelivery({
          labels: [`Retirada nº (${auxReportingChartQuantityMethodDelivery[1]?.quantiy ?? 0})`, `Entrega nº (${auxReportingChartQuantityMethodDelivery[0]?.quantiy ?? 0})`],
          datasets: [
            {
              data: [auxReportingChartQuantityMethodDelivery[1]?.quantiy ?? 0, auxReportingChartQuantityMethodDelivery[0]?.quantiy ?? 0],
              backgroundColor: [
                '#757575',
                '#34aadc',
              ]
            },

          ],
        })

        const labelsCreated = () => {
          const list = []
          while (list.length < 12) {
            list.push(DateTime.local().set({ month: list.length + 1 }).monthLong)
          }

          return list.map(x => x.charAt(0).toUpperCase() + x.substring(1))
        }

        setReportingChart({
          labels: labelsCreated(),
          datasets: [
            {
              label: 'Produtos vendidos por mês',
              data: getDatasReporting(auxOrdersReportingResponse.data?.reportingCountOrdersWithMoth?.sort((a, b) => a.moth - b.moth)),
              backgroundColor: '#233059',
            }
          ],
        })

        setReportingChartRendiments({
          labels: labelsCreated(),
          datasets: [
            {
              label: 'Rendimentos por mês',
              data: getDatasReportingSum(auxOrdersReportingResponse.data?.reportingSumOrdersWithMoth?.sort((a, b) => a.moth - b.moth)),
              backgroundColor: '#233059',
            }
          ],
        })

        setTipoRendimentosEntrega({
          labels: [labels[new Date().getMonth()]],
          datasets: [
            {
              label: 'Cartão',
              data: [auxReportingChartSumMethodDelivery[0]?.totalCard ?? 0],
              backgroundColor: '#233059',
            },
            {
              label: 'PIX',
              data: [auxReportingChartSumMethodDelivery[0]?.totalPix ?? 0],
              backgroundColor: '#233059',
            },
            {
              label: 'Dinheiro',
              data: [auxReportingChartSumMethodDelivery[0]?.totalMoney ?? 0],
              backgroundColor: '#233059',
            },
          ],
        })

        setTipoRendimentosRetirada({
          labels: [labels[new Date().getMonth()]],
          datasets: [
            {
              label: 'Cartão',
              data: [auxReportingChartSumMethodDelivery[1]?.totalCard ?? 0],
              backgroundColor: '#233059',

            },
            {
              label: 'PIX',
              data: [auxReportingChartSumMethodDelivery[1]?.totalPix ?? 0],
              backgroundColor: '#233059',
            },
            {
              label: 'Dinheiro',
              data: [auxReportingChartSumMethodDelivery[1]?.totalMoney ?? 0],
              backgroundColor: '#233059',
            },
          ],
        })

        const moth = DateTime.local().get('month')
        const getSumMoth = auxOrdersReportingResponse.data?.reportingSumOrdersWithMoth?.find((value) => value.moth === moth)
        setSumProducts(getSumMoth?.total ?? 0)

        const customerCountResponse = await api.get('/customers/count' + queryParams)
        setCountCustomer(customerCountResponse?.data?.quantity || 0)

        setSumTicket(auxOrdersReportingResponse.data?.reportingSumOrdersWithMoth.find((value) => value.moth === moth)?.count ?? 0)

        await findAllBettweenDates()
      } catch (error) {
        console.log(error)
      } finally {
        setloadding(false)
      }
    }

    findAllReports()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates.startDate])

  function getDatasReporting(datavalue) {
    try {
      const first = (datavalue[0].moth - 1)
      let aux = []
      while (true) {

        if (aux.length === first) {
          break
        }
        aux.push({ quantity: 0 })
      }

      return [...aux, ...datavalue].map((value) => value.quantiy)
    } catch (error) {
      return []
    }
  }

  function getDatasReportingSum(datavalue) {
    try {
      const first = (datavalue[0].moth - 1)
      let aux = []
      while (true) {

        if (aux.length === first) {
          break
        }
        aux.push({ quantity: 0 })
      }


      return [...aux, ...datavalue].map((value) => value.total)
    } catch (error) {
      return []
    }
  }

  async function findAllBettweenDates() {
    const queryParams = `?startDate=${dates.startDate}&endDate=${dates.endDate}`

    const { data } = await api.get('/metrics' + queryParams)

    const countMetrics = data.filter(e => e.type === 'count')?.reduce((prev, current) => prev + current?.count ?? 0, 0) ?? 0
    const dataMetrics = data.filter(e => e.type === 'metric')

    const abandonedCarts = dataMetrics.length
    const listCustomers = []
    let pricingCartsAbandoned = 0

    const sum = (listProd) => {
      const data = listProd.map((item, index) => {
        const keys = Object.keys(item?.additinal).map((e) => e.split('_').join(' '))
        const sumAdditioanl = keys.reduce((prev, current) => {
          return prev + parseFloat(item.additinal[current.replace(/ /ig, '_')]?.map(v => v?.pricing ?? 0).reduce((p, c) => p + parseFloat(c), 0)) ?? 0
        }, 0)
        const preco = (parseFloat(item.pricing) + sumAdditioanl) * item.quantity
        return preco
      })

      const _pricingTotalProducts = data.reduce((prev, current) => prev + current, 0) ?? 0
      return Number(_pricingTotalProducts.toFixed(2))
    }

    const dataCustomers = dataMetrics.filter(e => {
      pricingCartsAbandoned += sum(e.data.products)

      if (!!(e.data.infoClient.phone)) {
        listCustomers.push({
          id: e.id,
          name: e?.data?.infoClient?.name,
          phone: e?.data?.infoClient?.phone
        })

        return !!(e.data.infoClient.phone)
      }

      return false
    }).length

    const auxMetrics = dataMetrics.map(e => e.data)
    const allProducts = _.flatMap(auxMetrics, 'products');
    const productCounts = {};
    _.forEach(allProducts, product => {
      if (productCounts[product.nameProduct]) {
        productCounts[product.nameProduct] += product.quantity;
      } else {
        productCounts[product.nameProduct] = product.quantity;
      }
    });

    const sortedProducts = _.orderBy(_.keys(productCounts), product => productCounts[product], 'desc');
    const top10Products = _.take(sortedProducts, 10);

    setMetrics({
      ...metrics,
      countMetrics,
      abandonedCarts,
      dataCustomers,
      listCustomers,
      abandonedProducts: top10Products,
      pricingCartsAbandoned
    })
  }

  async function deleteMetric(id) {
    const confirmation = await Toast.confirm()

    if (confirmation) {
      await api.delete('/metrics/' + id)
      Toast.success('Registro deletado.')
      await findAllBettweenDates()
    }
  }

  if (loadding) {
    return (
      <>
        <NavBar>
          <div className="container-fluid">
            <div className="mt-3 p-2 bg-dark-blue-1 rounded fw-bold d-flex justify-content-between  align-items-center border mb-3">
              <p className="text-white" style={{ fontSize: '1.3rem' }}>
                <HiOutlineDocumentReport color='white' className="mb-1" size={22} /> Relatórios
              </p>
            </div>
            <div style={{ height: '90vh', display: 'flex', alignItems: 'center', gap: 15, justifyContent: 'center' }}>
              <h4>Carregando informações  </h4>
              <div className="spinner-border mb-2" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </NavBar>
      </>
    )
  }

  return (
    <>
      <NavBar>
        <div className="container-fluid" style={{ height: 'auto', marginBottom: 70 }}>
          <div className="mt-3 p-2 bg-dark-blue-1 rounded fw-bold d-flex justify-content-between  align-items-center border mb-3">
            <p className="text-white" style={{ fontSize: '1.3rem' }}>
              <HiOutlineDocumentReport color='white' className="mb-1" size={22} /> Relatórios
            </p>
            <p className="text-white">
              <span className="pointer" onClick={() => {
                setDates({
                  startDate: DateTime.fromSQL(dates.startDate).plus({ month: -1 }).toSQLDate(),
                  endDate: DateTime.fromSQL(dates.endDate).plus({ month: -1 }).toSQLDate(),
                })
              }}>
                <FaArrowAltCircleLeft size={20} />
              </span>
              <span className="ms-2 me-2">{DateTime.fromSQL(dates.startDate).monthLong}</span>
              <span className="pointer" onClick={() => {
                setDates({
                  startDate: DateTime.fromSQL(dates.startDate).plus({ month: 1 }).toSQLDate(),
                  endDate: DateTime.fromSQL(dates.endDate).plus({ month: 1 }).toSQLDate(),
                })
              }}>
                <FaArrowAltCircleRight size={20} />
              </span>
            </p>
          </div>

          <div className="cart-container" style={{ fontSize: '1rem', paddingBottom: 20 }}>
            <div className="d-flex d-flex justify-content-between align-items-center p-1 pe-2 ps-2 rounded" style={{ border: '1px solid #ccc', width: '100%', minWidth: 150 }}>
              <p className="m-0">Vendas totais</p>
              <p className="m-0">{countOrder}</p>
            </div>
            <div className="d-flex d-flex justify-content-between align-items-center p-1 pe-2 ps-2 rounded" style={{ border: '1px solid #ccc', width: '100%', minWidth: 150 }}>
              <p className="m-0">Clientes Cadastrados</p>
              <p className="m-0">{countCustomer}</p>
            </div>
            <div className="d-flex d-flex justify-content-between align-items-center p-1 pe-2 ps-2 rounded" style={{ border: '1px solid #ccc', width: '100%', minWidth: 150 }}>
              <p className="m-0">Ticket Médio</p>
              <p className="m-0">{maskCurrency((sumProducts / sumTicket).toFixed(2))}</p>
            </div>
            <div className="d-flex d-flex justify-content-between align-items-center p-1 pe-2 ps-2 rounded" style={{ border: '1px solid #ccc', width: '100%', minWidth: 150 }}>
              <p className="m-0">Total Mês</p>
              <p className="m-0">{maskCurrency(sumProducts)}</p>
            </div>
          </div>
          <div className="report-list-type-orders row">
            <>
              <div className="col-sm-4 mb-3">
                <div className="card">
                  <div className="card-body text-center mb-3" style={{}}>
                    <h5 className="text-dark rounded fw-bold" style={{ fontSize: '1rem' }}>Acessos ao cardapio (Mês)</h5>
                    <h1 className="mt-4" style={{ fontSize: '2rem', border: '1px solid #dcdcdc' }}>
                      {metrics.countMetrics}
                    </h1>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 mb-3">
                <div className="card">
                  <div className="card-body text-center mb-3" style={{}}>
                    <h5 className="text-dark rounded fw-bold" style={{ fontSize: '1rem' }}>Carrinhos abandonados (Mês)</h5>
                    <h1 className="mt-4" style={{ fontSize: '2rem', border: '1px solid #dcdcdc' }}>
                      {metrics.abandonedCarts}
                    </h1>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 mb-3">
                <div className="card">
                  <div className="card-body text-center mb-3" style={{}}>
                    <h5 className="text-dark rounded fw-bold" style={{ fontSize: '1rem' }}>Clientes capturados (Mês)</h5>
                    <h1 className="mt-4" style={{ fontSize: '2rem', border: '1px solid #dcdcdc' }}>
                      {metrics.dataCustomers}
                    </h1>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 mb-3">
                <div className="card">
                  <div className="card-body text-center mb-3" style={{ minHeight: 300 }}>
                    <h5 className="text-dark rounded fw-bold" style={{ fontSize: '1rem' }}>Lista de clientes capturados (Mês)</h5>
                    <div className="text-start mt-4" style={{ minHeight: 230, maxHeight: 230, overflow: 'auto' }}>
                      <ol className="list-group">
                        {metrics.listCustomers.map(e => {
                          return (
                            <li key={e.id} className="list-group-item" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                              <span>{e.name || 'Sem nome'} - {e.phone}</span>
                              <span className="pointer" onClick={() => deleteMetric(e.id)}><FaTrashAlt /></span>
                            </li>
                          )
                        })}

                        {metrics.listCustomers.length === 0 && (
                          <h3 className="text-center" style={{ marginTop: 90 }}>Sem clientes</h3>
                        )}
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 mb-3">
                <div className="card">
                  <div className="card-body text-center mb-3" style={{ minHeight: 300 }}>
                    <h5 className="text-dark rounded fw-bold" style={{ fontSize: '1rem' }}>Produtos mais abandonados (Mês)</h5>
                    <div className="text-start mt-4" style={{ minHeight: 230, maxHeight: 230, overflow: 'auto' }}>
                      <ol className="list-group list-group-numbered">
                        {metrics.abandonedProducts.map((e, index) => {
                          return (
                            <li key={`${e}-${index}`} className="list-group-item"><strong>{e}</strong></li>
                          )
                        })}

                        {metrics.abandonedProducts.length === 0 && (
                          <h3 className="text-center" style={{ marginTop: 90 }}>Sem produtos</h3>
                        )}
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 mb-3">
                <div className="card">
                  <div className="card-body text-center mb-3" style={{ minHeight: 300, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <h5 className="text-dark rounded fw-bold" style={{ fontSize: '1rem' }}>Valores deixados no carrinho (Mês)</h5>
                    <h1 className="mt-4" style={{ fontSize: '2rem' }}>
                      {maskCurrency(metrics.pricingCartsAbandoned.toFixed(2))}
                    </h1>
                  </div>
                </div>
              </div>
            </>
            <div className="col-sm-4 mb-3">
              <div className="card">
                <div className="card-body text-center mb-3" style={{ height: '280px' }}>
                  <h5 className="text-dark rounded fw-bold" style={{ fontSize: '1rem' }}>Nº de Pedidos (Mês)</h5>
                  <Bar options={options} data={reportingChart} />
                </div>
              </div>
            </div>
            <div className="col-sm-4 mb-3">
              <div className="card">
                <div className="card-body text-center mb-3" style={{ height: '280px' }}>
                  <h5 className="text-dark rounded fw-bold" style={{ fontSize: '1rem' }}>Total de Vendas (Mês)</h5>
                  <Bar options={options} data={reportingChartRendiments} />
                </div>
              </div>
            </div>
            <div className="col-sm-4 mb-3">
              <div className="card">
                <div className="card-body text-center mb-3" style={{ height: '280px' }}>
                  <h5 className="text-dark rounded fw-bold" style={{ fontSize: '1rem' }}>Métodos de Entrega ({DateTime.local().monthLong}/{DateTime.local().year})</h5>
                  <div style={{ height: '100%', textAlign: 'center' }}>
                    <Doughnut style={{ margin: '0 auto' }} options={options} data={reportingChartQuantityMethodDelivery} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4 mb-3">
              <div className="card">
                <div className="card-body text-center mb-3" style={{ height: '280px' }}>
                  <h5 className="text-dark rounded fw-bold" style={{ fontSize: '1rem' }}>Tipos de recebimentos (Entrega)</h5>
                  <Bar options={optionsHorizontal} data={tipoRendimentosEntrega} />;
                </div>
              </div>
            </div>
            <div className="col-sm-4 mb-3">
              <div className="card">
                <div className="card-body text-center mb-3" style={{ height: '280px' }}>
                  <h5 className="text-dark rounded fw-bold" style={{ fontSize: '1rem' }}>Tipos de recebimentos (Retirada)</h5>
                  <Bar options={optionsHorizontal} data={tipoRendimentosRetirada} />;
                </div>
              </div>
            </div>
            <div className="col-sm-12">
              <OrdersReporting />
            </div>
          </div>
        </div>
      </NavBar>
    </>
  )
}


