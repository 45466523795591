import { useContext } from "react"
import { useEffect } from "react"
import { useState } from "react"
import { api } from "../../../api/api"
import { MenuContext } from "../../../context/MenuContext"
import { Toast } from "../../../utils/Toast"
import { mascaraMoeda, maskCurrency } from "../../../utils/utils"
import { ProductContext } from "../../../context/ProductContext"
import { DataSheet } from "../../DataSheet"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { InfoFiscale } from "../../InfoFiscal"

const STATE_DEFAULT = {
  id: null,
  name: "",
  codReferencia: null,
  pricing: 0,
  oldPricing: 0,
  promotionalPrice: 0,
  description: '',
  image: '',
  categoryId: null,
  isMenu: false,
  isLocal: false,
  active: false,
  ncm: '',
  cest: '',
  refFiscal: '',
  idSector: null
}

export function ProductModal({ isMenu = true, isLocal = true, loadProducts }) {
  const navigate = useNavigate()
  const { categoryId, load } = useContext(MenuContext)
  const categories = useSelector(state => state.category.data)

  const { productEdit, setProductEdit, listRef, findAllRefs, findAllSectors, listSector } = useContext(ProductContext)

  const [cestData, setCestData] = useState([])
  const [ncmData, setNcmData] = useState([])

  const [state, setState] = useState({
    ...STATE_DEFAULT,
    categoryId: categoryId || null,
    idSector: null,
    isMenu: isMenu,
    isLocal: isLocal,
  })

  async function created(e) {
    e.preventDefault()

    if (!state.name) {
      return Toast.warning('O campo nome é obrigatório')
    }

    const response = await api.post("/products", state)
    const { id } = response.data

    Toast.success()
    loadProducts ? await loadProducts() : await load()
    clear()
    
    await findAllInfoFiscal()
    return id
  }

  async function updated(e) {
    e.preventDefault()

    if (!state.name) {
      return Toast.warning('O campo nome é obrigatório')
    }

    if (state.isMenu === false && state.isLocal === false) {
      return Toast.warning('Deve ser escolhido se o produto é de delivery ou local')
    }

    const response = await api.put("/products/" + state.id, state)
    const { id } = response.data

    Toast.success()

    loadProducts ? await loadProducts() : await load()

    clear()

    await findAllInfoFiscal()
    return id
  }

  async function convert(idProduct) {
    try {
      if (!idProduct) {
        return Toast.warning('Selecione o produto por favor')
      }

      const confirmation = await Toast.confirm(
        "Este produto será adicionado como estoque, tem certeza que quer adicionar?", 'Não', 'Sim'
      )
      if (confirmation) {
        await api.get('/stocks/convert/' + idProduct)
        document.getElementById('btn-close-modal-produto').click()
        setTimeout(() => navigate('/stock'), 800)
      }
    } catch (error) {
      Toast.warning('Este produto já está cadastrado no estoque.')
    }
  }

  function clear() {
    setProductEdit({})
    setState({
      ...STATE_DEFAULT,
      codReferencia: '',
      categoryId: categoryId || null,
      idSector: null,
      isMenu: isMenu,
      isLocal: isLocal,
    })
  }

  async function findAllInfoFiscal() {
    const [
      ncms,
      cests
    ] = await Promise.all([
      api.get('/ncms'),
      api.get('/cests'),
    ])


    setCestData(cests.data)
    setNcmData(ncms.data)
  }

  useEffect(() => {
    setState({
      ...state,
      categoryId: categoryId
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId])

  useEffect(() => {
    if (productEdit?.id)
      setState({
        id: productEdit.id,
        name: productEdit.name ?? "",
        codReferencia: productEdit.codReferencia ?? '',
        pricing: productEdit.pricing ?? "",
        oldPricing: productEdit.oldPricing ?? "",
        promotionalPrice: productEdit.promotionalPrice ?? "",
        description: productEdit.description ?? "",
        image: productEdit.image ?? "",
        categoryId: productEdit.categoryId,
        isMenu: productEdit.isMenu,
        isLocal: productEdit.isLocal,
        active: Boolean(productEdit.active),
        refFiscal: productEdit.refFiscal,
        ncm: productEdit.ncm,
        cest: productEdit.cest,
        idSector: productEdit?.idSector ?? null
      })
    else
      setState({
        ...STATE_DEFAULT,
        categoryId: categoryId,
        idSector: null,
        isMenu: isMenu,
        isLocal: isLocal,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productEdit])

  useEffect(() => {
    findAllRefs()
    findAllSectors()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    findAllInfoFiscal()
  }, [])

  return (
    <>
      <div className="modal fade" id="modalProduct" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Cadastrar Produto</h5>
              <button type="button" id="btn-close-modal-produto" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => clear()}></button>
            </div>
            <div className="modal-body">
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <button className="nav-link active" id="nav-produto-tab" data-bs-toggle="tab" data-bs-target="#nav-produto" type="button" role="tab" aria-controls="nav-produto" aria-selected="true">
                    Produto
                  </button>
                  <button className="nav-link" id="nav-fiscal-produto-tab" data-bs-toggle="tab" data-bs-target="#nav-fiscal-produto" type="button" role="tab" aria-controls="nav-fiscal-produto" aria-selected="false">
                    Fiscal
                  </button>
                  <button className="nav-link" id="nav-ficha-produto-tab" data-bs-toggle="tab" data-bs-target="#nav-ficha-produto" type="button" role="tab" aria-controls="nav-ficha-produto" aria-selected="false">
                    Ficha
                  </button>
                </div>
              </nav>
              <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-produto" role="tabpanel" aria-labelledby="nav-produto-tab">
                  <form className="row mt-3">
                    <div className="form-group col-sm-4">
                      <label>Nome</label>
                      <input type="text" maxLength={70} value={state.name} onChange={(e) => setState({
                        ...state,
                        name: e.target.value
                      })} className="form-control form-control-sm" id="name" />
                      <small id="nameHelp" className="form-text text-muted">Digite o nome do Produto.</small>
                    </div>
                    <div className="form-group col-sm-2">
                      <label>Cod Referência</label>
                      <input type="text" maxLength={70} value={state.codReferencia} onChange={(e) => setState({
                        ...state,
                        codReferencia: e.target.value
                      })} className="form-control form-control-sm" id="name" />
                    </div>
                    <div className="form-group col-sm-3">
                      <label>Categoria</label>
                      <select value={state.categoryId} onChange={(e) => {
                        setState({
                          ...state,
                          categoryId: e.target.value === 'Selecione' ? null : e.target.value
                        })
                      }} className="form-select form-select-sm" aria-label=".form-select-sm example">
                        <option value={null}>Selecione</option>
                        {categories?.map((value) => {
                          return <option key={value.id} value={value.id}>{value.name}</option>
                        })}
                      </select>
                    </div>
                    <div className="form-group col-sm-3">
                      <label>Setor</label>
                      <select value={state.idSector} onChange={(e) => {
                        setState({
                          ...state,
                          idSector: e.target.value === 'Selecione' ? null : e.target.value
                        })
                      }} className="form-select form-select-sm" aria-label=".form-select-sm example">
                        <option value={null}>Selecione</option>
                        {listSector?.map((value) => {
                          return <option key={value.id} value={value.id}>{value.name}</option>
                        })}
                      </select>
                    </div>
                    <div className="form-group col-sm-3">
                      <label>Preço</label>
                      <input type="text" value={maskCurrency(state.pricing)} onChange={(e) => setState({
                        ...state,
                        pricing: mascaraMoeda(e.target.value)
                      })} className="form-control form-control-sm" id="pricing" />
                    </div>
                    <div className="form-group col-sm-3">
                      <label>Preço Antigo</label>
                      <input type="text" value={maskCurrency(state.oldPricing)} onChange={(e) => setState({
                        ...state,
                        oldPricing: mascaraMoeda(e.target.value)
                      })} className="form-control form-control-sm" id="oldPricing" />
                    </div>
                    <div className="form-group col-sm-6">
                      <label>Preço Promocional</label>
                      <input type="text" value={maskCurrency(state.promotionalPrice)} onChange={(e) => setState({
                        ...state,
                        promotionalPrice: mascaraMoeda(e.target.value)
                      })} className="form-control form-control-sm" id="promotionalPrice" />
                      <div id="promotionalPrice" className="form-text text-dark">Se preenchido o valor irá aparecer no cardápio.</div>
                    </div>
                    <div className="col-sm-4 mb-2">
                      <label className="w-100">
                        NCM
                        <input value={state.ncm} onChange={(e) => setState({
                          ...state,
                          ncm: e.target.value
                        })} list="ncms" className="form-control form-control-sm col-sm-12" />
                      </label>
                      <datalist id="ncms">
                        {ncmData.map((e) => {
                          return (
                            <option value={e.code}></option>
                          )
                        })}
                      </datalist>
                    </div>
                    <div className="col-sm-4 mb-2">
                      <label>CEST</label>
                      <label className="w-100">
                        <input value={state.cest} onChange={(e) => setState({
                          ...state,
                          cest: e.target.value
                        })} list="cests" className="form-control form-control-sm col-sm-12" />
                      </label>
                      <datalist id="cests">
                        {cestData.map((e) => {
                          return (
                            <option value={e.code}></option>
                          )
                        })}
                      </datalist>
                    </div>
                    <div className="col-sm-4 mb-2">
                      <label>REF Fiscal </label>
                      <select value={state.refFiscal} onChange={(e) => setState({ ...state, refFiscal: e.target.value })} className="form-select form-select-sm">
                        <option selected>---- Selecione ----</option>
                        {listRef.map((value) => {
                          return (
                            <option value={value.ref}>{value.ref} - {value.description}</option>
                          )
                        })}
                      </select>
                    </div>

                    <div className="form-group col-sm-12">
                      <label>Descrição</label>
                      <textarea maxLength={1000} type="text" value={state.description} onChange={(e) => setState({
                        ...state,
                        description: e.target.value
                      })} className="form-control form-control-sm" id="description" />
                    </div>
                    <div className="mt-2 row">
                      <div className="form-group form-check col-sm-1" style={{ marginLeft: 10 }}>
                        <input type="checkbox" checked={state.active} onChange={(e) => setState({
                          ...state,
                          active: Boolean(e.target.checked)
                        })} className="form-check-input" id="activeCategory" />
                        <label className="form-check-label" htmlFor="activeCategory">Ativar</label>
                      </div>
                      <div className="form-group form-check col-sm-2">
                        <input type="checkbox" checked={state.isMenu} onChange={(e) => setState({
                          ...state,
                          isMenu: Boolean(e.target.checked)
                        })} className="form-check-input" id="activeismenu" />
                        <label className="form-check-label" htmlFor="activeismenu">Produto de delivery?</label>
                      </div>
                      <div className="form-group form-check col-sm-2">
                        <input type="checkbox" checked={state.isLocal} onChange={(e) => setState({
                          ...state,
                          isLocal: Boolean(e.target.checked)
                        })} className="form-check-input" id="activeislocal" />
                        <label className="form-check-label" htmlFor="activeislocal">Produto de venda local?</label>
                      </div>
                    </div>
                  </form>
                  <br />
                  <div style={{ float: 'right', width: 150 }}>
                    <button type="submit" className="btn btn-sm btn-success shadow-sm" style={{ width: '100%' }} onClick={state.id ? updated : created}>Salvar</button>
                  </div>
                  <div style={{ float: 'left', width: 250, marginRight: 10, display: state?.id ? 'block' : 'none' }}>
                    <button type="submit" className="btn btn-sm btn-dark shadow-sm" style={{ width: '100%' }} onClick={() => convert(state.id)}>Adicionar produto como estoque?</button>
                  </div>
                </div>
                <div className="tab-pane fade" id="nav-fiscal-produto" role="tabpanel" aria-labelledby="nav-fiscal-produto-tab">
                  <div className="mt-2">
                    <InfoFiscale />
                  </div>
                </div>
                <div className="tab-pane fade" id="nav-ficha-produto" role="tabpanel" aria-labelledby="nav-ficha-produto-tab">
                  <div className="mt-2">
                    <DataSheet id={productEdit?.id} keyValue={'idProduct'} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
