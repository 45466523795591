import { api } from "../api/api";
import { GlobalContext } from "../context/GlobalContext";
import { useEffect, useState } from 'react'

export const GlobalProvider = ({ children }) => {
  const [user, setUser] = useState({})
  const [userPermissions, setUserPermissions] = useState(null)

  function isAccess(key = '', value = '') {
    if (user.typeUser === 'admin') {
      return 'admin'
    }

    if (user.typeUser === 'garcom') {
      return 'garcom'
    }

    if (key || value) {
      return userPermissions ? userPermissions[key]?.includes(value) : false
    }

    return false
  }


  return (
    <GlobalContext.Provider value={{ user, setUser, isAccess, userPermissions, setUserPermissions }}>
      {children}
    </GlobalContext.Provider>
  )
}