/* eslint-disable no-undef */
import React, { useCallback, useContext, useEffect, useState } from "react";

import "./map.css";
import { useDispatch } from "react-redux";
import { MdArrowCircleRight } from "react-icons/md";
import { api } from "../../../api/api";
import { Toast } from "../../../utils/Toast";
import { mascaraMoeda, maskCurrency } from "../../../utils/utils";
import { HiTrash } from "react-icons/hi";
import { UPDATE_COMPANY } from "../../../stores/reducers/CompanyReducers";
import { useCompany } from "../../../hooks/useCompany";
import { loadGMaps } from "../../../utils/loadMaps";
import { GlobalContext } from "../../../context/GlobalContext";


export function MapPage() {
  const { isAccess } = useContext(GlobalContext)

  const dispatch = useDispatch()
  const { company, configurations } = useCompany()

  const [map, setMap] = useState(null)
  const [marker, setMarker] = useState(null)
  const [position, setPosition] = useState({
    lat: null,
    lng: null,
  })

  const [state, setState] = useState({
    start: '',
    end: '',
    pricing: 0
  })

  const [distancesPricing, setDistancesPricing] = useState([])

  const [loadMapCompleted, setLoadMapCompleted] = useState(false)

  useEffect(() => {
    if (configurations.id) {
      loadGMaps(() => {
        setLoadMapCompleted(!loadMapCompleted)
      }, configurations.googleKey)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configurations.googleKey, configurations.id])

  function getCurrentPosition() {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          if (result.state === "granted") {
            navigator.geolocation.getCurrentPosition((location) => {
              setPosition({
                lat: location.coords.latitude,
                lng: location.coords.longitude
              })
              dispatch(UPDATE_COMPANY({
                location: {
                  lat: location.coords.latitude,
                  lng: location.coords.longitude
                }
              }))
            });
          } else if (result.state === "prompt") {
            navigator.geolocation.getCurrentPosition((location) => {
              setPosition({
                lat: location.coords.latitude,
                lng: location.coords.longitude
              })
              dispatch(UPDATE_COMPANY({
                location: {
                  lat: location.coords.latitude,
                  lng: location.coords.longitude
                }
              }))
            }, null, null);
          } else if (result.state === "denied") {
            navigator.geolocation.getCurrentPosition((location) => {
              setPosition({
                lat: location.coords.latitude,
                lng: location.coords.longitude
              })

              dispatch(UPDATE_COMPANY({
                location: {
                  lat: location.coords.latitude,
                  lng: location.coords.longitude
                }
              }))
            }, (err) => {
              alert(err.message)
            });
            //If denied then you have to show instructions to enable location
          }
          result.onchange = function () {
            console.log(result.state);
          };
        });
    } else {
      alert("Sorry Not available!");
    }
  }

  const initMap = useCallback(() => {
    const createMap = new google.maps.Map(document.getElementById('maps-delivery'), {
      zoom: 15,
    });

    google.maps.event.addListenerOnce(createMap, 'tilesloaded', function () {
      console.log('renderizou map')
    });

    setMap(createMap)
  }, [])

  useEffect(() => {
    if (map) {
      createMarker()
      map.setCenter(position)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, position])

  function createMarker() {
    if (marker) {
      marker.setMap(null)
    }

    const aux = new google.maps.Marker({
      position: position,
      draggable: true,
      label: {
        text: 'Origem',
        color: 'white',
        fontWeight: 'bold',
        className: 'bg-dark p-2 rounded mb-5'
      },
      title: 'Loja',
      map: map
    });

    aux.addListener('dragend', (event) => {
      setPosition({
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      })
    });

    setMarker(aux)
  }

  async function salvarLocation() {
    if (!isAccess('configuracoes', 'posicao_estabelecimento')) {
      return Toast.warning('Você não tem permissão para alterar posição do estabalecimento')
    }

    await api.put('/companies/location', {
      location: position
    })
    
    Toast.success("Localização adicionada")
  }

  async function save() {
    if (!isAccess('metodo_entrega', 'create')) {
      return Toast.warning('Você não tem permissão para criar uma entrega.')
    }

    await api.post('/distance-pricing', state)
    await findAll()
    Toast.success()
    setState({
      start: '',
      end: '',
      pricing: 0
    })
  }

  async function updated(id, key, value) {
    if (!isAccess('metodo_entrega', 'update')) {
      return Toast.warning('Você não tem permissão para atualizar entrega.')
    }

    await api.put('/distance-pricing/' + id, {
      [key]: value
    })
    await findAll()
    Toast.success()
  }

  async function findAll() {
    const response = await api.get('/distance-pricing')
    setDistancesPricing(response.data.sort((a, b) => a.start - b.start))
  }

  async function deleteDistance(id) {
    if (!isAccess('metodo_entrega', 'delete')) {
      return Toast.warning('Você não tem permissão para deletar uma entrega.')
    }

    const confirmation = await Toast.confirm()
    if (confirmation) {
      await api.delete('/distance-pricing/' + id)
      await findAll()
      return Toast.warning("Registro deletado.")
    }
  }

  useEffect(() => {
    findAll()
  }, [])

  useEffect(() => {
    if (document.getElementById('googleMaps') && loadMapCompleted)
      initMap()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadMapCompleted])

  useEffect(() => {
    if (company?.location?.lat) {
      setPosition(company.location)
    } else {
      getCurrentPosition()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company.location])

  return (
    <div className="mt-3">
      <div className="mb-3 d-flex" style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <p>
          Endereço: {company.address}, {company.number}, {company.district} - {company.city} - {company.uf}
        </p>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 5, textAlign: 'center' }}>
          <button className="btn btn-sm border" onClick={() => getCurrentPosition()}>Pegar posição atual</button>
          <button className="btn btn-sm btn-success" onClick={() => salvarLocation()}>Salvar Localização de estabelecimento</button>
        </div>
      </div>
      <div id="maps-delivery" style={{ height: '400px', width: '100%' }}></div>
      <div className="row mt-3">
        <div className="col-sm-6">
          <div className="row">
            <div className="col-sm-4">
              <label htmlFor="">De (Km)</label>
              <input type="number" value={state.start} onChange={(e) => setState({ ...state, start: e.target.value })} placeholder="Digite o km inicial" className="form-control form-control-sm" />
            </div>
            <div className="col-sm-4">
              <label htmlFor="">Até (Km)</label>
              <input type="number" value={state.end} onChange={(e) => setState({ ...state, end: e.target.value })} placeholder="Digite o km final" className="form-control form-control-sm" />
            </div>
            <div className="col-sm-4">
              <label htmlFor="">Preço</label>
              <input type="text" value={maskCurrency(state.pricing)} onChange={(e) => setState({ ...state, pricing: mascaraMoeda(e.target.value) })} placeholder="Digite o km final" className="form-control form-control-sm" />
            </div>
            <div className="col-sm-12 mt-2">
              <button className="btn btn-sm btn btn-sm border" onClick={() => save()}>Salvar</button>
            </div>
          </div>
        </div>
        <div className="col-sm-6 card p-2">
          <p className="bg-dark-blue-3 text-white p-2 rounded"> < MdArrowCircleRight size={20} /> Lista de KM</p>
          <table className="table">
            <thead>
              <tr>
                <th >#</th>
                <th >De</th>
                <th >Até</th>
                <th >Preço</th>
                <th >Frete Grátis</th>
                <th >Ações</th>
              </tr>
            </thead>
            <tbody>
              {distancesPricing.sort((a, b) => a.start - b.start).map((value) => {
                return (
                  <tr key={value.id}>
                    <th>#</th>
                    <td>{value.start}km</td>
                    <td>{value.end}km</td>
                    <td>{maskCurrency(value.pricing)}</td>
                    <td>
                      <div className="form-check form-check-sm form-switch" style={{ fontSize: '1rem' }}>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={value.free}
                          onChange={(e) => updated(value.id, 'free', e.target.checked)}
                          role="switch" id="flexSwitchCheckDefault"
                        />
                      </div>
                    </td>
                    <td onClick={() => deleteDistance(value.id)}>
                      <HiTrash size={20} color="red" className="pointer" />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

