import ReactDOM from 'react-dom/client';
import React from 'react';

import { Provider } from 'react-redux';

import App from './App';
import store from './stores/store';
import './utils/prototype'
import { GlobalProvider } from './providers/GlobalProvider';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <>
    <ToastContainer />
    <Provider store={store}>
      <GlobalProvider>
        <App />
      </GlobalProvider>
    </Provider>
  </>
);
