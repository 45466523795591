
export function Wpp({status = "Desconectada"}) {
  return (
    <div className="modal fade" id="modalWpp" tabIndex="-1" aria-labelledby="modalIsOpenSafeLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="modalIsOpenSafeLabel">Integração WhatsApp</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>

          <br /><br />  
          <h1 className="text-center">A integração está {status}</h1>
          <br /><br />
        </div>
      </div>
    </div>
  )
}