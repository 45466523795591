/* eslint-disable no-undef */
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { numOrderFormat } from '../../../../../utils/utils';

const MapComponent = memo(({ dataDestinations }) => {
  const company = useSelector(state => state.company.data)

  const [link, setLink] = useState('')
  const [distances, setDistances] = useState([])

  const calculateAndDisplayRoute = useCallback((directionsService = new google.maps.DirectionsService(), directionsRenderer = new google.maps.DirectionsRenderer(), destinations = [], map) => {
    const waypoints = destinations.map(e => {
      return {
        location: e,
        stopover: true,
      }
    })

    directionsService
      .route({
        origin: {
          lat: company?.location?.lat,
          lng: company?.location?.lng,
        },
        destination: waypoints[waypoints.length - 1]?.location ?? {},
        waypoints: waypoints,
        optimizeWaypoints: true,
        travelMode: google.maps.TravelMode.DRIVING,
      })
      .then((response) => {
        directionsRenderer.setDirections(response);
        directionsRenderer.setOptions({
          suppressMarkers: true,
          preserveViewport: true,
        });

        try {
          const aux = response.routes[0].legs.slice(0, response.routes[0].waypoint_order.length)
          const auxDistances = aux.map(e => {
            return {
              "distance": e.distance,
              "duration": e.duration,
            }
          })

          setDistances(auxDistances)

          const { origin, destination, waypoints } = response.request;


          for (let index = 0; index < aux.length; index++) {
            const item = aux[index];
            const numOrder = destinations[index]?.numOrder
            new google.maps.Marker({
              position: {
                lat: item.end_location.lat(),
                lng: item.end_location.lng(),
              },
              label: {
                text: `Entrega ${item.distance.text} - ${numOrderFormat(numOrder)}`,
                color: 'white',
                fontWeight: 'bold',
                className: 'bg-dark p-2 rounded mb-5'
              },
              map: map
            });
          }

          const originStr = `${origin.location.lat()},${origin.location.lng()}`;
          const destinationStr = `${destination.location.lat()},${destination.location.lng()}`;
          // Gerar o link com as direções
          var link = "https://www.google.com/maps/dir/?api=1";
          link += "&origin=" + encodeURI(originStr);
          link += "&destination=" + encodeURI(destinationStr);
          link += "&waypoints=" + waypoints.map(waypoint => encodeURI(`${waypoint.location.location.lat()},${waypoint.location.location.lng()}`)).join('|');
          link += "&travelmode=" + google.maps.TravelMode.DRIVING;
          // Imprimir o link

          setLink(link)
        } catch (error) {
          console.log(error)
        }
      })
      .catch((e) => console.log('Directions request failed due to ' + e));
  }, [company?.location?.lat, company?.location?.lng])

  const initMap = useCallback(() => {
    const directionsService = new google.maps.DirectionsService();
    const directionsRenderer = new google.maps.DirectionsRenderer();

    const map = new google.maps.Map(document.getElementById('map'), {
      zoom: 15,
      center: {
        lat: company?.location?.lat,
        lng: company?.location?.lng,
      },
    });

    directionsRenderer.setMap(map)

    new google.maps.Marker({
      position: {
        lat: company?.location?.lat,
        lng: company?.location?.lng,
      },
      label: {
        text: 'Origem',
        color: 'white',
        fontWeight: 'bold',
        className: 'bg-dark p-2 rounded mb-5'
      },
      title: 'ORIGEM',
      map: map
    });

    calculateAndDisplayRoute(directionsService, directionsRenderer, dataDestinations, map);
  }, [calculateAndDisplayRoute, company?.location?.lat, company?.location?.lng, dataDestinations])

  useEffect(() => {
    if (company && company?.location?.lat !== '' && company?.location?.lng !== '' &&  document.getElementById('googleMaps')) {
      initMap()
    }
  }, [company, dataDestinations, initMap])

  return (
    <>
      <div id="map" style={{ height: '400px', width: '100%' }}></div>
      <div className='border p-2 rounded mt-2 d-flex justify-content-between'>
        <p className='mt-2'><strong>Distáncia:</strong> {(distances.reduce((prev, current) => prev + current.distance.value, 0) / 1000).toFixed(2)} KM</p>
        <button className='btn btn-sm btn-dark-blue mt-2 fw-bold' onClick={() => window.open(link, '_blank')}>Abrir link</button>
      </div>
    </>

  );
}, (prev, next) => {
  const _prev = prev.dataDestinations.join('')
  const _next = next.dataDestinations.join('')

  return _prev === _next
})

export { MapComponent };
