import React, { useState, useRef } from 'react'
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop'

import 'react-image-crop/dist/ReactCrop.css'
import { useDebounceEffect } from './useDebounceEffect'
import { canvasPreview } from './canvaPreview'
import { isMobile } from 'react-device-detect';
import { Toast } from '../../../utils/Toast'

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        x: 25,
        y: 25,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

export function ModalImageCrop({ uploadImageBanner, toggleModal, width, height, title = 'Cortar imagem' }) {
  const [imgSrc, setImgSrc] = useState('')
  const previewCanvasRef = useRef(null)
  const imgRef = useRef(null)
  const [crop, setCrop] = useState()
  const [completedCrop, setCompletedCrop] = useState()
  const [scale] = useState(1)
  const [rotate] = useState(0)

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined)
      const reader = new FileReader()
      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || ''),
      )
      reader.readAsDataURL(e.target.files[0])
    }
  }

  function onImageLoad(e) {
    setCrop(centerAspectCrop(width, height, 16 / 9))
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        )
      }
    },
    100,
    [completedCrop, scale, rotate],
  )

  function toBlob() {
    if (completedCrop?.width === 0 || completedCrop?.height === 0 || previewCanvasRef.current === null || imgRef.current === null || imgSrc === '') {
      return Toast.warning("Selecione a foto por favor")
    }

    previewCanvasRef.current.toBlob((blob) => {
      uploadImageBanner(blob)
    });
  }

  return (
    <div className="modal fade" id="modalCropImage" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modalCropImageLabel" aria-hidden="true">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="modalCropImageLabel">{title}</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => toggleModal()}></button>
          </div>
          <div className="modal-body">
            {isMobile &&
              <div className="alert alert-primary" role="alert">
                Essa funcionalidade só pode ser usado no computador
              </div>
            }
            {!isMobile &&
              <div className="App">
                <div className="Crop-Controls">
                  <input type="file" className='form-control mb-4' accept="image/*" onChange={onSelectFile} />
                </div>
                <div className="row">
                  <div className='text-center mb-4 col-sm-6'>
                    <p className='bg-dark-blue-1 mb-2 text-white rounded'>Imagem selecionada</p>
                    {!!imgSrc && (
                      <ReactCrop
                        crop={crop}
                        onChange={(_, percentCrop) => setCrop(percentCrop)}
                        onComplete={(c) => setCompletedCrop(c)}
                        maxHeight={height}
                        maxWidth={width}
                      >
                        <img
                          ref={imgRef}
                          alt="Crop me"
                          src={imgSrc}
                          onLoad={onImageLoad}
                          loading='lazy'
                          width={600}
                          style={{ objectFit: 'cover', border: '1px solid #000' }}
                        />
                      </ReactCrop>
                    )}
                  </div>
                  <div className='text-center col-sm-6' style={{}}>
                    <p className='bg-dark-blue-1 mb-2 text-white rounded'>Pré Visualização</p>
                    {!!completedCrop && (
                      <canvas
                        ref={previewCanvasRef}
                        style={{
                          width: completedCrop.width,
                          height: completedCrop.height,
                        }}
                      />
                    )}
                  </div>
                </div>

                <button type="button" className="btn btn-sm border" onClick={() => toBlob()}>Salvar</button>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export function ModalImageLogo({ uploadImageBanner, toggleModal, title = 'Selecione a logo por favor' }) {
  const [imgSrc, setImgSrc] = useState('')
  const blobRef = useRef(null)

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        setImgSrc(reader.result?.toString() || '')
      })

      const file = e.target.files[0]
      const blob = new Blob([file], { type: file.type });

      blobRef.current = blob

      reader.readAsDataURL(e.target.files[0])
    }
  }

  function toBlob() {
    uploadImageBanner(blobRef.current)
  }

  return (
    <div className="modal fade" id="modalCropImageLogo" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modalCropImageLogoLabel" aria-hidden="true">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="modalCropImageLogoLabel">{title}</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => toggleModal()}></button>
          </div>
          <div className="modal-body">
            {isMobile &&
              <div className="alert alert-primary" role="alert">
                Essa funcionalidade só pode ser usado no computador
              </div>
            }
            {!isMobile &&
              <div className="App">
                <div className="Crop-Controls">
                  <input type="file" className='form-control mb-4' accept="image/*" onChange={onSelectFile} />
                </div>
                <div className="row">
                  <div className='text-center mb-4 col-sm-6'>
                    <p className='bg-dark-blue-1 mb-2 text-white rounded'>Imagem selecionada</p>
                    <img
                      alt="Imagem logo"
                      src={imgSrc}
                      loading='lazy'
                      width={600}
                      style={{ objectFit: 'cover', border: '1px solid #000' }}
                    />
                  </div>
                </div>

                <button type="button" className="btn btn-sm border" onClick={() => toBlob()}>Salvar</button>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}