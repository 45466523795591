import { useEffect, useState } from "react";
import { NavBar } from "../../components/NavBar";
import { useCompany } from "../../hooks/useCompany";
import { GrLink } from "react-icons/gr";
import { api } from "../../api/api";
import { Toast } from "../../utils/Toast";
import { useIntegrationWhatsApp } from "../../hooks/useIntegrationWhatsApp";


export function Integration() {
  const { getQrCode, qrCode, restartIntegration, create } = useIntegrationWhatsApp()
  const { configurations, updateConfiguration } = useCompany()


  const [state, setState] = useState({
    idStore: configurations.idStore ?? '',
    googleKey: configurations.googleKey ?? ''
  })
  const [integrationWhatsApp, setIntegrationWhatsApp] = useState({
    host: '',
    messageAccepted: '',
    messageSendDelivery: '',
    messageSendReview: ''
  })

  useEffect(() => {
    if (configurations?.id) {
      setState({
        idStore: configurations.idStore ?? '',
        googleKey: configurations.googleKey ?? ''
      })
    }
  }, [configurations])

  async function saveKeys() {
    updateConfiguration({ ...state })
    await api.post('/configurations', state)
    Toast.success()
  }

  async function saveIntegrationWhatsApp() {
    await api.post(`/integration-whatsapp`, integrationWhatsApp)
    Toast.success()
  }

  async function getIntegrationWhatsApp() {
    const response = await api.get(`/integration-whatsapp`)
    setIntegrationWhatsApp({
      host: response.data[0]?.host ?? '',
      messageAccepted: response.data[0]?.messageAccepted ?? '',
      messageSendDelivery: response.data[0]?.messageSendDelivery ?? '',
      messageSendReview: response.data[0]?.messageSendReview ?? ''
    })
  }

  useEffect(() => {
    getIntegrationWhatsApp()
  }, [])

  return (
    <>
      <NavBar>
        <div className="container-fluid mt-4">

          <div className="mt-3 p-2 bg-dark-blue-1 rounded fw-bold d-flex justify-content-between  align-items-center border mb-3">
            <p className="text-white" style={{ fontSize: '1.3rem' }}> <GrLink className='bg-white rounded-pill p-1 mb-1' color='white' size={22} /> Integrações</p>
          </div>


          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Ifood</button>
            </li>
            <>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Google Maps</button>
              </li>

              <li className="nav-item" role="presentation">
                <button className="nav-link" id="whatsapp-tab" data-bs-toggle="tab" data-bs-target="#whatsapp" type="button" role="tab" aria-controls="whatsapp" aria-selected="false">
                  WhatsApp
                </button>
              </li>
            </>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
              <div className="col-sm-6 mt-2 p-3">
                <div>
                  Faça a integração do seu BMS Pedidos com o seu Ifood.<br />
                  Gerencie todos os seus pedidos por aqui! Centralize os atendimento, ganhe tempo e a
                  satisfação dos seus clientes.

                  Para realizar integração, basta seguir os passos: <br /><br />

                  | - Acessar o Portal do Parceiro (<a href="https://portal.ifood.com.br/login" target="_blank" rel="noopener noreferrer">Acessar</a>) <br />
                  || - Acesse a área de Minha Loja <br />
                  ||| - Copie o ID da Loja e cole abaixo <br />
                  |||| - Ao adicionar abaixo e salvar, clique nesse <a href="https://portal.ifood.com.br/apps" target="_blank" rel="noopener noreferrer">Link</a> e será direcionado para os Apps do ifood, onde poderá aceitar a integração  no aplicativo. 
                </div>
                <div className="mt-3">
                  <label htmlFor="">Código da loja no ifood</label>
                  <input
                    className="form-control form-control-sm"
                    value={state?.idStore ?? ''}
                    onChange={(e) => setState({ ...state, idStore: e.target.value })}
                    type="text"
                  />

                  <button className="btn btn-sm btn-dark-blue mt-3" onClick={() => saveKeys()}>Salvar</button>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
              <div className="mt-2 p-3">
                Faça a integração do seu BMS Pedidos com o seu Google Maps. <br />
                Calcule com precisão o seu frete por distância percorrida. Além disso, com essa
                integração você pode gerar para o seu entregador a MELHOR ROTA.<br />
                Ganhe agilidade e diminua custos.<br /><br />

                |    - Acessar o Google Maps e faça o Cadastro (<a href="https://developers.google.com/maps?hl=pt-br" target="_blank" rel="noopener noreferrer">Acessar</a>) <br />
                ||   - Após realizar o cadastro, dentro do painel acesse Credenciais <br />
                |||  - Nesta tela clique em (+Criar Credenciais) e selecione Chave de API <br />
                |||| - Copie e cole a baixo
              </div>
              <div className="mt-3">
                <label htmlFor="">Código do Google Maps</label>
                <input
                  className="form-control form-control-sm"
                  value={state?.googleKey ?? ''}
                  onChange={(e) => setState({ ...state, googleKey: e.target.value })}
                  type="text"
                />

                <button className="btn btn-sm btn-dark-blue mt-3" onClick={() => saveKeys()}>Salvar</button>
              </div>
            </div>
            <div className="tab-pane fade" id="whatsapp" role="tabpanel" aria-labelledby="whatsapp-tab">
              <div className="mt-2">
                <p style={{ fontSize: '1.2rem' }}>
                  Automatize o seu atendimento com esta integração. Personalize as suas mensagens de acordo com o seu delivery. Agora, ao fazer a alteração de status do pedido, o seu cliente já recebe uma mensagem automaticamente. Prático, rápido e eficiente!
                </p>

                <div className="mt-4">
                  <p>Váriaveis de uso</p>

                  1 - @NUMERO_PEDIDO (Número do pedido do cliente)<br />
                  3 - @NOME_CLIENTE (Nome do cliente no pedido) <br />
                  4 - @CONTATO_CLIENTE (Contato do cliente no pedido) <br />
                  5 - @LINK (Link do pedido) <br />
                </div>


                <div className="row mt-3">
                  <div className="mb-3 col-sm-4">
                    <label htmlFor="exampleFormControlTextarea1" className="form-label">Mensagem de aceite do pedido.</label>
                    <textarea className="form-control"
                      value={integrationWhatsApp.messageAccepted}
                      onChange={(e) => {
                        setIntegrationWhatsApp({ ...integrationWhatsApp, messageAccepted: e.target.value })
                      }}
                      style={{ fontSize: '0.8rem' }}
                      maxLength={500}
                      id="exampleFormControlTextarea1" rows="7">
                    </textarea>
                  </div>
                  <div className="mb-3 col-sm-4">
                    <label htmlFor="exampleFormControlTextarea1" className="form-label">Mensagem de enviado para entrega.</label>
                    <textarea className="form-control"
                      value={integrationWhatsApp.messageSendDelivery}
                      onChange={(e) => {
                        setIntegrationWhatsApp({ ...integrationWhatsApp, messageSendDelivery: e.target.value })
                      }}
                      style={{ fontSize: '0.8rem' }}
                      maxLength={500}
                      id="exampleFormControlTextarea1" rows="7">
                    </textarea>
                  </div>
                  <div className="mb-3 col-sm-4">
                    <label htmlFor="exampleFormControlTextarea1" className="form-label">Mensagem de avaliação.</label>
                    <textarea className="form-control"
                      value={integrationWhatsApp.messageSendReview}
                      onChange={(e) => {
                        setIntegrationWhatsApp({ ...integrationWhatsApp, messageSendReview: e.target.value })
                      }}
                      maxLength={500}
                      style={{ fontSize: '0.8rem' }}
                      id="exampleFormControlTextarea1" rows="7">
                    </textarea>
                  </div>
                </div>

                <div>
                  <button className="btn btn-sm btn-dark-blue" onClick={() => saveIntegrationWhatsApp()}>Salvar registro</button>
                  <button style={{ float: 'right', textDecoration: 'underline' }} className="btn btn-sm border ms-2" onClick={() => create()}>Conectar</button>
                  <button style={{ float: 'right' }} className="btn btn-sm border ms-2" onClick={() => getQrCode()}>Pegar QRCODE</button>
                  <button style={{ float: 'right', textDecoration: 'underline' }} className="btn btn-sm border ms-2" onClick={() => restartIntegration()}>Desconectar</button>
                </div>

                <div className="mt-3" style={{ display: qrCode ? 'block' : 'none' }}>
                  <img src={`data:image/png;base64, ${qrCode}`} alt="" width={250} height={250} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </NavBar >
    </>
  )
}