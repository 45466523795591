import { TbEdit, TbInfoSquare } from "react-icons/tb";
import { BsFillTrashFill } from 'react-icons/bs'
import { AiFillCheckSquare } from 'react-icons/ai'
import { ImCheckboxUnchecked } from 'react-icons/im'

import { NavBar } from "../../components/NavBar";
import { useEffect, useState } from "react";
import { api } from "../../api/api";
import { Toast } from "../../utils/Toast";
import { useNavigate } from "react-router-dom";

export function CrossSellingPage() {
  const navigate = useNavigate()

  const [crossSelling, setCrossSelling] = useState([])
  const [categories, setCategories] = useState([])
  const [products, setProducts] = useState([])
  const [productSelect, setProductSelect] = useState('')
  const [crossSellingData, setCrossSellingData] = useState({
    id: '',
    categoryId: "",
    title: "",
    description: "",
    active: false,
  })

  const [crossSellingDataItems, setCrossSellingDataItems] = useState([])

  async function findAll() {
    const response = await api.get('/cross-selling')
    setCrossSelling(response.data)
  }

  async function findAllCategory() {
    const response = await api.get("/category")
    setCategories(response.data)
  }


  async function findAllProducts() {
    const response = await api.get("/products")
    setProducts(response.data.data)
  }

  async function create() {
    const response = await api.post('/cross-selling', {
      ...crossSellingData,
      products: crossSellingDataItems
    })
    setCrossSellingData({
      ...crossSellingData,
      id: response.data.id
    })
    await findAll()
    Toast.success("Registro salvo com sucesso.")
  }

  async function update(id) {
    if (!id)
      return
    await api.put('/cross-selling/' + id, {
      ...crossSellingData,
      products: crossSellingDataItems
    })
    await findAll()
    Toast.success("Registro atualizado com sucesso.")
  }

  async function destroy(id) {
    const confirm = await Toast.confirm()
    if (confirm) {
      await api.delete(`/cross-selling/${id}`)
      await findAll()
    }
  }

  async function edit(id) {
    const response = await api.get(`/cross-selling/${id}`)
    setCrossSellingData({
      id: response.data.id,
      categoryId: response.data.categoryId,
      title: response.data.title,
      description: response.data.description,
      active: response.data.active,
    })

    setCrossSellingDataItems(response.data.products)
  }

  function clear() {
    setCrossSellingData({
      id: '',
      categoryId: "",
      title: "",
      description: "",
      active: false,
    })

    setCrossSellingDataItems([])

    setProductSelect('')
  }

  async function removeProduct(id) {
    const confirm = await Toast.confirm("Deseja remover o produto?", "Não", "Sim")
    if (confirm) {
      setCrossSellingData(crossSellingData)
      setCrossSellingDataItems(crossSellingDataItems.filter(e => e.id !== id))
    }
  }

  useEffect(() => {
    findAll()
    findAllCategory()
    findAllProducts()
  }, [])

  return (
    <NavBar>
      <div className="container-fluid mt-4">

        <div className="alert alert-info fw-bold" role="alert">
          <TbInfoSquare size={25} /> Venda cruzada é uma técnica usada para oferecer uma experiência de compra mais completa e personalizada para o consumidor. O objetivo dessa estratégia é aumentar o valor final da compra, agregando produtos ou serviços complementares à escolha inicial do usuário. Dessa forma, você tem grandes chances de elevar o seu ticket médio e garantir a satisfação do cliente.
        </div>

        {categories.length === 0 && (
          <div className="alert alert-warning fw-bold d-flex justify-content-between align-items-center" role="alert">
            <span>Para criar uma venda cruzada, tem que ter pelo menos uma categoria cadastrada</span>
            <button className="btn btn-sm btn btn-sm border" onClick={() => navigate('/menu')}>Cadastrar</button>
          </div>
        )}
        {products.length === 0 && (
          <div className="alert alert-warning fw-bold d-flex justify-content-between align-items-center" role="alert">
            <span>Para criar uma venda cruzada, tem que ter pelo menos um produto cadastrado</span>
            <button className="btn btn-sm btn btn-sm border" onClick={() => navigate('/menu')}>Cadastrar</button>
          </div>
        )}
        <h3 className="bg-dark-blue-3 p-3 text-white rounded" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>Venda Cruzada</span>
        </h3>
        <div className="row">
          <div className="col-sm-5">
            <div className="card" >
              <div className="card-body">

                {!crossSellingData.id && (
                  <div className="alert alert-primary" role="alert">
                    <TbInfoSquare size={25} /> Adicione a categoria que gostaria de fazer uma venda cruzada
                  </div>
                )}

                <div className="form-check">
                  <input checked={crossSellingData.active} onChange={(e) => setCrossSellingData({ ...crossSellingData, active: e.target.checked })} className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                  <label className="form-check-label" htmlFor="flexCheckDefault">
                    Ativar
                  </label>
                </div>

                <select value={crossSellingData.categoryId} onChange={(e) => setCrossSellingData({ ...crossSellingData, categoryId: e.target.value })} className="form-select mt-3" aria-label="Default select example">
                  <option value={null}>Selecione a categoria</option>
                  {categories.map((value) => {
                    return (
                      <option key={value.id} value={value.id}>{value.name}</option>
                    )
                  })}
                </select>
                <div className="mt-3">
                  <label htmlFor="exampleFormControlTextarea1" className="form-label">Titulo</label>
                  <input value={crossSellingData.title} onChange={(e) => setCrossSellingData({ ...crossSellingData, title: e.target.value })} type="text" className="form-control form-control-sm" />
                </div>
                <div className="mt-3">
                  <label htmlFor="exampleFormControlTextarea1" className="form-label">Descrição</label>
                  <textarea value={crossSellingData.description} onChange={(e) => setCrossSellingData({ ...crossSellingData, description: e.target.value })} className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                </div>

                <hr />
                {!crossSellingData.id && (
                  <div className="alert alert-primary" role="alert">
                    <TbInfoSquare size={25} /> Escolho os produtos que irão aparecer, quando o cliente comprar algo da categoria escolhida
                  </div>
                )}

                <div className="d-flex gap-4 mt-3">
                  <select defaultValue={null} onChange={(e) => setProductSelect(e.target.value)} className="form-select" aria-label="Default select example">
                    <option value={null}>Selecione</option>
                    {products.map((value) => {
                      return (
                        <option key={value.id} value={value.id}>{value.name}</option>
                      )
                    })}
                  </select>
                  <button className="btn btn-sm btn btn-sm border text-white" onClick={() => {
                    if (!productSelect || productSelect === 'Selecione') {
                      return Toast.warning("Selecione um produto.")
                    }

                    const aux = products.find(e => e.id === productSelect)

                    const exits = crossSellingDataItems.find((e) => e.productId === productSelect)

                    if (exits) {
                      return Toast.warning("Esse produto já foi adicionado")
                    }

                    setCrossSellingData(crossSellingData)

                    setCrossSellingDataItems([...crossSellingDataItems, {
                      productId: aux.id,
                      name: aux.name
                    }])
                  }}>Adicionar</button>
                </div>

                <table className="table mt-2">
                  <thead>
                    <tr>
                      <th >#</th>
                      <th >Produto</th>
                      <th ></th>
                    </tr>
                  </thead>
                  <tbody>
                    {crossSellingDataItems.map((value) => {
                      return (
                        <tr key={value.id}>
                          <td>#</td>
                          <td>{value.name}</td>
                          {crossSellingData.id && <td></td>}
                          <td className="pointer" onClick={() => removeProduct(value.id)}><BsFillTrashFill color="red" size={20} /></td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                <hr />
                <button className="btn btn-sm btn-success" onClick={() => crossSellingData.id ? update(crossSellingData.id) : create()}>Salvar</button>
                <button className="btn btn-sm btn btn-sm border ms-2" onClick={() => clear()}>Novo</button>
              </div>
            </div>
          </div>

          <div className="col-sm-7">
            <h5 className="text-white bg-dark-blue-4 p-2 rounded">Lista de vendas Cruzadas</h5>
            <table className="table">
              <thead>
                <tr>
                  <th >#</th>
                  <th >Categoria</th>
                  <th >Status</th>
                  <th ></th>
                </tr>
              </thead>
              <tbody>
                {crossSelling.map((value) => {
                  return (
                    <tr key={value.id}>
                      <th>#</th>
                      <td>{value.name}</td>
                      <td>
                        {Boolean(value.active) && <AiFillCheckSquare color="green" size={25} />}
                        {!Boolean(value.active) && <span style={{ color: 'red', fontSize: 18 }}>-</span>}
                      </td>
                      <td>
                        <span className="pointer p-2" onClick={() => edit(value.id)}>
                          <TbEdit size={20} />
                        </span>
                        <span className="pointer ms-2 p-2" onClick={() => destroy(value.id)}>
                          <BsFillTrashFill size={20} />
                        </span>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </NavBar>
  )
}

