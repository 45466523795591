import { MdOutlineDateRange } from "react-icons/md"
import { useState } from "react"
import useSWR from "swr"

import { addPayment } from '../../../services/MercadoPago'
import { api } from "../../../api/api"
import './style.css'

export function MercadoPago() {
  const [requestInPedding, setRequestInPedding] = useState(false)

  const { data, isLoading } = useSWR('get-plan', async () => {
    try {
      const response = await api.get('/mercado-pago/get-plan')
      return response.data
    } catch (error) {
      return {
        plataform: false,
        nota: false,
        info: {
          plataform: {
            start: '',
            end: '',
          },
          nota: 0
        }
      }
    }
  }, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  })

  async function payment(type = null) {
    try {
      setRequestInPedding(true)
      const url = await addPayment(type)

      window.open(url, '_blank')
    } catch { }
    finally {
      setRequestInPedding(false)
    }
  }

  return (
    <div className="modal fade" id="mercadoPagoPlanos" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div style={{ position: 'relative', width: '100%' }}>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ position: 'absolute', right: 10, top: 10, zIndex: 9999 }}></button>
          </div>
          <br />
          <div className="modal-body overflow-auto" style={{ maxHeight: 768 }}>
            {isLoading && (
              <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
            {!isLoading && (
              <>
                <div className="mb-3 text-center row" style={{ alignItems: 'flex-end' }} >
                  <div className="col-sm-12 rounded " style={{}}>
                    <img width={220} className="" src="https://pedidos-images.s3.amazonaws.com/BMS+PEDIDO+-+LAYOUT+SISTEMA.png" alt="" />
                  </div>

                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className="card shadow-sm" style={{ width: "60%", minWidth: 350 }}>
                      <div className="card-body">
                        {data.info.plataform.isTest && (
                          <>
                            <h4>Plataforma</h4>
                            <div>
                              <p style={{ color: '#555', fontSize: 28, fontWeight: 'bold', paddingTop: 10, paddingBottom: 10, lineHeight: 1.2 }}>R$ 139,80</p>
                            </div>
                            <button onClick={() => payment('plataform')} className="btn-plan">Quero assinar</button>
                          </>
                        )}

                        {data.info.plataform.isTest && (
                          <>
                            <button onClick={() => window.open('https://api.whatsapp.com/send?phone=553138919414', '_blank')} className="mt-3 btn-plan">Quero assinar Plataforma + Módulo de Notas</button>
                          </>
                        )}

                        {!data.info.plataform.isTest && (
                          <div style={{}}>
                            <h3>Informações do plano</h3>
                            <p>Inicio <MdOutlineDateRange />: <b>{data?.info?.plataform?.start || 'Sem data de inicio'}</b></p>
                            <p>Fim <MdOutlineDateRange />: <b>{data?.info?.plataform?.end || 'Indeterminado'}</b></p>
                            <p>Módulo fiscal habilitado?: <b>{data?.info?.nota ? 'Sim' : 'Não'}</b></p>

                            <br />
                            <a className="mt-5" href="https://api.whatsapp.com/send?phone=553138919414" target="_blank" rel="noreferrer">Falar com um atendente sobre meu plano?</a>
                          </div>
                        )}
                      </div>
                      {requestInPedding && (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <br />
                          <div className="spinner-border mt-2 mb-3" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                          <br />
                        </div>
                      )}
                    </div>
                  </div>

                  <div>
                    <br />
                    <a href="https://api.whatsapp.com/send?phone=553138919414" target="_blank" className="mt-3" rel="noreferrer">Problemas com o pagamento?</a>
                  </div>
                  {/* <div className="col-sm-8">
                    <h2>Planos contratados</h2>
                    <div>
                      <hr />
                      <div style={{ display: 'flex', justifyContent: 'space-around', gap: 20 }}>
                        <div>
                          <h5>Plataforma</h5>
                          <p style={{ display: data.info.plataform.isTest ? 'none' : 'block' }}>Inicio: <b>{data?.info?.plataform?.start || 'Sem data de inicio'}</b> - Fim: <b>{data?.info?.plataform?.end || 'Indeterminado'}</b></p>
                          <p style={{ display: data.info.plataform.isTest ? 'block' : 'none' }}>
                            <b>Périodo teste</b>
                          </p>
                        </div>
                        {data?.nota && (
                          <div >
                            <h5>Módulo fiscal</h5>
                            <p>Quantidade de notas restantes: <b>{data?.info?.nota || '0'} / notas</b></p>
                          </div>
                        )}
                      </div>
                      <hr />
                      <div className="col-sm-12 row" style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <div className="col-sm-5 border p-2 rounded" style={{ display: data?.info?.plataform?.isTest ? 'block' : 'none' }}>
                          <div className="card-body">
                            <h2 className="card-title pricing-card-title" style={{ fontSize: '1.5rem' }}>
                              R$ 149,90 <small className="text-muted">/ mês</small>
                            </h2>
                            <button type="button" className="btn btn-sm btn-lg btn-block text-white"
                              onClick={() => payment('plataform', null)}
                              style={{
                                backgroundColor: '#666'
                              }}
                            >
                              Contratar Plano
                            </button>
                            <p>Contrato de 12 meses.</p>
                            {requestInPedding && (
                              <>
                                <br />
                                <div className="spinner-border mt-2" role="status">
                                  <span className="visually-hidden">Loading...</span>
                                </div>
                              </>
                            )}

                          </div>
                        </div>
                        <div className="col-sm-5 border p-2 rounded">
                          <h5 style={{ display: data?.nota ? 'block' : 'none' }}>Adquira mais documentos fiscais</h5>
                          <select className="form-select" defaultChecked={200} onChange={(e) => setPlanNota(e.target.value)}>
                            <option value={200}>Plano de 200 notas - R$  39,90</option>
                            <option value={500}>Plano de 500 notas - R$ 89,90</option>
                            <option value={1000}>Plano de 1000 notas - R$ 159,90</option>
                            <option value={2000}>Plano de 2000 notas - R$ 299,90</option>
                          </select>
                          <button type="button" className="mt-3 btn btn-sm btn-lg btn-block text-white"
                            onClick={() => payment('nota', Number(planNota))}
                            style={{ backgroundColor: '#666' }}
                          >
                            Contratar notas
                          </button>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div >
  )
}