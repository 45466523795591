import { useDispatch, useSelector } from "react-redux";

import { CLEAR_SELECT_ORDER } from '../../../stores/reducers/OrderReducers';
import { numOrderFormat } from '../../../utils/utils';
import { BodyModal } from './components/ContentModal';
import './style.css'

export function ModalOrders() {
  const dispatch = useDispatch()
  const orderSelected = useSelector(state => state.order.orderSelect)

  return (
    <>
      <div className="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" id="modalOrder" tabIndex="-1" aria-labelledby="modalOrderLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title" style={{ display: 'flex', alignItems: "flex-end" }}>
                <div>
                  <img
                    src="/favicon.ico"
                    loading="lazy" alt="logobms"
                    style={{ width: 20 }}
                  />
                </div>
                <p className="m-0 p-0 fw-bold">Pedido {numOrderFormat(orderSelected?.numOrder)}</p>
              </div>
              <button type="button" id='modalOrderClose' onClick={() => dispatch(CLEAR_SELECT_ORDER())} className="btn-close text-reset" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body" style={{ height: 650, overflow: 'auto' }}>
              {orderSelected?.id && <BodyModal order={orderSelected} />}
              {!orderSelected?.id && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", height: '100%' }}>
                  <div className="spinner-border" style={{ width: '3rem', height: '3rem', }} role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}