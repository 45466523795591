import { useEffect, useState } from "react"
import { api } from "../../api/api"
import { IFrame } from "../../reporting/iframe"
import { maskCurrency } from "../../utils/utils"
import { Toast } from "../../utils/Toast"

export function ReportProdutFiscalOrNotFiscal({ refFiscal, set }) {
  const [state, setState] = useState([])

  useEffect(() => {
    find()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refFiscal])

  async function find() {
    if (refFiscal === null) {
      return []
    }

    const response = await api.get(`/products?refFiscal=${refFiscal}`)
    const data = response.data?.data?.map(e => e.stocks).flat()

    if (data.length === 0) {
      return Toast.warning('Nenhum estoque encontrado.')
    }
    setState(data)

    set(null)
  }


  useEffect(() => {
    if (state.length > 0) {
      window.frames["printf"].focus();
      window.frames["printf"].print();
    }
  }, [state])

  return (
    <>
      <IFrame>
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", gap: 10 }}>
            <img src="https://pedidos-images.s3.amazonaws.com/BMS%20PEDIDO%20-%20LAYOUT%20SISTEMA.png" width={130} alt="" />
            <h1 style={{ textAlign: 'center' }}>Relatório de estoque</h1>
          </div>
          <table className="table" style={{ border: '1px solid #666', padding: 10, borderRadius: 5, margin: '0 auto' }}>
            <thead>
              <tr style={{ background: '#13293D', color: '#fff' }}>
                <th scope="col" style={{ width: 200, textAlign: 'center' }}>Produto</th>
                <th scope="col" style={{ width: 200, textAlign: 'center' }}>Valor</th>
                <th scope="col" style={{ width: 200, textAlign: 'center' }}>Estoque</th>
                <th scope="col" style={{ width: 200, textAlign: 'center' }}>Unidade</th>
              </tr>
            </thead>
            <tbody>
              {state.map((stock) => {
                return (
                  <tr style={{ borderBottom: '1px solid #666' }}>
                    <th style={{ width: 200, textAlign: 'left' }}>{stock.name}</th>
                    <td style={{ width: 200, textAlign: 'center' }}>{maskCurrency(stock?.pricing)}</td>
                    <td style={{ width: 200, textAlign: 'center' }}>{stock?.stock ?? 0}</td>
                    <td style={{ width: 200, textAlign: 'center' }}>{stock?.type ?? 'UN'}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </IFrame>
    </>
  )
}