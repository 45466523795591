import { useContext, useEffect, useState } from 'react'

import { NavBar } from "../../components/NavBar";
import { mascaraMoeda, maskCurrency } from "../../utils/utils";
import { api } from '../../api/api';
import { DateTime } from 'luxon';
import { Toast } from '../../utils/Toast';
import { IoTicketOutline } from 'react-icons/io5';
import { FaEdit } from 'react-icons/fa';
import _ from 'lodash';
import { GlobalContext } from '../../context/GlobalContext';

export function CupomPage() {
  const { isAccess } = useContext(GlobalContext)

  const [cupons, setCupons] = useState([])
  const [state, setState] = useState({
    id: '',
    codCupom: "",
    typeDiscount: "porcentagem",
    dateLimitUsage: "",
    pricingMin: 0,
    discount: 0,
    limitUsed: 0,
    firstPurchase: false,
    active: false
  })

  async function createCupom() {
    if (!isAccess('cupons', 'create')) {
      return Toast.warning('Você não tem permissão para criar cupom')
    }

    try {
      const respone = await api.post('/cupons', state)
      Toast.success("Cupom criado com sucesso.")
      await listAllCupons()
      setState({
        ...state,
        id: respone.data.id
      })
    } catch (error) {
      const message = error?.response?.data?.message ?? 'Erro ao criar cupom'
      Toast.warning(message)
    }
  }


  async function updateCupom() {
    if (!isAccess('cupons', 'update')) {
      return Toast.warning('Você não tem permissão para atualizar cupom')
    }

    try {
      Reflect.deleteProperty(state, 'codCupom')
      await api.put('/cupons/' + state.id, state)
      Toast.success("Cupom atualizado com sucesso.")
      await listAllCupons()
    } catch (error) {
      const message = error?.response?.data?.message ?? 'Erro ao atualizar cupom'
      Toast.warning(message)
    }
  }

  async function updateStatus(id, status) {
    if (!isAccess('cupons', 'update')) {
      return Toast.warning('Você não tem permissão para atualizar cupom')
    }

    try {
      await api.put('/cupons/' + id, {
        active: status
      })
      await listAllCupons()
    } catch (error) {
      const message = error?.response?.data?.message ?? 'Erro ao atualizar cupom'
      Toast.warning(message)
    }
  }

  async function listAllCupons() {
    const response = await api.get('/cupons')
    setCupons(_.orderBy(response.data, (a) => a.codCupom, 'asc'))
  }

  async function onChange(key, valueInput = '') {
    setState({
      ...state,
      [key]: valueInput
    })
  }

  async function findById(id) {
    const response = await api.get('/cupons/' + id)
    setState({
      id: response.data.id,
      codCupom: response.data.codCupom,
      typeDiscount: response.data.typeDiscount,
      dateLimitUsage: response.data?.dateLimitUsage?.substring(0, 10),
      pricingMin: response.data.pricingMin,
      discount: response.data.discount,
      limitUsed: response.data.limitUsed,
      firstPurchase: Boolean(response.data.firstPurchase),
      active: Boolean(response.data.active)
    })
  }

  useEffect(() => {
    listAllCupons()
  }, [])

  return (
    <>
      <NavBar>
        <div className="container-fluid">
          <div className="mt-3 p-2 bg-dark-blue-1 rounded fw-bold d-flex justify-content-between  align-items-center border mb-3">
            <p className="text-white" style={{ fontSize: '1.3rem' }}> <IoTicketOutline color='white' className='mb-1' size={22} /> Cupons de desconto</p>
          </div>

          <div className="form row mt-5">
            <div className="col-sm-3 col-lg-2">
              <label htmlFor="">Nome do cupom</label>
              <input type="text" disabled={!!state.id} value={state.codCupom} onChange={(e) => onChange('codCupom', e.target.value)} placeholder="BLACKFRIDAY10" className="form-control form-control-sm" id="" />
            </div>
            <div className="col-sm-3 col-lg-2">
              <label htmlFor="">Válido até</label>
              <input type="date" value={state.dateLimitUsage} onChange={(e) => onChange('dateLimitUsage', e.target.value)} className="form-control form-control-sm" id="" />
            </div>
            <div className="col-sm-3 col-lg-2">
              <label htmlFor="">Tipo de desconto</label>
              <select className="form-select form-select-sm" value={state.typeDiscount} onChange={(e) => onChange('typeDiscount', e.target.value)} aria-label=".form-select-sm example">
                <option selected value={"porcentagem"}>Porcentagem</option>
                <option value={"fixo"}>Valor Fixo</option>
              </select>
            </div>
            <div className="col-sm-3 col-lg-2">
              <label htmlFor="">Valor</label>
              <input type="number" value={state.discount} onChange={(e) => onChange('discount', e.target.value)} className="form-control form-control-sm" id="" />
            </div>
            <div className="col-sm-3 col-lg-2">
              <label htmlFor="">Valor minimo</label>
              <input type="text" value={maskCurrency(state.pricingMin)} onChange={(e) => onChange('pricingMin', mascaraMoeda(e.target.value))} className="form-control form-control-sm" id="" />
            </div>
            <div className="col-sm-3 col-lg-2">
              <label htmlFor="">Limite de uso</label>
              <input type="number" value={state.limitUsed} onChange={(e) => onChange('limitUsed', e.target.value)} aria-describedby="limitUsed" className="form-control form-control-sm" id="" />
              <div id="limitUsed" className="form-text text-primary">Obs.: 0 = ilimitado</div>
            </div>
            <div className="col-sm-5 col-lg-3">
              <input checked={state.firstPurchase} onChange={(e) => onChange('firstPurchase', e.target.checked)} className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
              <label className="form-check-label" htmlFor="flexCheckDefault" style={{ marginLeft: 5 }}>
                Primeira venda
              </label>
              <div id="limitUsed" className="form-text text-primary">Cupom válido apenas para primeira compra</div>
            </div>
            <div className="col-sm-12 mt-2 text-end">
              <button className="btn btn-sm btn-success" onClick={async () => {
                if (!state.id) {
                  await createCupom()
                } else {
                  await updateCupom()
                }
              }}>Salvar Cupom</button>
              <button className="ms-2 btn btn-sm btn-light border" onClick={async () => {
                setState({
                  id: '',
                  codCupom: "",
                  typeDiscount: "porcentagem",
                  dateLimitUsage: "",
                  pricingMin: 0,
                  discount: 0,
                  limitUsed: 0,
                  firstPurchase: false,
                  active: false
                })
              }}>Novo Cupom</button>
            </div>
          </div>
          <h6 className='mt-3'>Todos Cupons</h6>
          <hr />
          <table className="table table-sm" style={{ fontSize: 14 }}>
            <thead>
              <tr>
                <th>#</th>
                <th>Código do cupom</th>
                <th >Desconto</th>
                <th>Validade</th>
                <th>Uso limite</th>
                <th>Usados</th>
              </tr>
            </thead>
            <tbody>
              {cupons.map((cupom) => {
                let discount = null

                if (cupom.typeDiscount === 'porcentagem') {
                  discount = `${cupom.discount}%`
                } else {
                  discount = maskCurrency(cupom.discount)
                }

                // let isValid = true

                // const currentDate = DateTime.local().startOf('day')
                // const dateLimitUsage = DateTime.fromISO(cupom.dateLimitUsage).startOf('day').toLocal()

                // if (dateLimitUsage.diff(currentDate, 'days').days < 0) {
                //   isValid = false
                // }

                // if (cupom.used === cupom.limitUsed && cupom.limitUsed !== 0) {
                //   isValid = false
                // }

                return (
                  <tr key={cupom.id}>
                    <th>#</th>
                    <td className="fw-bold">{cupom.codCupom}</td>
                    <td>{discount}</td>
                    <td>{cupom.dateLimitUsage ? DateTime.fromISO(cupom.dateLimitUsage).toUTC().toFormat('dd/MM/yyyy') : 'Sem data limite'}</td>
                    <td>{cupom.limitUsed === 0 ? 'Ilimitado' : cupom.limitUsed}</td>
                    <td>{cupom.used} usados</td>
                    <td>

                      <div className='d-flex '>
                        <div className="form-check form-switch">
                          <input className="form-check-input" checked={Boolean(cupom.active)} onChange={(e) => updateStatus(cupom.id, e.target.checked)} type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                        </div>
                        <div>
                          <span className='pointer' onClick={() => findById(cupom.id)}><FaEdit size={20} /></span>
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              })}

            </tbody>
          </table>
        </div>
      </NavBar>
    </>
  )
}