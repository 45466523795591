import { useContext, useEffect, useState } from "react"
import { api } from "../../../api/api"
import { Toast } from "../../../utils/Toast"
import { MenuContext } from "../../../context/MenuContext"

export function CategoryModal() {
  const { load, categoryEdit } = useContext(MenuContext)

  const [state, setState] = useState({
    id: null,
    name: "",
    isLocal: false,
    isMenu: false,
    active: false,
  })

  useEffect(() => {
    if (categoryEdit.id)
      setState({
        id: categoryEdit.id,
        name: categoryEdit.name,
        isLocal: categoryEdit.isLocal,
        isMenu: categoryEdit.isMenu,
        active: Boolean(categoryEdit.active)
      })
  }, [categoryEdit])

  function clear() {
    setState({
      id: null,
      name: "",
      isLocal: false,
      isMenu: false,
      active: false,
    })
  }

  async function created(e) {
    e.preventDefault()

    if (!state.name) {
      return Toast.warning('O campo nome é obrigatório')
    }

    const response = await api.post("/category", state)
    const { id } = response.data
    setState({
      ...state,
      id
    })
    await load()
    Toast.success()
    clear()
    return id
  }

  async function updated(e) {
    e.preventDefault()

    if (!state.name) {
      return Toast.warning('O campo nome é obrigatório')
    }

    const response = await api.put("/category/" + state.id, state)
    const { id } = response.data
    setState({
      ...state,
      id
    })

    await load()
    Toast.success()
    clear()
    return id
  }

  return (
    <>
      <div className="modal fade" id="modalCategory" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">Cadastrar</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => clear()}></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Nome</label>
                  <input type="text" maxLength={255} value={state.name} onChange={(e) => setState({
                    ...state,
                    name: e.target.value
                  })} className="form-control" id="name" aria-describedby="nameHelp" />
                  <small id="nameHelp" className="form-text text-muted">Digite o nome da Categoria.</small>
                </div>
                <div className="d-flex gap-3 mt-2 mb-2">
                  <div className="form-group form-check">
                    <input type="checkbox" checked={state.isLocal} onChange={(e) => setState({
                      ...state,
                      isLocal: e.target.checked
                    })} className="form-check-input" id="activeVendaLocal" />
                    <label className="form-check-label" htmlFor="activeVendaLocal">Venda local?</label>
                  </div>
                  <div className="form-group form-check">
                    <input type="checkbox" checked={state.isMenu} onChange={(e) => setState({
                      ...state,
                      isMenu: e.target.checked
                    })} className="form-check-input" id="activeVendaDelivery" />
                    <label className="form-check-label" htmlFor="activeVendaDelivery">Venda delivery?</label>
                  </div>
                  <div className="form-group form-check">
                    <input type="checkbox" checked={state.active} onChange={(e) => setState({
                      ...state,
                      active: e.target.checked
                    })} className="form-check-input" id="activeCategory" />
                    <label className="form-check-label" htmlFor="activeCategory">Ativar</label>
                  </div>
                </div>
              </form>
              <div style={{ float: 'right', width: 150 }}>
                <button type="submit" className="btn btn-sm border shadow-sm" style={{ width: '100%' }} onClick={state.id ? updated : created}>Salvar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}