import { Information } from "./components/Information";
import { Schedules } from "./components/Schedules";
import { NavBar } from "../../components/NavBar";
import { Configuration } from "./components/Configuration";
import { AiOutlineSetting } from "react-icons/ai";

export function CompanyPage() {
  return (
    <>
      <NavBar>
        <div className="container-fluid mt-4">

          <div className="mt-3 p-2 bg-dark-blue-1 rounded fw-bold d-flex justify-content-between  align-items-center border mb-3">
            <p className="text-white" style={{ fontSize: '1.3rem' }}> <AiOutlineSetting color='white' className='mb-1' size={22} /> Meu dados</p>
          </div>

          <ul className="nav nav-tabs mt-4" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button className="nav-link active" id="home-tab" style={{ color: 'black' }} data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Estabelecimento</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link" id="profile-tab" style={{ color: 'black' }} data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Horários</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link" id="configuration-tab" style={{ color: 'black' }} data-bs-toggle="tab" data-bs-target="#configuration" type="button" role="tab" aria-controls="configuration" aria-selected="false">Personalização</button>
            </li>
          </ul>

          <div className="tab-content mb-4" id="myTabContent">
            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
              <Information />
            </div>
            <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
              <Schedules />
            </div>
            <div className="tab-pane fade" id="configuration" role="tabpanel" aria-labelledby="configuration-tab">
              <Configuration />
            </div>
          </div>
        </div>
      </NavBar>
    </>
  )
}