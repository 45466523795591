/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useState, } from "react";
import useSWR from 'swr'
import { LuStickyNote } from "react-icons/lu";
import { NavBar } from "../../components/NavBar";
import { ModalFiscalCancel } from "../../components/Modals/FiscalCancel";
import { api } from "../../api/api";
import { DateTime } from "luxon";
import { downloadFile, maskCurrency, numOrderFormat } from "../../utils/utils";
import { FiscalContext } from "../../context/FiscalContext";
import { ModalOrders } from "../../components/Modals/Orders";
import { useDispatch } from "react-redux";
import { SELECT_ORDER } from "../../stores/reducers/OrderReducers";
import { GlobalContext } from "../../context/GlobalContext";
import { Toast } from "../../utils/Toast";
import { ModalReportNfe } from "../../components/Modals/ReportNFe";

import './style.css'

export function FiscalPage() {
  const { isAccess } = useContext(GlobalContext)

  const { setIdOrder } = useContext(FiscalContext)
  const dispatch = useDispatch()

  const [search, setSearch] = useState({
    startDate: '',
    endDate: ''
  })

  const { data } = useSWR(['/nfce', search], async (args) => {

    if (args[1].startDate && !args[1].endDate) {
      return []
    }


    if (!args[1].startDate && args[1].endDate) {
      return []
    }

    const response = await api.get(`/nfce?startDate=${args[1].startDate}&endDate=${args[1].endDate}`)
    return response.data
  })

  async function orderOpen(id) {
    const response = await api.get(`/orders/${id}`)
    dispatch(SELECT_ORDER(response.data))
    const myModal = new window.bootstrap.Modal(document.getElementById('modalOrder'), {})
    myModal.show()
  }

  return (
    <>
      <ModalFiscalCancel />
      <ModalOrders />
      <ModalReportNfe />
      <NavBar>
        <div className="container-fluid">
          <div className="mt-3 p-2 bg-dark-blue-1 rounded fw-bold d-flex justify-content-between  align-items-center border mb-3" id="title-fiscal">
            <p className="text-white text-title-fiscal" style={{ fontSize: '1.3rem' }}>  <LuStickyNote className="mb-1" color='white' size={22} /> Fiscal</p>
          </div>

          <div>
            <div className="row header-filter">
              <div className="col-sm-3">
                <label htmlFor="">Data inicio</label>
                <input type="date" onChange={(e) => setSearch({ ...search, startDate: e.target.value })} value={search.startDate} className="form-control form-control-sm" />
              </div>
              <div className="col-sm-3">
                <label htmlFor="">Data fim</label>
                <input type="date" onChange={(e) => setSearch({ ...search, endDate: e.target.value })} value={search.endDate} className="form-control form-control-sm" />
              </div>
              <div className="col-sm-3">
                <br />
                <button className="btn btn-sm btn-primary" onClick={() => window.print()}>Imprimir</button>
              </div>
              <div className="col-sm-3" style={{ textAlign: 'end' }}>
                <br />
                <button className="btn btn-sm btn-primary" onClick={() => {
                  new window.bootstrap.Modal(document.getElementById('modalReportNFe'), {}).toggle()

                }}>Gerar Relatório</button>
              </div>
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th >Cliente</th>
                  <th >Status</th>
                  <th >Data</th>
                  <th >Pedido</th>
                  <th >Nº Nota</th>
                  <th >Valor</th>
                  <th className="th-acoes">Ações</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((value) => {
                  return (
                    <tr key={value.id}>
                      <th>{value.name || 'Cliente não selecionado'}</th>
                      <td className="td-status">
                        {value.status === 'Emitida' && <span className="badge bg-success">Emitida</span>}
                        {value.status === 'Cancelada' && <span className="badge bg-danger">Cancelada</span>}
                        {(value.status !== 'Emitida' && value.status !== 'Cancelada') && <span className="badge bg-dark">Processando</span>}
                      </td>
                      <td>{DateTime.fromISO(value.dataNfe).toLocal().toFormat('dd/MM/yyyy HH:mm')}</td>
                      <td style={{ color: 'blue', cursor: 'pointer' }} onClick={() => value.numOrder ? orderOpen(value.idOrder) : null}>
                        {numOrderFormat(value.numOrder)}
                      </td>
                      <td>
                        nº {value?.response?.nfe}
                      </td>
                      <td>
                        {maskCurrency(value.total)}
                      </td>
                      <td className="td-acoes">
                        <div className="dropdown">
                          <button className="btn btn-sm btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            Ações
                          </button>
                          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            {(value.status === 'Emitida' && value.status !== 'Cancelada') && (
                              <li><a className="dropdown-item pointer" onClick={() => {
                                if (isAccess('fiscal', 'cancelar')) {
                                  setIdOrder(value.idOrder)
                                  new window.bootstrap.Modal(document.getElementById('fiscalCancel'), {}).toggle()
                                } else {
                                  Toast.warning('Você não tem permissão para cancelar nota.')
                                }
                              }}>Cancelar</a></li>
                            )}
                            {value.status === 'Emitida' && (
                              <>
                                <li><a className="dropdown-item pointer" onClick={() => {
                                  if (value.response) {
                                    window.open(value.response.danfe)
                                  }
                                }}>Imprimir</a></li>

                                <li><a className="dropdown-item pointer" onClick={() => {
                                  if (value.response) {
                                    downloadFile(value.response.xml, `${value.response.chave}.xml`)
                                  }
                                }}>Baixar XML</a></li>
                              </>
                            )}
                            {value.status === 'Cancelada' && (
                              <>
                                <li><a className="dropdown-item pointer" onClick={() => {
                                  if (value.response) {
                                    downloadFile(value.responseCancelamento.xml, `${value.response.chave}.xml`)
                                  }
                                }}>Baixar XML</a></li>
                              </>
                            )}
                          </ul>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>Total: {maskCurrency(data?.reduce((prev, current) => prev + (current?.total ?? 0), 0))}</td>
                  <td></td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </NavBar>
    </>
  )
}