import { useContext, useState } from "react"
import { FiscalContext } from "../../../context/FiscalContext"
import { api } from "../../../api/api"
import { Toast } from "../../../utils/Toast"
import { useSWRConfig } from "swr"

export function ModalFiscalCancel() {
  const { idOrder, setIdOrder } = useContext(FiscalContext)
  const { mutate } = useSWRConfig()
  const [messageRefused, setMessageRefused] = useState('')

  async function cancelDoc() {
    if (!idOrder) {
      return Toast.warning('Selecione a nota por favor')
    }

    if (!messageRefused || messageRefused.length < 15 || messageRefused.length > 255) {
      return Toast.warning('O motivo deve conter de 15 a 255 caracteres.')
    }

    await api.post('/nfce/cancel', { idOrder: idOrder, messageRefused })
    
    await mutate(['/nfce', {
      startDate: '',
      endDate: ''
    }])

    document.getElementById('modalFiscalCancel').click()
    Toast.warning('Nota cancelada com sucesso')
  }

  return (
    <div className="modal fade" id="fiscalCancel" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Cancelar documento fiscal</h5>
            <button type="button" id="modalFiscalCancel" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
              setIdOrder(null)
              setMessageRefused('')
            }}></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-sm-12">
                <label htmlFor="motivationCancel">Motivo do cancelamento</label>
                <input value={messageRefused} onChange={(e) => setMessageRefused(e.target.value)} maxLength={255} type="text" id="motivationCancel" className="form-control form-control-sm" />
                <div id="motivationCancel" className="form-text">Deve conter de 15 a 255 caracteres.</div>
              </div>
              <div className="col-sm-4 mt-3">
                <button className="btn btn-sm btn-dark" onClick={() => cancelDoc()}>Cancelar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}